import moment from 'moment';
import { startSubmit, stopSubmit } from 'redux-form';
import ws from '../../ws';
import getToken from './getToken';
import PaginationPageConverter from '../../shared/classes/PaginationPageConverter';

const USERS_PROGRESS = 'progress';
const USERS_SUCCESS = 'success';
const USERS_FAILED = 'failed';

const USERS_GET_LIST_PROGRESS = 'USERS_GET_LIST_PROGRESS';
const USERS_GET_LIST_SUCCESS = 'USERS_GET_LIST_SUCCESS';
const USERS_GET_LIST_FAILED = 'USERS_GET_LIST_FAILED';
const USERS_FORM_SUBMIT_PROGRESS = 'USERS_FORM_SUBMIT_PROGRESS';
const USERS_FORM_SUBMIT_SUCCESS = 'USERS_FORM_SUBMIT_SUCCESS';
const USERS_FORM_SUBMIT_FAILED = 'USERS_FORM_SUBMIT_FAILED';
const USERS_GET_USER_PROGRESS = 'USERS_GET_USER_PROGRESS';
const USERS_GET_USER_SUCCESS = 'USERS_GET_USER_SUCCESS';
const USERS_GET_USER_FAILED = 'USERS_GET_USER_FAILED';
const USERS_GET_ASYNC_DATA_PROGRESS = 'USERS_GET_ASYNC_DATA_PROGRESS';
const USERS_GET_ASYNC_DATA_SUCCESS = 'USERS_GET_ASYNC_DATA_SUCCESS';
const USERS_GET_ASYNC_DATA_FAILED = 'USERS_GET_ASYNC_DATA_FAILED';
const USERS_GET_STATISTICS_PROGRESS = 'USERS_GET_STATISTICS_PROGRESS';
const USERS_GET_STATISTICS_SUCCESS = 'USERS_GET_STATISTICS_SUCCESS';
const USERS_GET_STATISTICS_FAILED = 'USERS_GET_STATISTICS_FAILED';
const USERS_CLEAR_STATE = 'USERS_CLEAR_STATE';

const USERS_CLEAR_FLASH_MESSAGE = 'USERS_CLEAR_FLASH_MESSAGE';

const userGetListProgress = () => ({
  type: USERS_GET_LIST_PROGRESS,
});

const userGetListSuccess = payload => ({
  type: USERS_GET_LIST_SUCCESS,
  payload,
});

const userGetListFailed = () => ({
  type: USERS_GET_LIST_FAILED,
});

const userStartFormSubmit = () => ({
  type: USERS_FORM_SUBMIT_PROGRESS,
});

const userFormSubmitSuccess = payload => ({
  type: USERS_FORM_SUBMIT_SUCCESS,
  payload,
});

const userFormSubmitFailed = () => ({
  type: USERS_FORM_SUBMIT_FAILED,
});

const userGetUserProgress = () => ({
  type: USERS_GET_USER_PROGRESS,
});

const userGetUserSuccess = payload => ({
  type: USERS_GET_USER_SUCCESS,
  payload,
});

const userGetUserFailed = () => ({
  type: USERS_GET_USER_FAILED,
});

const userGetAsyncDataProgress = () => ({
  type: USERS_GET_ASYNC_DATA_PROGRESS,
});

const userGetAsyncDataSuccess = payload => ({
  type: USERS_GET_ASYNC_DATA_SUCCESS,
  payload,
});

const userGetAsyncDataFailed = () => ({
  type: USERS_GET_ASYNC_DATA_FAILED,
});

const userClearState = () => ({
  type: USERS_CLEAR_STATE,
});

const userGetStatisticsProgress = () => ({
  type: USERS_GET_STATISTICS_PROGRESS,
});

const userGetStatisticsSuccess = payload => ({
  type: USERS_GET_STATISTICS_SUCCESS,
  payload,
});

const userGetStatisticsFailed = () => ({
  type: USERS_GET_STATISTICS_FAILED,
});

const convertListToTable = (data) => {
  const getRole = (userRoles) => {
    let role = 'Ученик';

    if (userRoles.indexOf('curator') !== -1) {
      role = 'Психолог';
    } else if (userRoles.indexOf('admin') !== -1) {
      role = 'Администратор';
    }

    return role;
  };

  return new PaginationPageConverter(data, doc => ({
    id: doc.id,
    email: doc.email,
    name: doc.name,
    roles: getRole(doc.roles),
    isActive: doc.isActive,
    createdAt: moment(doc.createdAt).locale('ru').format('DD MMM YY г., HH:mm'),
  })).getConvertedData();
};

const getUsersList = params => (dispatch) => {
  const token = getToken();
  dispatch(userGetListProgress());

  ws.emit('api/academy/users/list', { token, payload: params }, (data) => {
    const { status, payload } = data;
    let action;

    if (status === 'ok') {
      action = userGetListSuccess(convertListToTable(payload.data));
    } else {
      action = userGetListFailed();
    }

    dispatch(action);
  });
};

const convertSetUserToDB = (data) => {
  const {
    name,
    birthday,
    gender,
    country,
    city,
    tz,
    phone,
    isActive,
    children,
    isAdmin,
    isCurator,
    blocks,
  } = data;

  const countryStr = typeof country === 'string' && !country.length ? null : country;
  const countryObj = typeof country === 'object' && country ? country.value : null;
  const tzStr = typeof tz === 'string' && !tz.length ? null : tz;
  const tzObj = typeof tz === 'object' && tz ? tz.value : null;

  const convertedData = {
    name,
    birthday: birthday instanceof Date ? birthday.toUTCString() : null,
    gender: typeof gender === 'string' ? gender : gender.value,
    country: typeof country === 'string' ? countryStr : countryObj,
    tz: typeof tz === 'string' ? tzStr : tzObj,
    city: typeof city === 'string' && !city.length ? null : city,
    contact: { phone },
    isActive: isActive !== 'undefined' ? isActive : false,
    roles: (() => {
      const roles = ['user'];

      if (isAdmin !== 'undefined' && isAdmin) {
        roles.push('admin');
      }

      if (isCurator !== 'undefined' && isCurator) {
        roles.push('curator');
      }

      return roles;
    })(),
    children: (() => {
      const childrenArray = [];

      if (children && children.length) {
        for (let i = 0; i < children.length; i += 1) {
          const childrenListData = {
            name: children[i].name,
            gender: typeof children[i].gender === 'string' ? children[i].gender : children[i].gender.value,
            birthday: children[i].birthday instanceof Date ? children[i].birthday.toUTCString() : null,
          };

          if (Object.prototype.hasOwnProperty.call(children[i], 'id')) {
            /* eslint no-underscore-dangle: 0 */
            childrenListData._id = children[i].id;
          }

          childrenArray.push(childrenListData);
        }
      }

      return childrenArray;
    })(),
  };

  const userEmail = Object.prototype.hasOwnProperty.call(data, 'email') ? data.email : null;
  const userPassword = Object.prototype.hasOwnProperty.call(data, 'password') ? data.password : null;
  let email;
  let password;

  if (typeof userEmail === 'string' && !userEmail.length) {
    email = null;
  } else {
    email = userEmail;
  }

  if (typeof userPassword === 'string' && !userPassword.length) {
    password = null;
  } else {
    password = userPassword;
  }

  const convertedAccess = {
    local: {
      email,
      password,
    },
  };

  const ordersAccess = [];

  if (blocks) {
    const leadKeys = Object.keys(blocks);

    for (let i = 0; i < leadKeys.length; i += 1) {
      const leadId = Number(leadKeys[i].substr(6));
      ordersAccess.push({ leadId, isAdminBlock: blocks[leadKeys[i]] });
    }
  }

  return { user: convertedData, access: convertedAccess, ordersAccess };
};

const generateImageObject = async file => new Promise((resolve) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    resolve({ buffer: e.target.result, type: file.type });
  };

  reader.readAsArrayBuffer(file);
});

const createUser = async (data, dispatch, props) => {
  const token = getToken();
  const { form, reset } = props;
  const convertedData = convertSetUserToDB(data);
  const { photo } = data;
  const images = {};

  if (photo && photo.length) {
    images.photo = await generateImageObject(photo[0]);
  }

  dispatch(startSubmit(form));
  dispatch(userStartFormSubmit());

  ws.emit('api/academy/users/create', {
    // payload: convertedData, images, token,
    token, payload: { convertedData, images },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      reset();
      dispatch(stopSubmit(form));
      dispatch(userFormSubmitSuccess('Новый пользователь был успешно создан.'));
      props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(userFormSubmitFailed());
    }
  });
};

const convertDBUserToEdit = (payload) => {
  const { user: userFromDB } = payload;
  const { image, contact, orders } = userFromDB;
  const blocks = {};

  for (let i = 0; i < orders.length; i += 1) {
    blocks[`order_${orders[i].amoLeadId}`] = orders[i].isAdminBlock;
  }

  const user = {
    id: userFromDB.id,
    name: userFromDB.name,
    birthday: userFromDB.birthday !== null ? new Date(userFromDB.birthday) : null,
    gender: userFromDB.gender,
    country: userFromDB.country,
    city: userFromDB.city,
    tz: userFromDB.tz,
    email: userFromDB.email,
    phone: contact.phone,
    orders,
    blocks,
    children: userFromDB.children.map(item => ({
      id: item.id,
      name: item.name,
      gender: item.gender,
      birthday: item.birthday !== null ? new Date(item.birthday) : null,
    })),
    isAdmin: userFromDB.roles.indexOf('admin') !== -1,
    isCurator: userFromDB.roles.indexOf('curator') !== -1,
    photo: !image.photo ? image.photo : [{ preview: image.photo }],
    isActive: userFromDB.isActive,
    authorizationMethod: userFromDB.authorizationMethod,
  };

  return { user };
};

const getUser = userId => async (dispatch) => {
  const token = getToken();
  dispatch(userGetUserProgress());

  ws.emit('api/academy/users/getById', {
    // userId, token,
    token, payload: { userId },
  }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = userGetUserSuccess(convertDBUserToEdit(payload));
    } else {
      action = userGetUserFailed();
    }

    dispatch(action);
  });
};

const updateUser = async (data, dispatch, props) => {
  const token = getToken();
  const { id, photo } = data;
  const { form } = props;
  const convertedData = convertSetUserToDB(data);
  const images = {};

  const generateImage = async (file) => {
    let result;

    if (file && file.length && file[0] instanceof File) {
      result = await generateImageObject(file[0]);
    } else if (file && file.length && file[0] instanceof Object) {
      result = true;
    } else {
      result = null;
    }

    return result;
  };

  images.photo = await generateImage(photo);

  dispatch(startSubmit(form));
  dispatch(userStartFormSubmit());

  ws.emit('api/academy/users/updateById', {
    // userId: id, payload: convertedData, images, token,
    token, payload: { userId: id, convertedData, images },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(stopSubmit(form));
      dispatch(userFormSubmitSuccess('Пользователь был успешно отредактирован.'));
      props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(userFormSubmitFailed());
    }
  });
};

const transformAsyncData = data => ({
  countries: data.countries.map(country => ({ value: country.id, label: country.name })),
  timezones: data.timezones.map(timezone => ({ value: timezone, label: timezone })),
});

const getAsyncData = () => async (dispatch) => {
  const token = getToken();
  dispatch(userGetAsyncDataProgress());

  ws.emit('api/academy/users/getAsyncData', { token }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = userGetAsyncDataSuccess(transformAsyncData(payload));
    } else {
      action = userGetAsyncDataFailed();
    }

    dispatch(action);
  });
};

const convertSetStatisticsToDB = ({ begin, end }) => ({
  begin: begin.toJSON(),
  end: end.toJSON(),
});

const getStatistics = async (data, dispatch, props) => {
  const token = getToken();
  const { form } = props;
  const convertedData = convertSetStatisticsToDB(data);

  dispatch(startSubmit(form));
  dispatch(userGetStatisticsProgress());

  ws.emit('api/academy/users/statistics', { token, payload: { ...convertedData } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      // reset();
      dispatch(stopSubmit(form));
      dispatch(userGetStatisticsSuccess(payload));
      // props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(userGetStatisticsFailed());
    }
  });
};

const userClearFlashMessage = () => ({
  type: USERS_CLEAR_FLASH_MESSAGE,
});

export {
  USERS_PROGRESS,
  USERS_SUCCESS,
  USERS_FAILED,
  USERS_GET_LIST_PROGRESS,
  USERS_GET_LIST_SUCCESS,
  USERS_GET_LIST_FAILED,
  USERS_FORM_SUBMIT_PROGRESS,
  USERS_FORM_SUBMIT_SUCCESS,
  USERS_FORM_SUBMIT_FAILED,
  USERS_GET_USER_PROGRESS,
  USERS_GET_USER_SUCCESS,
  USERS_GET_USER_FAILED,
  USERS_GET_ASYNC_DATA_PROGRESS,
  USERS_GET_ASYNC_DATA_SUCCESS,
  USERS_GET_ASYNC_DATA_FAILED,
  USERS_GET_STATISTICS_PROGRESS,
  USERS_GET_STATISTICS_SUCCESS,
  USERS_GET_STATISTICS_FAILED,
  USERS_CLEAR_STATE,
  USERS_CLEAR_FLASH_MESSAGE,
  userGetListProgress,
  userGetListSuccess,
  userGetListFailed,
  userStartFormSubmit,
  userFormSubmitSuccess,
  userFormSubmitFailed,
  userGetUserProgress,
  userGetUserSuccess,
  userGetUserFailed,
  userGetAsyncDataProgress,
  userGetAsyncDataSuccess,
  userGetAsyncDataFailed,
  userClearState,
  getUsersList,
  createUser,
  getUser,
  updateUser,
  getAsyncData,
  getStatistics,
  userClearFlashMessage,
};
