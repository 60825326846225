import moment from 'moment';
import { startSubmit, stopSubmit } from 'redux-form';
import ws from '../../ws';
import getToken from './getToken';
import PaginationPageConverter from '../../shared/classes/PaginationPageConverter';

const BANNERS_PROGRESS = 'progress';
const BANNERS_SUCCESS = 'success';
const BANNERS_FAILED = 'failed';

const BANNERS_GET_LIST_PROGRESS = 'BANNERS_GET_LIST_PROGRESS';
const BANNERS_GET_LIST_SUCCESS = 'BANNERS_GET_LIST_SUCCESS';
const BANNERS_GET_LIST_FAILED = 'BANNERS_GET_LIST_FAILED';
const BANNERS_FORM_SUBMIT_PROGRESS = 'BANNERS_FORM_SUBMIT_PROGRESS';
const BANNERS_FORM_SUBMIT_SUCCESS = 'BANNERS_FORM_SUBMIT_SUCCESS';
const BANNERS_FORM_SUBMIT_FAILED = 'BANNERS_FORM_SUBMIT_FAILED';
const BANNERS_GET_BANNER_PROGRESS = 'BANNERS_GET_BANNER_PROGRESS';
const BANNERS_GET_BANNER_SUCCESS = 'BANNERS_GET_BANNER_SUCCESS';
const BANNERS_GET_BANNER_FAILED = 'BANNERS_GET_BANNER_FAILED';
const BANNERS_CLEAR_STATE = 'BANNERS_CLEAR_STATE';

const BANNERS_CLEAR_FLASH_MESSAGE = 'BANNERS_CLEAR_FLASH_MESSAGE';

const bannerGetListProgress = () => ({
  type: BANNERS_GET_LIST_PROGRESS,
});

const bannerGetListSuccess = payload => ({
  type: BANNERS_GET_LIST_SUCCESS,
  payload,
});

const bannerGetListFailed = () => ({
  type: BANNERS_GET_LIST_FAILED,
});

const bannerStartFormSubmit = () => ({
  type: BANNERS_FORM_SUBMIT_PROGRESS,
});

const bannerFormSubmitSuccess = payload => ({
  type: BANNERS_FORM_SUBMIT_SUCCESS,
  payload,
});

const bannerFormSubmitFailed = () => ({
  type: BANNERS_FORM_SUBMIT_FAILED,
});

const bannerGetBannerProgress = () => ({
  type: BANNERS_GET_BANNER_PROGRESS,
});

const bannerGetBannerSuccess = payload => ({
  type: BANNERS_GET_BANNER_SUCCESS,
  payload,
});

const bannerGetBannerFailed = () => ({
  type: BANNERS_GET_BANNER_FAILED,
});

const bannerClearState = () => ({
  type: BANNERS_CLEAR_STATE,
});

const convertListToTable = (data) => {
  const getType = (type) => {
    const intType = parseInt(type, 10);
    let typeText = '';

    switch (intType) {
      case 1: typeText = 'страница Мои курсы'; break;
      case 2: typeText = 'страница Витрина'; break;
      default: typeText = 'не определено'; break;
    }

    return typeText;
  };

  const getDate = date => (date !== null
    ? moment(date).locale('ru').format('DD MMM YY г.')
    : 'не указано');

  return new PaginationPageConverter(data, doc => ({
    id: doc.id,
    name: doc.name,
    type: getType(doc.type),
    start: getDate(doc.dates.start),
    end: getDate(doc.dates.end),
    isVisible: doc.isVisible,
  })).getConvertedData();
};

const getBannersList = params => (dispatch) => {
  const token = getToken();
  dispatch(bannerGetListProgress());

  ws.emit('api/academy/banners/list', { token, payload: params }, (data) => {
    const { status, payload } = data;
    let action;

    if (status === 'ok') {
      action = bannerGetListSuccess(convertListToTable(payload.data));
    } else {
      action = bannerGetListFailed();
    }

    dispatch(action);
  });
};

const convertSetBannerToDB = data => ({
  link: data.useLink && data.link ? data.link : undefined,
  target: data.useLink && data.target ? data.target.value : undefined,
  dates: {
    start: data.start instanceof Date ? moment(data.start).startOf('day').toDate().toUTCString() : null,
    end: data.end instanceof Date ? moment(data.end).endOf('day').toDate().toUTCString() : null,
  },
  isVisible: data.isVisible,
});

const generateImageObject = async file => new Promise((resolve) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    resolve({ buffer: e.target.result, type: file.type });
  };

  reader.readAsArrayBuffer(file);
});

const convertDBBannerToEdit = (payload) => {
  const { banner: bannerFromDB } = payload;
  const targets = [
    { value: '_self', label: 'В текущем окне' },
    { value: '_blank', label: 'В новом окне' },
  ];
  const getImageSize = (type) => {
    const intType = parseInt(type, 10);
    const size = {};

    switch (intType) {
      case 1: size.desktop = '800x385'; size.mobile = '400x510'; break;
      case 2: size.desktop = '800x385'; size.mobile = '400x510'; break;
      default: size.desktop = 'не определено'; size.mobile = 'не определено'; break;
    }

    return size;
  };

  const banner = {
    id: bannerFromDB.id,
    name: bannerFromDB.name,
    useLink: typeof bannerFromDB.link !== 'undefined',
    size: getImageSize(bannerFromDB.type),
    link: typeof bannerFromDB.link !== 'undefined' ? bannerFromDB.link : '',
    target: typeof bannerFromDB.target !== 'undefined'
      ? targets.find(target => target.value === bannerFromDB.target)
      : targets[0],
    start: bannerFromDB.dates.start !== null ? new Date(bannerFromDB.dates.start) : null,
    end: bannerFromDB.dates.end !== null ? new Date(bannerFromDB.dates.end) : null,
    desktop: bannerFromDB.images.desktop !== null ? [{ preview: bannerFromDB.images.desktop }] : null,
    mobile: bannerFromDB.images.mobile !== null ? [{ preview: bannerFromDB.images.mobile }] : null,
    isVisible: bannerFromDB.isVisible,
  };

  return { banner };
};

const getBanner = bannerId => (dispatch) => {
  const token = getToken();
  dispatch(bannerGetBannerProgress());

  ws.emit('api/academy/banners/getById', { token, payload: { bannerId } }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = bannerGetBannerSuccess(convertDBBannerToEdit(payload));
    } else {
      action = bannerGetBannerFailed();
    }

    dispatch(action);
  });
};

const updateBanner = async (data, dispatch, props) => {
  const token = getToken();
  const { id, desktop, mobile } = data;
  const { form } = props;
  const convertedData = convertSetBannerToDB(data);
  const images = {};

  const generateImage = async (file) => {
    let result;

    if (file && file.length && file[0] instanceof File) {
      result = await generateImageObject(file[0]);
    } else if (file && file.length && file[0] instanceof Object) {
      result = true;
    } else {
      result = null;
    }

    return result;
  };

  images.desktop = await generateImage(desktop);
  images.mobile = await generateImage(mobile);

  dispatch(startSubmit(form));
  dispatch(bannerStartFormSubmit());

  ws.emit('api/academy/banners/updateById', { token, payload: { bannerId: id, convertedData, images } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(stopSubmit(form));
      dispatch(bannerFormSubmitSuccess('Баннер был успешно отредактирован.'));
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(bannerFormSubmitFailed());
    }
  });
};

const bannerClearFlashMessage = () => ({
  type: BANNERS_CLEAR_FLASH_MESSAGE,
});

export {
  BANNERS_PROGRESS,
  BANNERS_SUCCESS,
  BANNERS_FAILED,
  BANNERS_GET_LIST_PROGRESS,
  BANNERS_GET_LIST_SUCCESS,
  BANNERS_GET_LIST_FAILED,
  BANNERS_FORM_SUBMIT_PROGRESS,
  BANNERS_FORM_SUBMIT_SUCCESS,
  BANNERS_FORM_SUBMIT_FAILED,
  BANNERS_GET_BANNER_PROGRESS,
  BANNERS_GET_BANNER_SUCCESS,
  BANNERS_GET_BANNER_FAILED,
  BANNERS_CLEAR_STATE,
  BANNERS_CLEAR_FLASH_MESSAGE,
  bannerGetListProgress,
  bannerGetListSuccess,
  bannerGetListFailed,
  bannerStartFormSubmit,
  bannerFormSubmitSuccess,
  bannerFormSubmitFailed,
  bannerGetBannerProgress,
  bannerGetBannerSuccess,
  bannerGetBannerFailed,
  bannerClearState,
  getBannersList,
  getBanner,
  updateBanner,
  bannerClearFlashMessage,
};
