import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';

import GroupTablePage from '../../../Groups/GroupTable/index';
import GroupCreatePage from '../../../Groups/GroupCreate/index';
import GroupEditPage from '../../../Groups/GroupEdit/index';

export default () => (
  <Switch>
    <ProtectedRoute path="/groups" exact component={GroupTablePage} />
    <ProtectedRoute path="/groups/create" component={GroupCreatePage} />
    <ProtectedRoute path="/groups/:groupId" exact component={GroupEditPage} />
  </Switch>
);
