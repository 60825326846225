import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import {
  Field, reduxForm, formValueSelector, arrayRemoveAll,
} from 'redux-form';
import PropTypes from 'prop-types';
import renderField from '../../../../shared/components/form/TextField';
import renderSelectField from '../../../../shared/components/form/Select';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import store from '../../../App/store';
import validate from '../../validate';

class WizardFormOne extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line
    initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    asyncData: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor() {
    super();
    this.state = {
      showDates: false,
      showDays: false,
    };
  }

  componentDidMount() {
    const selector = formValueSelector('maraphon_flows_edit_form');
    const maraphonType = selector(store.getState(), 'maraphonType');

    if (maraphonType) {
      if (maraphonType.value === 'flow') {
        this.setState({ showDates: true });
      } else if (maraphonType.value === 'instant') {
        this.setState({ showDays: true });
      }
    }
  }

  handleChange = (type) => {
    const showDates = type.value === 'flow';
    const showDays = type.value === 'instant';
    this.setState({ showDates, showDays });
  };

  handleChangeCourse = () => {
    store.dispatch(arrayRemoveAll('maraphon_flows_edit_form', 'tasks'));
  };

  render() {
    const { handleSubmit, asyncData } = this.props;
    const { showDates, showDays } = this.state;
    const { maraphons } = asyncData;

    return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Заполните общею информацию о потоке</h3>
        <div className="form__form-group">
          <span className="form__form-group-label">Название потока</span>
          <div className="form__form-group-field">
            <Field
              name="name"
              component={renderField}
              type="text"
              placeholder="Название потока"
            />
          </div>
        </div>
        <div className="form__form-group form__form-select">
          <span className="form__form-group-label">Марафон</span>
          <div className="form__form-group-field">
            <Field
              name="maraphon"
              component={renderSelectField}
              placeholder="Выберите марафон"
              options={maraphons}
              changeAction={this.handleChangeCourse}
            />
          </div>
        </div>
        <div className="form__form-group form__form-select">
          <span className="form__form-group-label">Тип марафона</span>
          <div className="form__form-group-field">
            <Field
              name="maraphonType"
              component={renderSelectField}
              placeholder="Выберите тип марафона"
              options={[
                { value: 'flow', label: 'Поток' },
                { value: 'instant', label: 'Моментальный' },
              ]}
              changeAction={this.handleChange}
            />
          </div>
        </div>
        {showDates && (
        <div className="form__form-group">
          <span className="form__form-group-label">Дата старта</span>
          <div className="form__form-group-field">
            <Field
              name="dateBegin"
              component={renderDatePickerField}
            />
          </div>
        </div>
        )}
        {showDates && (
        <div className="form__form-group">
          <span className="form__form-group-label">Дата окончания</span>
          <div className="form__form-group-field">
            <Field
              name="dateEnd"
              component={renderDatePickerField}
            />
          </div>
        </div>
        )}
        {showDates && (
        <div className="form__form-group">
          <span className="form__form-group-label">Доступен до</span>
          <div className="form__form-group-field">
            <Field
              name="dateAvailable"
              component={renderDatePickerField}
            />
          </div>
        </div>
        )}
        {showDays && (
          <div className="form__form-group">
            <span className="form__form-group-label">Окончание через N дней</span>
            <div className="form__form-group-field">
              <Field
                name="ending"
                component={renderField}
                type="number"
                placeholder="Количество дней"
              />
            </div>
          </div>
        )}
        {showDays && (
          <div className="form__form-group">
            <span className="form__form-group-label">Доступен количество дней</span>
            <div className="form__form-group-field">
              <Field
                name="days"
                component={renderField}
                type="number"
                placeholder="Количество дней"
              />
            </div>
          </div>
        )}
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" disabled className="previous">Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next">Следующий шаг</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'maraphon_flows_edit_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: false,
  validate,
})(WizardFormOne);
