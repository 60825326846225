import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';

import DuplicateTablePage from '../../../Duplicates/DuplicateTable/index';
import DuplicateEditPage from '../../../Duplicates/DuplicateEdit/index';

export default () => (
  <Switch>
    <ProtectedRoute path="/duplicates" exact component={DuplicateTablePage} />
    <ProtectedRoute path="/duplicates/:email" exact component={DuplicateEditPage} />
  </Switch>
);
