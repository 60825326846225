import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import {
  Button, ButtonToolbar, Card, CardBody, Col, Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field, reduxForm, formValueSelector, reset,
} from 'redux-form';
import {
  IMAGES_SUCCESS,
  getImagesList as getImagesListAction,
  imageClearState as imageClearStateAction,
  imageClearFlashMessage as imageClearFlashMessageAction,
} from '../../../../redux/actions/imagesAction';
import renderField from '../../../../shared/components/form/TextField';
import DataTable from '../../../../shared/components/data-table';
import { COMPLETED, WAIT } from '../../../../shared/components/data-table/submitStatuses';
import { DATA, ACTION } from '../../../../shared/components/data-table/columnTypes';
import { LINK } from '../../../../shared/components/data-table/buttonTypes';
import { FullWideNotification } from '../../../../shared/components/Notification';
import store from '../../../App/store';

const ImageTable = ({
  getImagesList,
  imageClearState,
  imageClearFlashMessage,
  showNotification,
  imagesList,
  pagination,
  loadStatus,
  flashMessage,
  resetForm,
}) => {
  const [data, setData] = useState({
    columns: [],
    buttons: [],
    isFind: false,
    status: WAIT,
  });

  const clearFilters = () => {
    resetForm(ImageTable.FORM_NAME);
    setData({ ...data, isFind: true });
  };

  const getConditions = () => {
    const selector = formValueSelector(ImageTable.FORM_NAME);
    const name = selector(store.getState(), 'name');

    return { name };
  };

  const handleFind = () => {
    setData({ ...data, isFind: true });
  };

  const show = (color, message) => showNotification({
    notification: <FullWideNotification
      color={color}
      message={message}
    />,
    position: 'full',
  });

  useEffect(() => {
    if (loadStatus === IMAGES_SUCCESS) {
      setData({ ...data, isFind: false });
    }
  }, [loadStatus]);

  useEffect(() => {
    if (flashMessage && flashMessage.length) {
      setTimeout(() => {
        show('success', flashMessage);
        imageClearFlashMessage();
      }, 1000);
    }
  }, [flashMessage]);

  useEffect(() => {
    const columns = [{
      label: '#',
      dbColumn: 'createdAt',
      listColumn: 'number',
      sortable: true,
      width: 5,
      type: DATA,
    }, {
      label: 'Название изображения',
      dbColumn: 'name',
      listColumn: 'name',
      sortable: true,
      width: 45,
      type: DATA,
    }, {
      label: 'Изображение',
      dbColumn: 'image',
      listColumn: 'image',
      sortable: false,
      width: 25,
      handle: link => (link !== null
        // eslint-disable-next-line react/jsx-no-target-blank
        ? <a href={link} target="_blank"><img src={link} alt="" style={{ maxWidth: '100px', maxHeight: '70px' }} /></a>
        : 'не найдено'),
      type: DATA,
    }, {
      label: 'Создан',
      dbColumn: 'createdAt',
      listColumn: 'createdAt',
      sortable: true,
      width: 20,
      type: DATA,
    }, {
      label: 'Действия',
      dbColumn: '_id',
      listColumn: 'id',
      sortable: false,
      width: 5,
      type: ACTION,
    }];

    const buttons = [{
      name: 'edit',
      color: 'primary',
      icon: 'edit',
      type: LINK,
      link: '/images/',
      text: 'Редактировать',
    }];

    setData(({
      ...data, columns, buttons, status: COMPLETED,
    }));

    return () => {
      imageClearState();
    };
  }, []);

  return (
    <Fragment>
      {data.status === COMPLETED && (
        <Fragment>
          <Row>
            <Col md={12} lg={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Поиск по изображениям</h5>
                  </div>
                  <form className="form gap-20">
                    <Col className="p-0">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Название изображения</span>
                        <div className="form__form-group-field">
                          <Field
                            name="name"
                            component={renderField}
                            type="text"
                            placeholder="Название изображения"
                          />
                        </div>
                      </div>
                    </Col>
                  </form>
                  <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                    <Button color="primary" type="button" onClick={handleFind}>Найти</Button>
                    <Button color="secondary" type="button" onClick={clearFilters}>Очистить</Button>
                  </ButtonToolbar>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <DataTable
                addButton={{ to: '/images/create', caption: 'Добавить изображения' }}
                columns={data.columns}
                buttons={data.buttons}
                getConditions={getConditions}
                getList={getImagesList}
                list={imagesList}
                pagination={pagination}
                isFind={data.isFind}
                loadListStatus={loadStatus}
                declensions={['изображение', 'изображения', 'изображений']}
              />
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

ImageTable.FORM_NAME = 'images_table';

ImageTable.propTypes = {
  getImagesList: PropTypes.func.isRequired,
  imageClearState: PropTypes.func.isRequired,
  imageClearFlashMessage: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  imagesList: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.any),
  ).isRequired,
  // eslint-disable-next-line react/require-default-props
  pagination: PropTypes.objectOf(PropTypes.any),
  loadStatus: PropTypes.string.isRequired,
  flashMessage: PropTypes.string.isRequired,
  resetForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  imagesList: state.images.list,
  pagination: state.images.pagination,
  loadStatus: state.images.loadListStatus,
  flashMessage: state.images.flash,
});

const mapDispatchToProps = dispatch => ({
  getImagesList: params => dispatch(getImagesListAction(params)),
  imageClearState: () => dispatch(imageClearStateAction()),
  imageClearFlashMessage: () => dispatch(imageClearFlashMessageAction()),
  resetForm: formName => dispatch(reset(formName)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(withRouter(ImageTable)));

export default reduxForm({
  form: ImageTable.FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(withConnect);
