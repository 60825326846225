import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field } from 'redux-form';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import renderField from '../../../shared/components/form/TextField';
import renderEditor from '../../../shared/components/tinymce/TinyMCE';

const buttonTop = {
  marginTop: '15px',
};

class RenderBilletValues extends PureComponent {
  static propTypes = {
    fields: PropTypes.objectOf(PropTypes.any).isRequired,
    meta: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  render() {
    const { fields, meta: { error, submitFailed } } = this.props;

    return (
      <div className="form__form-group">
        <ButtonToolbar style={buttonTop}>
          <Button color="success" size="sm" onClick={() => fields.push({})}>Добавить плашку</Button>
        </ButtonToolbar>
        {submitFailed && error
        && (
          <div className="form__form-group">
            <div>
              <span className="form__form-group-error">{error}</span>
            </div>
          </div>
        )}
        {fields.map((billet, index) => (
          <div key={index}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Название плашки # {index + 1} (шорт-код %billet{index + 1}%)
              </span>
              <div className="form__form-group-field">
                <Field
                  name={`${billet}.name`}
                  component={renderField}
                  type="text"
                  placeholder="Название плашки"
                />
                <div className="form__form-group-icon">
                  <TrashCanOutlineIcon onClick={() => fields.remove(index)} />
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Плашка # {index + 1}</span>
              <div className="form__form-group-field">
                <Field
                  name={`${billet}.content`}
                  component={renderEditor}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default RenderBilletValues;
