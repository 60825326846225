import ws from '../../ws';
import getToken from '../../redux/actions/getToken';

class AmoIdError extends Error {
  amoId = 'Такой AMO ID уже существует';
}

const asyncValidate = async (values) => {
  const token = getToken();
  const data = { amoId: values.amoId };

  data.id = Object.prototype.hasOwnProperty.call(values, 'id') ? values.id : null;

  return new Promise((resolve, reject) => {
    ws.emit('api/academy/hubs/checkAmoId', { token, payload: data }, (result) => {
      const { status, payload } = result;
      const { isFind } = payload;

      if (status === 'ok' && isFind) {
        reject(new AmoIdError());
      } else {
        resolve();
      }
    });
  });
};

export default asyncValidate;
