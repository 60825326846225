import moment from 'moment';
import { startSubmit, stopSubmit } from 'redux-form';
import ws from '../../ws';
import getToken from './getToken';
import PaginationPageConverter from '../../shared/classes/PaginationPageConverter';

const PAGES_PROGRESS = 'progress';
const PAGES_SUCCESS = 'success';
const PAGES_FAILED = 'failed';

const PAGES_GET_LIST_PROGRESS = 'PAGES_GET_LIST_PROGRESS';
const PAGES_GET_LIST_SUCCESS = 'PAGES_GET_LIST_SUCCESS';
const PAGES_GET_LIST_FAILED = 'PAGES_GET_LIST_FAILED';
const PAGES_FORM_SUBMIT_PROGRESS = 'PAGES_FORM_SUBMIT_PROGRESS';
const PAGES_FORM_SUBMIT_SUCCESS = 'PAGES_FORM_SUBMIT_SUCCESS';
const PAGES_FORM_SUBMIT_FAILED = 'PAGES_FORM_SUBMIT_FAILED';
const PAGES_GET_PAGE_PROGRESS = 'PAGES_GET_PAGE_PROGRESS';
const PAGES_GET_PAGE_SUCCESS = 'PAGES_GET_PAGE_SUCCESS';
const PAGES_GET_PAGE_FAILED = 'PAGES_GET_PAGE_FAILED';
const PAGES_CLEAR_STATE = 'PAGES_CLEAR_STATE';

const PAGES_CLEAR_FLASH_MESSAGE = 'PAGES_CLEAR_FLASH_MESSAGE';

const pageGetListProgress = () => ({
  type: PAGES_GET_LIST_PROGRESS,
});

const pageGetListSuccess = payload => ({
  type: PAGES_GET_LIST_SUCCESS,
  payload,
});

const pageGetListFailed = () => ({
  type: PAGES_GET_LIST_FAILED,
});

const pageStartFormSubmit = () => ({
  type: PAGES_FORM_SUBMIT_PROGRESS,
});

const pageFormSubmitSuccess = payload => ({
  type: PAGES_FORM_SUBMIT_SUCCESS,
  payload,
});

const pageFormSubmitFailed = () => ({
  type: PAGES_FORM_SUBMIT_FAILED,
});

const pageGetPageProgress = () => ({
  type: PAGES_GET_PAGE_PROGRESS,
});

const pageGetPageSuccess = payload => ({
  type: PAGES_GET_PAGE_SUCCESS,
  payload,
});

const pageGetPageFailed = () => ({
  type: PAGES_GET_PAGE_FAILED,
});

const pageClearState = () => ({
  type: PAGES_CLEAR_STATE,
});

const convertListToTable = data => new PaginationPageConverter(data, doc => ({
  id: doc.id,
  name: doc.name,
  createdAt: moment(doc.createdAt).locale('ru').format('DD MMM YY г., HH:mm'),
})).getConvertedData();

const getPagesList = params => (dispatch) => {
  const token = getToken();
  dispatch(pageGetListProgress());

  ws.emit('api/academy/pages/list', { token, payload: params }, (data) => {
    const { status, payload } = data;
    let action;

    if (status === 'ok') {
      action = pageGetListSuccess(convertListToTable(payload.data));
    } else {
      action = pageGetListFailed();
    }

    dispatch(action);
  });
};

const convertSetPageToDB = (data) => {
  const videos = Object.prototype.hasOwnProperty.call(data, 'videos') ? data.videos : [];

  return {
    ...data,
    videos: videos.map(video => video.video),
  };
};

const createPage = (data, dispatch, props) => {
  const token = getToken();
  const { form, reset } = props;
  const convertedData = convertSetPageToDB(data);

  dispatch(startSubmit(form));
  dispatch(pageStartFormSubmit());

  ws.emit('api/academy/pages/create', {
    // payload: convertedData, token,
    token, payload: { convertedData },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      reset();
      dispatch(stopSubmit(form));
      dispatch(pageFormSubmitSuccess('Новая страница была успешно создана.'));
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(pageFormSubmitFailed());
    }
  });
};

const convertDBPageToEdit = (payload) => {
  const { page: pageFromDB } = payload;
  const { videos } = pageFromDB;

  const page = {
    id: pageFromDB.id,
    name: pageFromDB.name,
    url: pageFromDB.url,
    videos: videos.map(video => ({ video })),
    view: pageFromDB.view,
  };

  return { page };
};

const getPage = pageId => async (dispatch) => {
  const token = getToken();
  dispatch(pageGetPageProgress());

  ws.emit('api/academy/pages/getById', {
    // pageId, token,
    token, payload: { pageId },
  }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = pageGetPageSuccess(convertDBPageToEdit(payload));
    } else {
      action = pageGetPageFailed();
    }

    dispatch(action);
  });
};

const updatePage = (data, dispatch, props) => {
  const token = getToken();
  const { id } = data;
  const { form } = props;
  const convertedData = convertSetPageToDB(data);

  dispatch(startSubmit(form));
  dispatch(pageStartFormSubmit());

  ws.emit('api/academy/pages/updateById', {
    // pageId: id, payload: convertedData, token,
    token, payload: { pageId: id, convertedData },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(stopSubmit(form));
      dispatch(pageFormSubmitSuccess('Страница была успешно отредактирована.'));
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(pageFormSubmitFailed());
    }
  });
};

const pageClearFlashMessage = () => ({
  type: PAGES_CLEAR_FLASH_MESSAGE,
});

export {
  PAGES_PROGRESS,
  PAGES_SUCCESS,
  PAGES_FAILED,
  PAGES_GET_LIST_PROGRESS,
  PAGES_GET_LIST_SUCCESS,
  PAGES_GET_LIST_FAILED,
  PAGES_FORM_SUBMIT_PROGRESS,
  PAGES_FORM_SUBMIT_SUCCESS,
  PAGES_FORM_SUBMIT_FAILED,
  PAGES_GET_PAGE_PROGRESS,
  PAGES_GET_PAGE_SUCCESS,
  PAGES_GET_PAGE_FAILED,
  PAGES_CLEAR_STATE,
  PAGES_CLEAR_FLASH_MESSAGE,
  pageGetListProgress,
  pageGetListSuccess,
  pageGetListFailed,
  pageStartFormSubmit,
  pageFormSubmitSuccess,
  pageFormSubmitFailed,
  pageGetPageProgress,
  pageGetPageSuccess,
  pageGetPageFailed,
  pageClearState,
  getPagesList,
  createPage,
  getPage,
  updatePage,
  pageClearFlashMessage,
};
