import update from 'immutability-helper';
import {
  DUPLICATES_PROGRESS,
  DUPLICATES_SUCCESS,
  DUPLICATES_FAILED,
  DUPLICATES_GET_LIST_PROGRESS,
  DUPLICATES_GET_LIST_SUCCESS,
  DUPLICATES_GET_LIST_FAILED,
  DUPLICATES_GET_DUPLICATES_PROGRESS,
  DUPLICATES_GET_DUPLICATES_SUCCESS,
  DUPLICATES_GET_DUPLICATES_FAILED,
  DUPLICATES_CLEAR_STATE,
  DUPLICATES_CLEAR_FLASH_MESSAGE,
} from '../actions/duplicatesAction';

const initialState = {
  loadListStatus: '', // progress/success/failed
  loadActiveStatus: '', // progress/success/failed
  list: [],
  pagination: {},
  active: {},
  flash: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DUPLICATES_GET_LIST_PROGRESS: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: DUPLICATES_PROGRESS },
        },
      );
    }
    case DUPLICATES_GET_LIST_SUCCESS: {
      return update(
        state, {
          list: { $set: action.payload.list },
          pagination: { $set: action.payload.pagination },
          loadListStatus: { $set: DUPLICATES_SUCCESS },
        },
      );
    }
    case DUPLICATES_GET_LIST_FAILED: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: DUPLICATES_FAILED },
        },
      );
    }
    case DUPLICATES_GET_DUPLICATES_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: DUPLICATES_PROGRESS },
        },
      );
    }
    case DUPLICATES_GET_DUPLICATES_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload },
          loadActiveStatus: { $set: DUPLICATES_SUCCESS },
        },
      );
    }
    case DUPLICATES_GET_DUPLICATES_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: DUPLICATES_FAILED },
        },
      );
    }
    case DUPLICATES_CLEAR_STATE: {
      return update(
        state,
        {
          loadListStatus: { $set: '' },
          loadActiveStatus: { $set: '' },
          list: { $set: [] },
          pagination: { $set: {} },
          active: { $set: {} },
        },
      );
    }
    case DUPLICATES_CLEAR_FLASH_MESSAGE: {
      return update(
        state, {
          flash: { $set: '' },
        },
      );
    }
    default:
      return state;
  }
}
