const validate = (values) => {
  const errors = {};
  const requiredErrorMessage = 'Это поле является обязательным';

  if (!values.title) {
    errors.title = requiredErrorMessage;
  } else if (values.title.length < 3) {
    errors.title = 'Введите не менее 3-х символов';
  }

  if (values.name && values.name.length < 2) {
    errors.name = 'Введите не менее 2-х символов';
  }

  if (!values.question) {
    errors.question = requiredErrorMessage;
  } else if (values.question.length < 2) {
    errors.question = 'Введите не менее 2-x символов';
  }

  if (values.videos && values.videos.length) {
    const videoArrayErrors = [];

    values.videos.forEach((video, videoIndex) => {
      const videoErrors = {};

      if (typeof video.video === 'undefined') {
        videoErrors.video = requiredErrorMessage;
        videoArrayErrors[videoIndex] = videoErrors;
      }
    });

    if (videoArrayErrors.length) {
      errors.videos = videoArrayErrors;
    }
  }

  if (values.tags && values.tags.length) {
    const tagsArrayErrors = [];

    values.tags.forEach((tag, tagIndex) => {
      const tagErrors = {};

      if (typeof tag.tag === 'undefined') {
        tagErrors.tag = requiredErrorMessage;
        tagsArrayErrors[tagIndex] = tagErrors;
      }
    });

    if (tagsArrayErrors.length) {
      errors.tags = tagsArrayErrors;
    }
  }

  if (!values.academy || !values.academy.length) {
    errors.academy = { _error: 'Вопрос должен входить хотя бы в один курс' };
  } else {
    const courseArrayErrors = [];

    values.academy.forEach((course, courseIndex) => {
      const courseErrors = {};

      if (!course.course) {
        courseErrors.course = requiredErrorMessage;
        courseArrayErrors[courseIndex] = courseErrors;
      }

      if (course && course.themes && course.themes.length) {
        const courseThemeArrayErrors = [];

        course.themes.forEach((theme, themeIndex) => {
          if (typeof theme.theme === 'undefined') {
            courseThemeArrayErrors[themeIndex] = { theme: requiredErrorMessage };
          }
        });

        if (courseThemeArrayErrors.length) {
          courseErrors.themes = courseThemeArrayErrors;
          courseArrayErrors[courseIndex] = courseErrors;
        }
      } else {
        courseErrors.themes = { _error: 'Вы должны добавить хотя бы одну тему' };
        courseArrayErrors[courseIndex] = courseErrors;
      }
    });

    if (courseArrayErrors.length) {
      errors.academy = courseArrayErrors;
    }
  }

  return errors;
};

export default validate;
