import moment from 'moment';
import { startSubmit, stopSubmit } from 'redux-form';
import ws from '../../ws';
import getToken from './getToken';
import PaginationPageConverter from '../../shared/classes/PaginationPageConverter';

const FLOWS_PROGRESS = 'progress';
const FLOWS_SUCCESS = 'success';
const FLOWS_FAILED = 'failed';

const FLOWS_GET_LIST_PROGRESS = 'FLOWS_GET_LIST_PROGRESS';
const FLOWS_GET_LIST_SUCCESS = 'FLOWS_GET_LIST_SUCCESS';
const FLOWS_GET_LIST_FAILED = 'FLOWS_GET_LIST_FAILED';
const FLOWS_FORM_SUBMIT_PROGRESS = 'FLOWS_FORM_SUBMIT_PROGRESS';
const FLOWS_FORM_SUBMIT_SUCCESS = 'FLOWS_FORM_SUBMIT_SUCCESS';
const FLOWS_FORM_SUBMIT_FAILED = 'FLOWS_FORM_SUBMIT_FAILED';
const FLOWS_GET_COURSE_PROGRESS = 'FLOWS_GET_COURSE_PROGRESS';
const FLOWS_GET_COURSE_SUCCESS = 'FLOWS_GET_COURSE_SUCCESS';
const FLOWS_GET_COURSE_FAILED = 'FLOWS_GET_COURSE_FAILED';
const FLOWS_GET_ASYNC_DATA_PROGRESS = 'FLOWS_GET_ASYNC_DATA_PROGRESS';
const FLOWS_GET_ASYNC_DATA_SUCCESS = 'FLOWS_GET_ASYNC_DATA_SUCCESS';
const FLOWS_GET_ASYNC_DATA_FAILED = 'FLOWS_GET_ASYNC_DATA_FAILED';
const FLOWS_CLEAR_STATE = 'FLOWS_CLEAR_STATE';

const FLOWS_CLEAR_FLASH_MESSAGE = 'FLOWS_CLEAR_FLASH_MESSAGE';

const flowGetListProgress = () => ({
  type: FLOWS_GET_LIST_PROGRESS,
});

const flowGetListSuccess = payload => ({
  type: FLOWS_GET_LIST_SUCCESS,
  payload,
});

const flowGetListFailed = () => ({
  type: FLOWS_GET_LIST_FAILED,
});

const flowStartFormSubmit = () => ({
  type: FLOWS_FORM_SUBMIT_PROGRESS,
});

const flowFormSubmitSuccess = payload => ({
  type: FLOWS_FORM_SUBMIT_SUCCESS,
  payload,
});

const flowFormSubmitFailed = () => ({
  type: FLOWS_FORM_SUBMIT_FAILED,
});

const flowGetFlowProgress = () => ({
  type: FLOWS_GET_COURSE_PROGRESS,
});

const flowGetFlowSuccess = payload => ({
  type: FLOWS_GET_COURSE_SUCCESS,
  payload,
});

const flowGetFlowFailed = () => ({
  type: FLOWS_GET_COURSE_FAILED,
});

const flowGetAsyncDataProgress = () => ({
  type: FLOWS_GET_ASYNC_DATA_PROGRESS,
});

const flowGetAsyncDataSuccess = payload => ({
  type: FLOWS_GET_ASYNC_DATA_SUCCESS,
  payload,
});

const flowGetAsyncDataFailed = () => ({
  type: FLOWS_GET_ASYNC_DATA_FAILED,
});

const flowClearState = () => ({
  type: FLOWS_CLEAR_STATE,
});

const convertListToTable = data => new PaginationPageConverter(data, doc => ({
  id: doc.id,
  name: doc.name,
  courseType: doc.courseType,
  title: doc.course.name,
  begin: doc.date.begin !== null ? moment(doc.date.begin).locale('ru').format('DD MMM YY г.') : 'отсутствует',
  end: doc.date.end !== null ? moment(doc.date.end).locale('ru').format('DD MMM YY г.') : 'отсутствует',
  createdAt: moment(doc.createdAt).locale('ru').format('DD MMM YY г., HH:mm'),
})).getConvertedData();

const getFlowsList = params => (dispatch) => {
  const token = getToken();
  dispatch(flowGetListProgress());

  ws.emit('api/academy/flows/list', { token, payload: params }, (data) => {
    const { status, payload } = data;
    let action;

    if (status === 'ok') {
      action = flowGetListSuccess(convertListToTable(payload.data));
    } else {
      action = flowGetListFailed();
    }

    dispatch(action);
  });
};

const convertSetFlowToDB = (data) => {
  const {
    name,
    course,
    courseType,
    dateBegin,
    dateEnd,
    dateAvailable,
    ending,
    days,
    packages: packageList,
    curators: curatorList,
    themes: themeList,
    homeWork: homeWorkList,
    materials: materialList,
  } = data;

  const date = courseType.value !== 'flow' ? {
    begin: null, end: null, available: null, ending, days,
  } : {
    begin: dateBegin.toUTCString(),
    end: dateEnd.toUTCString(),
    available: dateAvailable.toUTCString(),
    ending: null,
    days: null,
  };

  const packages = (() => {
    const packageArray = [];

    for (let i = 0; i < packageList.length; i += 1) {
      const bonuses = [];

      if (typeof packageList[i].bonuses !== 'undefined') {
        for (let j = 0; j < packageList[i].bonuses.length; j += 1) {
          const { value, selected } = packageList[i].bonuses[j].bonus;
          const bonus = { flow: value };
          const packs = [];

          if (selected) {
            const keys = Object.keys(selected);

            for (let k = 0; k < keys.length; k += 1) {
              if (selected[keys[k]]) {
                packs.push(keys[k]);
              }
            }
          }

          bonus.packages = packs;
          bonuses.push(bonus);
        }
      }

      packageArray.push({
        _id: packageList[i].id,
        name: packageList[i].name,
        amoId: packageList[i].amoId,
        bonuses,
      });
    }

    return packageArray;
  })();

  /* eslint no-underscore-dangle: 0 */
  const availablePackages = packages.map(item => item._id);

  const curators = (() => {
    const curatorArray = [];

    if (Array.isArray(curatorList)) {
      for (let i = 0; i < curatorList.length; i += 1) {
        curatorArray.push(curatorList[i].curator.value);
      }
    }

    return curatorArray;
  })();

  const themes = (() => {
    const themeArray = [];
    const materialKeys = typeof materialList === 'object' ? Object.keys(materialList) : [];

    if (Array.isArray(themeList)) {
      for (let i = 0; i < themeList.length; i += 1) {
        const themeObject = {
          theme: themeList[i].id,
          name: 'name' in themeList[i] ? themeList[i].name : null,
          day: themeList[i].day.value,
          month: 0,
          isBonuses: 'isBonuses' in themeList[i] ? themeList[i].isBonuses : false,
          isActive: 'isActive' in themeList[i] ? themeList[i].isActive : false,
          isShowQuestions: 'isShowQuestions' in themeList[i] ? themeList[i].isShowQuestions : false,
        };

        const { package: themePackage } = themeList[i];
        const packageArray = [];

        if (typeof themePackage === 'object') {
          const packageKeys = Object.keys(themePackage);

          for (let j = 0; j < packageKeys.length; j += 1) {
            if (themePackage[packageKeys[j]] && availablePackages.indexOf(packageKeys[j]) !== -1) {
              packageArray.push(packageKeys[j]);
            }
          }
        }

        const materialArray = [];

        for (let j = 0; j < materialKeys.length; j += 1) {
          if (materialKeys[j] === themeList[i].id) {
            for (let k = 0; k < materialList[materialKeys[j]].length; k += 1) {
              materialArray.push({
                material: materialList[materialKeys[j]][k].id,
                importance: materialList[materialKeys[j]][k].importance.value,
              });
            }

            break;
          }
        }

        themeObject.materials = materialArray;
        themeObject.package = packageArray;
        themeArray.push(themeObject);
      }
    }

    return themeArray;
  })();

  const homeWork = (() => {
    const homeWorkArray = [];

    if (typeof homeWorkList === 'object') {
      const homeWorkKeys = Object.keys(homeWorkList);

      for (let i = 0; i < homeWorkKeys.length; i += 1) {
        if (homeWorkList[homeWorkKeys[i]] && availablePackages.indexOf(homeWorkKeys[i]) !== -1) {
          homeWorkArray.push(homeWorkKeys[i]);
        }
      }
    }

    return homeWorkArray;
  })();

  const convertedData = {
    name,
    course: typeof course === 'string' ? course : course.value,
    courseType: typeof courseType === 'string' ? courseType : courseType.value,
    date,
    packages,
    curators,
    themes,
    homeWork,
  };

  return convertedData;
};

const createFlow = async (data, dispatch, props) => {
  const token = getToken();
  const { form, reset } = props;
  const convertedData = convertSetFlowToDB(data);

  dispatch(startSubmit(form));
  dispatch(flowStartFormSubmit());

  ws.emit('api/academy/flows/create', {
    // payload: convertedData, token,
    token, payload: { convertedData },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      reset();
      dispatch(stopSubmit(form));
      dispatch(flowFormSubmitSuccess('Новый поток был успешно создан.'));
      props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(flowFormSubmitFailed());
    }
  });
};

const convertDBFlowToEdit = (payload) => {
  const { flow: flowFromDB } = payload;
  const { date } = flowFromDB;

  const flow = {
    id: flowFromDB.id,
    name: flowFromDB.name,
    course: {
      value: flowFromDB.course.id,
      label: flowFromDB.course.name,
      themes: flowFromDB.course.themes,
    },
    courseType: { value: flowFromDB.courseType, label: flowFromDB.courseType === 'flow' ? 'Поток' : 'Моментальный' },
    dateBegin: date.begin !== null ? new Date(date.begin) : null,
    dateEnd: date.end !== null ? new Date(date.end) : null,
    dateAvailable: date.available !== null ? new Date(date.available) : null,
    ending: date.ending !== null ? date.ending : null,
    days: date.days !== null ? date.days : null,
    packages: flowFromDB.packages.map(item => ({
      id: item.id,
      name: item.name,
      amoId: item.amoId,
      bonuses: item.bonuses.map(bonus => ({
        bonus: {
          value: bonus.id,
          label: bonus.name,
          checkboxes: bonus.packages,
          selected: bonus.selected.reduce((o, key) => ({ ...o, [key]: true }), {}),
        },
      })),
    })),
    curators: flowFromDB.curators.map(item => ({ curator: { value: item.id, label: item.name } })),
    themes: flowFromDB.themes.map((item) => {
      const theme = {
        id: item.theme.id,
        original: item.theme.name,
        isBonuses: item.isBonuses,
        isActive: item.isActive,
        isShowQuestions: item.isShowQuestions,
        month: item.month,
        day: { value: String(item.day), label: null },
        package: (() => {
          const packages = {};

          for (let i = 0; i < item.package.length; i += 1) {
            packages[item.package[i]] = true;
          }

          return packages;
        })(),
        materials: item.theme.tasks.map(material => material.id),
      };

      if (item.name) {
        theme.name = item.name;
      }

      return theme;
    }),
    materials: (() => {
      const text = ['Обязательно', 'Желательно', 'Для общего развития'];
      const materials = {};

      for (let i = 0; i < flowFromDB.themes.length; i += 1) {
        const total = [];

        for (let j = 0; j < flowFromDB.themes[i].materials.length; j += 1) {
          const material = {
            id: flowFromDB.themes[i].materials[j].id,
            name: flowFromDB.themes[i].materials[j].header,
          };

          if (typeof flowFromDB.themes[i].materials[j].importance !== 'undefined') {
            material.importance = {
              value: flowFromDB.themes[i].materials[j].importance,
              label: (() => {
                let index;

                switch (flowFromDB.themes[i].materials[j].importance) {
                  case 'required':
                    index = 0;
                    break;
                  case 'preferably':
                    index = 1;
                    break;
                  default:
                    index = 2;
                    break;
                }

                return text[index];
              })(),
            };
          }

          total.push(material);
        }

        materials[flowFromDB.themes[i].theme.id] = total;
      }

      return materials;
    })(),
    homeWork: (() => {
      const packages = {};

      for (let i = 0; i < flowFromDB.homeWork.length; i += 1) {
        packages[flowFromDB.homeWork[i]] = true;
      }

      return packages;
    })(),
  };

  return { flow };
};

const getFlow = flowId => async (dispatch) => {
  const token = getToken();
  dispatch(flowGetFlowProgress());

  ws.emit('api/academy/flows/getById', {
    // flowId, token,
    token, payload: { flowId },
  }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = flowGetFlowSuccess(convertDBFlowToEdit(payload));
    } else {
      action = flowGetFlowFailed();
    }

    dispatch(action);
  });
};

const updateFlow = async (data, dispatch, props) => {
  const token = getToken();
  const { id } = data;
  const { form } = props;
  const convertedData = convertSetFlowToDB(data);

  dispatch(startSubmit(form));
  dispatch(flowStartFormSubmit());

  ws.emit('api/academy/flows/updateById', {
    // flowId: id, payload: convertedData, token,
    token, payload: { flowId: id, convertedData },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(stopSubmit(form));
      dispatch(flowFormSubmitSuccess('Поток был успешно отредактирован.'));
      props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(flowFormSubmitFailed());
    }
  });
};

const transformAsyncData = data => ({
  courses: data.courses.map(task => ({ value: task.id, label: task.name, themes: task.themes })),
  themes: data.themes.map(task => ({ value: task.id, label: task.name, materials: task.tasks })),
  materials: data.materials.map(task => ({ value: task.id, label: task.header })),
  curators: data.curators.map(task => ({ value: task.id, label: task.name })),
  instantFlows: data.instantFlows.map(task => ({ value: task.id, label: task.name, checkboxes: task.checkboxes })),
});

const getAsyncData = () => async (dispatch) => {
  const token = getToken();
  dispatch(flowGetAsyncDataProgress());

  ws.emit('api/academy/flows/getAsyncData', { token }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = flowGetAsyncDataSuccess(transformAsyncData(payload));
    } else {
      action = flowGetAsyncDataFailed();
    }

    dispatch(action);
  });
};

const flowClearFlashMessage = () => ({
  type: FLOWS_CLEAR_FLASH_MESSAGE,
});

export {
  FLOWS_PROGRESS,
  FLOWS_SUCCESS,
  FLOWS_FAILED,
  FLOWS_GET_LIST_PROGRESS,
  FLOWS_GET_LIST_SUCCESS,
  FLOWS_GET_LIST_FAILED,
  FLOWS_FORM_SUBMIT_PROGRESS,
  FLOWS_FORM_SUBMIT_SUCCESS,
  FLOWS_FORM_SUBMIT_FAILED,
  FLOWS_GET_COURSE_PROGRESS,
  FLOWS_GET_COURSE_SUCCESS,
  FLOWS_GET_COURSE_FAILED,
  FLOWS_GET_ASYNC_DATA_PROGRESS,
  FLOWS_GET_ASYNC_DATA_SUCCESS,
  FLOWS_GET_ASYNC_DATA_FAILED,
  FLOWS_CLEAR_STATE,
  FLOWS_CLEAR_FLASH_MESSAGE,
  flowGetListProgress,
  flowGetListSuccess,
  flowGetListFailed,
  flowStartFormSubmit,
  flowFormSubmitSuccess,
  flowFormSubmitFailed,
  flowGetFlowProgress,
  flowGetFlowSuccess,
  flowGetFlowFailed,
  flowGetAsyncDataProgress,
  flowGetAsyncDataSuccess,
  flowGetAsyncDataFailed,
  flowClearState,
  getFlowsList,
  createFlow,
  getFlow,
  updateFlow,
  getAsyncData,
  flowClearFlashMessage,
};
