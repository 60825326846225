const validate = (values) => {
  const errors = {};
  const requiredErrorMessage = 'Это поле является обязательным';

  if (!values.email) {
    errors.email = requiredErrorMessage;
    // eslint-disable-next-line
  } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)) {
    errors.email = 'Введен некорректный E-mail адрес';
  }

  if (!values.password) {
    errors.password = requiredErrorMessage;
  }

  return errors;
};

export default validate;
