import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';

import ShowcaseTablePage from '../../../Showcases/ShowcaseTable/index';
import ShowcaseCreatePage from '../../../Showcases/ShowcaseCreate/index';
import ShowcaseEditPage from '../../../Showcases/ShowcaseEdit/index';

export default () => (
  <Switch>
    <ProtectedRoute path="/showcases" exact component={ShowcaseTablePage} />
    <ProtectedRoute path="/showcases/create" component={ShowcaseCreatePage} />
    <ProtectedRoute path="/showcases/:showcaseId" exact component={ShowcaseEditPage} />
  </Switch>
);
