import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import {
  Field, FieldArray, reduxForm, formValueSelector,
} from 'redux-form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  MATERIALS_SUCCESS, MATERIALS_FAILED,
  createMaterial as createCourseAction, materialClearState as materialClearStateAction,
  previewMaterial as previewMaterialAction,
} from '../../../../redux/actions/materialsAction';
import ButtonToNavigate from '../../../../shared/components/form/ButtonToNavigate';
import renderEditor from '../../../../shared/components/tinymce/TinyMCE';
import renderField from '../../../../shared/components/form/TextField';
import renderSelectField from '../../../../shared/components/form/Select';
import { FullWideNotification } from '../../../../shared/components/Notification';
import RenderVideos from '../../components/RenderVideos';
import RenderAudio from '../../components/RenderAudio';
import RenderCustomAudio from '../../components/RenderCustomAudio';
import store from '../../../App/store';
import validate from '../../validate';

const marginTop15 = {
  marginTop: '15px',
};

class CreateForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    submitStatus: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    materialClearState: PropTypes.func.isRequired,
    previewMaterial: PropTypes.func.isRequired,
    previewId: PropTypes.string.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    const { submitStatus, history } = nextProps;

    if (submitStatus === MATERIALS_SUCCESS) {
      history.push('/materials');
    } else if (submitStatus === MATERIALS_FAILED) {
      this.show('danger', 'Произошла непредвиденная ошибка. Обратитесь к разработчикам.');
    }
  }

  componentWillUnmount() {
    const { materialClearState } = this.props;
    materialClearState();
  }

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message={message}
      />,
      position: 'full',
    });
  };

  preview = async () => {
    const { previewMaterial, previewId } = this.props;
    const selector = formValueSelector('materials_create_form');
    const form = selector(store.getState(), 'header', 'img', 'typeName', 'duration', 'videos', 'audio', 'view');
    const newPreviewId = await previewMaterial(previewId, form);

    window.open(`https://karpachoff.academy/preview-material/${newPreviewId}`, '_blank');
  };

  render() {
    const {
      handleSubmit, submitting, t,
    } = this.props;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">{t('maretials.forms.create_form_title')}</h5>
              <h5 className="subhead">{t('maretials.forms.create_form_description')}</h5>
            </div>
            <form className="form" onSubmit={handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">Название материала</span>
                <div className="form__form-group-field">
                  <Field
                    name="header"
                    component={renderField}
                    type="text"
                    placeholder="Название материала"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Иконка материала</span>
                <div className="form__form-group-field">
                  <Field
                    name="img"
                    component={renderSelectField}
                    type="text"
                    placeholder="Выберите иконку для материала"
                    options={[
                      { value: 'aditional_materials', label: 'Допматериалы' },
                      { value: 'article', label: 'Статьи' },
                      { value: 'check_list', label: 'Чек-листы' },
                      { value: 'qa', label: 'Ответы на вопросы' },
                      { value: 'schema', label: 'Схемы' },
                      { value: 'task', label: 'Задания' },
                      { value: 'video', label: 'Видео' },
                    ]}
                  />
                </div>
              </div>
              <div className="form__form-group" style={marginTop15}>
                <span className="form__form-group-label">Тип материала</span>
                <div className="form__form-group-field">
                  <Field
                    name="typeName"
                    component={renderField}
                    type="text"
                    placeholder="Тип материала"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Длительность</span>
                <div className="form__form-group-field">
                  <Field
                    name="duration"
                    component={renderField}
                    type="text"
                    placeholder="Длительность"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Список видео материалов</span>
                <div className="form__form-group-field">
                  <FieldArray
                    name="videos"
                    component={RenderVideos}
                    props={{ reduxFormName: CreateForm.FORM_NAME, fieldName: 'videos' }}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Список аудио материалов</span>
                <div className="form__form-group-field">
                  <FieldArray name="audio" component={RenderAudio} />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Список аудио файлов</span>
                <div className="form__form-group-field">
                  <FieldArray
                    name="customAudio"
                    component={RenderCustomAudio}
                    props={{ reduxFormName: CreateForm.FORM_NAME, fieldName: 'customAudio' }}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Описание</span>
                <div className="form__form-group-field">
                  <Field
                    name="view"
                    component={renderEditor}
                  />
                </div>
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit" disabled={submitting}>Создать</Button>
                <Button color="success" type="button" onClick={this.preview}>Предварительный просмотр</Button>
                <Route
                  path="/"
                  render={props => <ButtonToNavigate {...props} title="Отмена" path="/materials" />}
                />
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

CreateForm.FORM_NAME = 'materials_create_form';

const mapStateToProps = state => ({
  submitStatus: state.materials.submitStatus,
  previewId: state.materials.previewId,
});

const mapDispatchToProps = dispatch => ({
  materialClearState: () => dispatch(materialClearStateAction()),
  previewMaterial: (previewId, data) => dispatch(previewMaterialAction(previewId, data)),
});

const CreateFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateForm);

export default reduxForm({
  form: CreateForm.FORM_NAME, // a unique identifier for this form
  validate,
  onSubmit: createCourseAction,
})(withTranslation('common')(withRouter(CreateFormComponent)));
