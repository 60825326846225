const validate = (values) => {
  const errors = {};
  const requiredErrorMessage = 'Это поле является обязательным';

  if (!values.name) {
    errors.name = requiredErrorMessage;
  } else if (values.name.length < 3) {
    errors.name = 'Введите не менее 3-х символов';
  }

  if (!values.url) {
    errors.url = requiredErrorMessage;
  } else if (!/^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(values.url)) {
    errors.url = 'Введенный вами URL не соответствует стандарту';
  }

  return errors;
};

export default validate;
