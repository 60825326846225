import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import renderCheckBoxField from './CheckBox';

const relative = {
  position: 'relative',
  display: 'flex',
  top: '30px',
  width: '100%',
};

class SelectField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    preText: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ]).isRequired,
    changeAction: PropTypes.func,
  };

  static defaultProps = {
    placeholder: '',
    preText: '',
    options: [],
    changeAction: null,
  };

  static getDefaultValue(value, options) {
    let defaultValue;

    if (typeof value === 'string' && value.length) {
      const defaultOption = Array.from(options.filter(option => option.value === value));
      const { label } = defaultOption[0];
      defaultValue = { value, label };
    } else {
      defaultValue = value;
    }

    return defaultValue;
  }

  constructor() {
    super();
    this.state = {
      defaultValue: null,
      checkboxes: [],
    };
  }

  componentDidMount() {
    /* eslint react/prop-types: 0 */
    const { value, options } = this.props;
    const { getDefaultValue } = SelectField;
    const { checkboxes } = value;

    this.setState({
      defaultValue: getDefaultValue(value, options),
      checkboxes: checkboxes || [],
    });
  }

  componentWillReceiveProps(nextProps) {
    const { value, options } = nextProps;
    const { getDefaultValue } = SelectField;
    const { checkboxes } = value;

    this.setState({
      defaultValue: getDefaultValue(value, options),
      checkboxes: checkboxes || [],
    });
  }

  handleChange = (selectedOption) => {
    const { onChange, changeAction } = this.props;
    onChange(selectedOption);

    if (changeAction) {
      changeAction(selectedOption);
    }

    this.setState({ checkboxes: selectedOption.checkboxes });
  };

  render() {
    const {
      name, placeholder, preText, options,
    } = this.props;
    const { defaultValue, checkboxes } = this.state;

    return (
      <div>
        <Select
          name={name}
          value={defaultValue}
          onChange={this.handleChange}
          options={options}
          clearable={false}
          className="react-select"
          placeholder={placeholder}
          classNamePrefix="react-select"
          defaultValue={defaultValue}
        />
        <div style={relative}>
          {checkboxes.map(checkbox => (
            <span key={checkbox.id}>
              <Field
                name={`[${name}]selected[${checkbox.id}]`}
                component={renderCheckBoxField}
                label={`${preText}${checkbox.name}`}
              />
            </span>
          ))}
        </div>
      </div>
    );
  }
}

const renderSelectField = (props) => {
  const {
    input, meta, options, placeholder, preText, changeAction,
  } = props;
  return (
    <div className="form__form-group-input-wrap">
      <SelectField
        {...input}
        options={options}
        placeholder={placeholder}
        preText={preText}
        changeAction={changeAction}
      />
      {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
    </div>
  );
};

renderSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  placeholder: PropTypes.string,
  preText: PropTypes.string,
  changeAction: PropTypes.func,
};

renderSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: '',
  preText: '',
  changeAction: null,
};

export default renderSelectField;
