import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';

import ThemeTablePage from '../../../Themes/ThemeTable/index';
import ThemeCreatePage from '../../../Themes/ThemeCreate/index';
import ThemeEditPage from '../../../Themes/ThemeEdit/index';

export default () => (
  <Switch>
    <ProtectedRoute path="/themes" exact component={ThemeTablePage} />
    <ProtectedRoute path="/themes/create" component={ThemeCreatePage} />
    <ProtectedRoute path="/themes/:themeId" exact component={ThemeEditPage} />
  </Switch>
);
