import update from 'immutability-helper';
import {
  FAQS_PROGRESS,
  FAQS_SUCCESS,
  FAQS_FAILED,
  FAQS_GET_LIST_PROGRESS,
  FAQS_GET_LIST_SUCCESS,
  FAQS_GET_LIST_FAILED,
  FAQS_FORM_SUBMIT_PROGRESS,
  FAQS_FORM_SUBMIT_SUCCESS,
  FAQS_FORM_SUBMIT_FAILED,
  FAQS_GET_PAGE_PROGRESS,
  FAQS_GET_PAGE_SUCCESS,
  FAQS_GET_PAGE_FAILED,
  FAQS_CLEAR_STATE,
  FAQS_CLEAR_FLASH_MESSAGE,
} from '../actions/faqsAction';

const initialState = {
  loadListStatus: '', // progress/success/failed
  loadActiveStatus: '', // progress/success/failed
  submitStatus: '', // progress/success/failed
  asyncStatus: '', // progress/success/failed
  list: [],
  pagination: {},
  active: {},
  asyncData: {},
  flash: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FAQS_GET_LIST_PROGRESS: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: FAQS_PROGRESS },
        },
      );
    }
    case FAQS_GET_LIST_SUCCESS: {
      return update(
        state, {
          list: { $set: action.payload.list },
          pagination: { $set: action.payload.pagination },
          loadListStatus: { $set: FAQS_SUCCESS },
        },
      );
    }
    case FAQS_GET_LIST_FAILED: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: FAQS_FAILED },
        },
      );
    }
    case FAQS_FORM_SUBMIT_PROGRESS: {
      return update(
        state,
        { submitStatus: { $set: FAQS_PROGRESS } },
      );
    }
    case FAQS_FORM_SUBMIT_SUCCESS: {
      return update(
        state,
        {
          flash: { $set: action.payload },
          submitStatus: { $set: FAQS_SUCCESS },
        },
      );
    }
    case FAQS_FORM_SUBMIT_FAILED: {
      return update(
        state,
        { submitStatus: { $set: FAQS_FAILED } },
      );
    }
    case FAQS_GET_PAGE_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: FAQS_PROGRESS },
        },
      );
    }
    case FAQS_GET_PAGE_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.faq },
          loadActiveStatus: { $set: FAQS_SUCCESS },
        },
      );
    }
    case FAQS_GET_PAGE_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: FAQS_FAILED },
        },
      );
    }
    case FAQS_CLEAR_STATE: {
      return update(
        state,
        {
          loadListStatus: { $set: '' },
          loadActiveStatus: { $set: '' },
          submitStatus: { $set: '' },
          asyncStatus: { $set: '' },
          list: { $set: [] },
          pagination: { $set: {} },
          active: { $set: {} },
          asyncData: { $set: {} },
        },
      );
    }
    case FAQS_CLEAR_FLASH_MESSAGE: {
      return update(
        state, {
          flash: { $set: '' },
        },
      );
    }
    default:
      return state;
  }
}
