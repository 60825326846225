import React, { PureComponent, Fragment } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Card, CardBody, Col, Button, ButtonToolbar, Row,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import {
  QUESTIONS_SUCCESS, QUESTIONS_FAILED,
  getStatistics as getStatisticsAction,
  getStatisticsAsyncData as getStatisticsAsyncDataAction,
  questionClearState as questionClearStateAction,
} from '../../../../redux/actions/questionsAction';
import ButtonToNavigate from '../../../../shared/components/form/ButtonToNavigate';
import { FullWideNotification } from '../../../../shared/components/Notification';
import validate from '../validate';

const font25 = {
  fontSize: '25px',
};

class StatisticsForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    active: PropTypes.objectOf(PropTypes.any).isRequired,
    asyncData: PropTypes.objectOf(PropTypes.any).isRequired,
    submitStatus: PropTypes.string.isRequired,
    asyncStatus: PropTypes.string.isRequired,
    loadStatus: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    getStatisticsAsyncData: PropTypes.func.isRequired,
    questionClearState: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { getStatisticsAsyncData } = this.props;
    getStatisticsAsyncData();
  }

  componentWillReceiveProps(nextProps) {
    const { submitStatus, loadStatus, history } = nextProps;

    if (loadStatus === QUESTIONS_FAILED) {
      history.push('/404');
    }

    if (submitStatus === QUESTIONS_FAILED) {
      this.show('danger', 'Произошла непредвиденная ошибка. Обратитесь к разработчикам.');
    }
  }

  componentWillUnmount() {
    const { questionClearState } = this.props;
    questionClearState();
  }

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message={message}
      />,
      position: 'full',
    });
  };

  getCuratorName = (id) => {
    const { asyncData } = this.props;
    const { curators } = asyncData;

    return curators.find(curator => curator.id === id).name;
  };

  render() {
    const {
      handleSubmit, submitting, asyncStatus, asyncData, loadStatus, active,
    } = this.props;
    const { curators } = asyncData;
    const {
      begin, end, allQuestions, allUserComments, statistics,
    } = active;

    return (
      <Fragment>
        <Row>
          {asyncStatus === QUESTIONS_SUCCESS ? (
            <form className="form w-100" onSubmit={handleSubmit}>
              <Col md={12} lg={6} xl={6}>
                <Card>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">Выберите даты</h5>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Начальная дата</span>
                      <div className="form__form-group-field">
                        <Field
                          name="begin"
                          component={renderDatePickerField}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Конечная дата</span>
                      <div className="form__form-group-field">
                        <Field
                          name="end"
                          component={renderDatePickerField}
                        />
                      </div>
                    </div>
                    <ButtonToolbar className="form__button-toolbar">
                      <Button color="primary" type="submit" disabled={submitting}>Показать статистику</Button>
                      <Route
                        path="/"
                        render={props => <ButtonToNavigate {...props} title="Отмена" path="/questions" />}
                      />
                    </ButtonToolbar>
                  </CardBody>
                </Card>
              </Col>
              <Col md={12} lg={6} xl={6}>
                <Card>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">Выберите кураторов</h5>
                    </div>
                    <div className="form__form-group">
                      {curators.map(curator => (
                        <div key={curator.id} className="form__form-group-field">
                          <Field
                            name={`curators[${curator.id}]`}
                            component={renderCheckBoxField}
                            label={curator.name}
                          />
                        </div>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </form>
          ) : null}
        </Row>
        {loadStatus === QUESTIONS_SUCCESS ? (
          <Fragment>
            <Row>
              <Col md={12} xl={3} lg={6} xs={12}>
                <Card>
                  <CardBody className="dashboard__booking-card">
                    <div className="dashboard__booking-total-container">
                      <h5 className="dashboard__booking-total-title dashboard__booking-total-title--blue">
                        {`${begin} - ${end}`}
                      </h5>
                    </div>
                    <h5 className="dashboard__booking-total-description">Промежуток дат</h5>
                  </CardBody>
                </Card>
              </Col>
              <Col md={12} xl={3} lg={6} xs={12}>
                <Card>
                  <CardBody className="dashboard__booking-card">
                    <div className="dashboard__booking-total-container">
                      <h5 className="dashboard__booking-total-title">{allQuestions}</h5>
                    </div>
                    <h5 className="dashboard__booking-total-description">Всего вопросов</h5>
                  </CardBody>
                </Card>
              </Col>
              <Col md={12} xl={3} lg={6} xs={12}>
                <Card>
                  <CardBody className="dashboard__booking-card">
                    <div className="dashboard__booking-total-container">
                      <h5 className="dashboard__booking-total-title dashboard__booking-total-title--green">
                        {allUserComments}
                      </h5>
                    </div>
                    <h5 className="dashboard__booking-total-description">Всего комментариев</h5>
                  </CardBody>
                </Card>
              </Col>
              <Col md={12} xl={3} lg={6} xs={12}>
                <Card>
                  <CardBody className="dashboard__booking-card">
                    <div className="dashboard__booking-total-container">
                      <h5 className="dashboard__booking-total-title dashboard__booking-total-title--red">
                        {allQuestions + allUserComments}
                      </h5>
                    </div>
                    <h5 className="dashboard__booking-total-description">Общее число</h5>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {statistics.map((data, index) => (
              <Row key={`${data.curator}${index}`}>
                <Col md={12} xl={3} lg={6} xs={12}>
                  <Card>
                    <CardBody className="dashboard__booking-card">
                      <div className="dashboard__booking-total-container">
                        <h5
                          className="dashboard__booking-total-title dashboard__booking-total-title--blue"
                          style={font25}
                        >
                          {this.getCuratorName(data.curator)}
                        </h5>
                      </div>
                      <h5 className="dashboard__booking-total-description">Куратор</h5>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={12} xl={3} lg={6} xs={12}>
                  <Card>
                    <CardBody className="dashboard__booking-card">
                      <div className="dashboard__booking-total-container">
                        <h5 className="dashboard__booking-total-title">{data.answers}</h5>
                      </div>
                      <h5 className="dashboard__booking-total-description">Всего ответов</h5>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={12} xl={3} lg={6} xs={12}>
                  <Card>
                    <CardBody className="dashboard__booking-card">
                      <div className="dashboard__booking-total-container">
                        <h5 className="dashboard__booking-total-title dashboard__booking-total-title--green">
                          {data.comments}
                        </h5>
                      </div>
                      <h5 className="dashboard__booking-total-description">Всего комментариев</h5>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={12} xl={3} lg={6} xs={12}>
                  <Card>
                    <CardBody className="dashboard__booking-card">
                      <div className="dashboard__booking-total-container">
                        <h5 className="dashboard__booking-total-title dashboard__booking-total-title--red">
                          {data.answers + data.comments}
                        </h5>
                      </div>
                      <h5 className="dashboard__booking-total-description">Общее число</h5>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ))}
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  active: state.questions.active,
  asyncData: state.questions.asyncData,
  submitStatus: state.questions.submitStatus,
  asyncStatus: state.questions.asyncStatus,
  loadStatus: state.questions.loadActiveStatus,
});

const mapDispatchToProps = dispatch => ({
  getStatisticsAsyncData: () => dispatch(getStatisticsAsyncDataAction()),
  questionClearState: () => dispatch(questionClearStateAction()),
});

const EditFormComponent = reduxForm({
  form: 'questions_statistics_form', // a unique identifier for this form
  validate,
  enableReinitialize: true,
  onSubmit: getStatisticsAction,
})(withTranslation('common')(withRouter(StatisticsForm)));

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditFormComponent);
