import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  FAQS_SUCCESS, FAQS_FAILED,
  createFaq as createFaqAction, faqClearState as faqClearStateAction,
} from '../../../../redux/actions/faqsAction';
import ButtonToNavigate from '../../../../shared/components/form/ButtonToNavigate';
import renderEditor from '../../../../shared/components/tinymce/TinyMCE';
import renderField from '../../../../shared/components/form/TextField';
import { FullWideNotification } from '../../../../shared/components/Notification';
import validate from '../../validate';

class CreateForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    submitStatus: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    faqClearState: PropTypes.func.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    const { submitStatus, history } = nextProps;

    if (submitStatus === FAQS_SUCCESS) {
      history.push('/faqs');
    } else if (submitStatus === FAQS_FAILED) {
      this.show('danger', 'Произошла непредвиденная ошибка. Обратитесь к разработчикам.');
    }
  }

  componentWillUnmount() {
    const { faqClearState } = this.props;
    faqClearState();
  }

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification color={color} message={message} />,
      position: 'full',
    });
  };

  render() {
    const {
      handleSubmit, submitting, t,
    } = this.props;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">{t('faqs.forms.create_form_title')}</h5>
              <h5 className="subhead">{t('faqs.forms.create_form_description')}</h5>
            </div>
            <form className="form" onSubmit={handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">Вопрос (на русском)</span>
                <div className="form__form-group-field">
                  <Field
                    name="question"
                    component={renderField}
                    type="text"
                    placeholder="Частый вопрос"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Ответ (на русском)</span>
                <div className="form__form-group-field">
                  <Field
                    name="answer"
                    component={renderEditor}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Вопрос (на украинском)</span>
                <div className="form__form-group-field">
                  <Field
                    name="question_ua"
                    component={renderField}
                    type="text"
                    placeholder="Частый вопрос"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Ответ (на украинском)</span>
                <div className="form__form-group-field">
                  <Field
                    name="answer_ua"
                    component={renderEditor}
                  />
                </div>
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit" disabled={submitting}>Создать</Button>
                <Route
                  path="/"
                  render={props => <ButtonToNavigate {...props} title="Отмена" path="/faqs" />}
                />
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  submitStatus: state.faqs.submitStatus,
});

const mapDispatchToProps = dispatch => ({
  faqClearState: () => dispatch(faqClearStateAction()),
});

const CreateFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateForm);

export default reduxForm({
  form: 'faqs_create_form', // a unique identifier for this form
  validate,
  onSubmit: createFaqAction,
})(withTranslation('common')(withRouter(CreateFormComponent)));
