import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderDropZoneField from '../../../../shared/components/form/DropZone';

class WizardFormThree extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit, previousPage, showNotification } = this.props;

    return (
      <form className="form form--horizontal wizard__form wizard__form__lg" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Загрузите изображения для марафона</h3>
        <div className="form__form-group">
          <span className="form__form-group-label">Фоновое изображение</span>
          <div className="form__form-group-field">
            <Field
              name="background"
              component={renderDropZoneField}
              showNotification={showNotification}
              mimes={['image/jpeg', 'image/png']}
              maxFilesize={2097152} /* 2Mb */
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Основное изображение</span>
          <div className="form__form-group-field">
            <Field
              name="layer"
              component={renderDropZoneField}
              showNotification={showNotification}
              mimes={['image/jpeg', 'image/png']}
              maxFilesize={2097152} /* 2Mb */
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Превью</span>
          <div className="form__form-group-field">
            <Field
              name="preview"
              component={renderDropZoneField}
              showNotification={showNotification}
              mimes={['image/jpeg', 'image/png']}
              maxFilesize={2097152} /* 2Mb */
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={previousPage}>Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next">Следующий шаг</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'maraphons_edit_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: false,
})(WizardFormThree);
