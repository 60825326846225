const validate = (values) => {
  // eslint-disable-next-line
  const validUrl = /(http(s)?:\/\/.)(www\.)?[-a-za-я0-9@:%._\+~#=]{2,256}\.[a-zа-я]{2,6}\b([-a-zа-я0-9@:%_\+.~#?&//=]*)/i;
  const errors = {};
  const requiredErrorMessage = 'Это поле является обязательным';

  if (!values.name) {
    errors.name = requiredErrorMessage;
  } else if (values.name.length < 3) {
    errors.name = 'Введите не менее 3-х символов';
  }

  if (!values.slug) {
    errors.slug = requiredErrorMessage;
  } else if (!/^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(values.slug)) {
    errors.slug = 'Введенный вами URL не соответствует стандарту';
  }

  if (!values.author) {
    errors.author = requiredErrorMessage;
  }

  if (!values.description) {
    errors.description = requiredErrorMessage;
  }

  if (values.landing && !values.landing.match(validUrl)) {
    errors.landing = 'Вы ввели неверный URL адрес';
  }

  if (!values.direction) {
    errors.direction = requiredErrorMessage;
  }

  if (!values.tasks || !values.tasks.length) {
    errors.tasks = { _error: 'Марафон должен содержать хотя бы одну тему' };
  } else {
    const tasksArrayErrors = [];

    values.tasks.forEach((task, taskIndex) => {
      const taskErrors = {};

      if (typeof task.task === 'undefined') {
        taskErrors.task = requiredErrorMessage;
        tasksArrayErrors[taskIndex] = taskErrors;
      }
    });

    if (tasksArrayErrors.length) {
      errors.tasks = tasksArrayErrors;
    }
  }

  return errors;
};

export default validate;
