import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const ButtonToNavigate = ({ history, title, path }) => (
  <Button
    type="button"
    onClick={() => history.push(path)}
  >
    {title}
  </Button>
);

ButtonToNavigate.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default withRouter(ButtonToNavigate);
