import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';

export default class ModalComponent extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    btn: PropTypes.string.isRequired,
    btnDisabled: PropTypes.bool,
    btnCancel: PropTypes.string,
    btnSuccess: PropTypes.string,
    btnType: PropTypes.string,
    success: PropTypes.func.isRequired,
  };

  static defaultProps = {
    title: '',
    message: '',
    colored: false,
    header: false,
    btnDisabled: false,
    btnCancel: 'Отмена',
    btnSuccess: 'Ok',
    btnType: 'button',
  };

  constructor() {
    super();
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  toggle() {
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  handleSuccess() {
    const { success } = this.props;
    success();
    this.toggle();
  }

  render() {
    const {
      color, btn, btnDisabled, btnCancel, btnSuccess, btnType, title, message, colored, header,
    } = this.props;
    const { modal } = this.state;
    let Icon;

    switch (color) {
      case 'primary':
        Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
        break;
      case 'success':
        Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
        break;
      case 'warning':
        Icon = <span className="lnr lnr-flag modal__title-icon" />;
        break;
      case 'danger':
        Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div>
        <Button color={color} onClick={this.toggle} disabled={btnDisabled}>{btn}</Button>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          className={`modal-dialog--${color} ${modalClass}`}
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            {message}
          </div>
          <ButtonToolbar className="modal__footer">
            <Button onClick={this.toggle}>{btnCancel}</Button>{' '}
            <Button outline={colored} color={color} onClick={this.handleSuccess} type={btnType}>{btnSuccess}</Button>
          </ButtonToolbar>
        </Modal>
      </div>
    );
  }
}
