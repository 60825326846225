import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

class SelectField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ]).isRequired,
    changeAction: PropTypes.func,
  };

  static defaultProps = {
    placeholder: '',
    options: [],
    changeAction: null,
  };

  static getDefaultValue(value, options) {
    let defaultValue;

    if (typeof value === 'string' && value.length) {
      const defaultOption = Array.from(options.filter(option => option.value === value));

      if (Array.isArray(defaultOption) && defaultOption.length) {
        const { label } = defaultOption[0];
        defaultValue = { value, label };
      } else {
        defaultValue = value;
      }
    } else {
      defaultValue = value;
    }

    return defaultValue;
  }

  constructor() {
    super();
    this.state = {
      defaultValue: null,
    };
  }

  componentDidMount() {
    /* eslint react/prop-types: 0 */
    const { value, options } = this.props;
    const { getDefaultValue } = SelectField;

    this.setState({ defaultValue: getDefaultValue(value, options) });
  }

  componentWillReceiveProps(nextProps) {
    const { value, options } = nextProps;
    const { getDefaultValue } = SelectField;

    this.setState({ defaultValue: getDefaultValue(value, options) });
  }

  handleChange = (selectedOption) => {
    const { onChange, changeAction } = this.props;
    onChange(selectedOption);

    if (changeAction) {
      changeAction(selectedOption);
    }
  };

  render() {
    const {
      name, placeholder, options,
    } = this.props;
    const { defaultValue } = this.state;

    return (
      <Select
        name={name}
        value={defaultValue}
        onChange={this.handleChange}
        options={options}
        clearable={false}
        className="react-select"
        placeholder={placeholder}
        classNamePrefix="react-select"
        defaultValue={defaultValue}
      />
    );
  }
}

const renderSelectField = (props) => {
  const {
    input, meta, options, placeholder, changeAction,
  } = props;
  return (
    <div className="form__form-group-input-wrap">
      <SelectField
        {...input}
        options={options}
        placeholder={placeholder}
        changeAction={changeAction}
      />
      {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
    </div>
  );
};

renderSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  placeholder: PropTypes.string,
  changeAction: PropTypes.func,
};

renderSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: '',
  changeAction: null,
};

export default renderSelectField;
