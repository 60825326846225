import update from 'immutability-helper';
import {
  TAGS_PROGRESS,
  TAGS_SUCCESS,
  TAGS_FAILED,
  TAGS_GET_LIST_PROGRESS,
  TAGS_GET_LIST_SUCCESS,
  TAGS_GET_LIST_FAILED,
  TAGS_FORM_SUBMIT_PROGRESS,
  TAGS_FORM_SUBMIT_SUCCESS,
  TAGS_FORM_SUBMIT_FAILED,
  TAGS_GET_TAG_PROGRESS,
  TAGS_GET_TAG_SUCCESS,
  TAGS_GET_TAG_FAILED,
  TAGS_CLEAR_STATE,
  TAGS_CLEAR_FLASH_MESSAGE,
} from '../actions/tagsAction';

const initialState = {
  loadListStatus: '', // progress/success/failed
  loadActiveStatus: '', // progress/success/failed
  submitStatus: '', // progress/success/failed
  asyncStatus: '', // progress/success/failed
  list: [],
  pagination: {},
  active: {},
  asyncData: {},
  flash: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TAGS_GET_LIST_PROGRESS: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: TAGS_PROGRESS },
        },
      );
    }
    case TAGS_GET_LIST_SUCCESS: {
      return update(
        state, {
          list: { $set: action.payload.list },
          pagination: { $set: action.payload.pagination },
          loadListStatus: { $set: TAGS_SUCCESS },
        },
      );
    }
    case TAGS_GET_LIST_FAILED: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: TAGS_FAILED },
        },
      );
    }
    case TAGS_FORM_SUBMIT_PROGRESS: {
      return update(
        state,
        { submitStatus: { $set: TAGS_PROGRESS } },
      );
    }
    case TAGS_FORM_SUBMIT_SUCCESS: {
      return update(
        state,
        {
          flash: { $set: action.payload },
          submitStatus: { $set: TAGS_SUCCESS },
        },
      );
    }
    case TAGS_FORM_SUBMIT_FAILED: {
      return update(
        state,
        { submitStatus: { $set: TAGS_FAILED } },
      );
    }
    case TAGS_GET_TAG_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: TAGS_PROGRESS },
        },
      );
    }
    case TAGS_GET_TAG_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.tag },
          loadActiveStatus: { $set: TAGS_SUCCESS },
        },
      );
    }
    case TAGS_GET_TAG_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: TAGS_FAILED },
        },
      );
    }
    case TAGS_CLEAR_STATE: {
      return update(
        state,
        {
          loadListStatus: { $set: '' },
          loadActiveStatus: { $set: '' },
          submitStatus: { $set: '' },
          asyncStatus: { $set: '' },
          list: { $set: [] },
          pagination: { $set: {} },
          active: { $set: {} },
          asyncData: { $set: {} },
        },
      );
    }
    case TAGS_CLEAR_FLASH_MESSAGE: {
      return update(
        state, {
          flash: { $set: '' },
        },
      );
    }
    default:
      return state;
  }
}
