import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';

import MaterialTablePage from '../../../Materials/MaterialTable';
import MaterialCreatePage from '../../../Materials/MaterialCreate/index';
import MaterialEditPage from '../../../Materials/MaterialEdit/index';

export default () => (
  <Switch>
    <ProtectedRoute path="/materials" exact component={MaterialTablePage} />
    <ProtectedRoute path="/materials/create" component={MaterialCreatePage} />
    <ProtectedRoute path="/materials/:materialId" exact component={MaterialEditPage} />
  </Switch>
);
