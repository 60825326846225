const validate = (values) => {
  const errors = {};
  const requiredErrorMessage = 'Это поле является обязательным';

  if (!values.name) {
    errors.name = requiredErrorMessage;
  } else if (values.name.length < 3) {
    errors.name = 'Введите не менее 3-х символов';
  }

  if (!values.amoId) {
    errors.amoId = requiredErrorMessage;
  }

  if (!values.flows || !values.flows.length) {
    errors.flows = { _error: 'Хаб должен содержать хотя бы один курс' };
  } else {
    const flowArrayErrors = [];

    values.flows.forEach((flow, flowIndex) => {
      const flowErrors = {};

      if (typeof flow.flow === 'undefined') {
        flowErrors.flow = requiredErrorMessage;
        flowArrayErrors[flowIndex] = flowErrors;
      }
    });

    if (flowArrayErrors.length) {
      errors.flows = flowArrayErrors;
    }
  }

  return errors;
};

export default validate;
