import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonToolbar, Col, Row,
} from 'reactstrap';
import { Field } from 'redux-form';
import ArrowUpBold from 'mdi-react/ArrowUpBoldIcon';
import ArrowDownBoldIcon from 'mdi-react/ArrowDownBoldIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import renderField from '../../../shared/components/form/TextField';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import PopupVideoPlayer from '../../../shared/components/PopupVideoPlayer';

class RenderVideos extends PureComponent {
  static propTypes = {
    fields: PropTypes.objectOf(PropTypes.any).isRequired,
    meta: PropTypes.objectOf(PropTypes.any).isRequired,
    reduxFormName: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
  };

  static moveToUp(fields, index) {
    if (index > 0) {
      fields.swap(index, index - 1);
    }
  }

  static moveToDown(fields, index) {
    if (fields.length - 1 !== index) {
      fields.swap(index, index + 1);
    }
  }

  render() {
    const {
      fields, reduxFormName, fieldName, meta: { error, submitFailed },
    } = this.props;
    const { moveToUp, moveToDown } = RenderVideos;

    return (
      <div className="form__form-group">
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" size="sm" onClick={() => fields.push({})}>Добавить видео</Button>
        </ButtonToolbar>
        {submitFailed && error
        && (
          <div className="form__form-group">
            <div>
              <span className="form__form-group-error">{error}</span>
            </div>
          </div>
        )}
        {fields.map((video, index) => (
          <div key={index} className="form__form-group">
            <Row>
              <Col md={7}>
                <span className="form__form-group-label">Видео # {index + 1} (шорт-код %video{index + 1}%)</span>
                <Field
                  name={`${video}.title`}
                  component={renderField}
                  type="hidden"
                />
                <Field
                  name={`${video}.isLong`}
                  component={renderField}
                  type="hidden"
                />
                <Field
                  name={`${video}.wistiaId`}
                  component={renderField}
                  type="hidden"
                />
                <div className="form__form-group-field">
                  <Field
                    name={`${video}.source`}
                    component={renderField}
                    type="text"
                    placeholder="Вставьте iframe с видео сюда"
                  />
                </div>
              </Col>
              <Col md={3}>
                <span className="form__form-group-label">&nbsp;</span>
                <div className="form__form-group-field">
                  <Field
                    name={`${video}.isFree`}
                    component={renderCheckBoxField}
                    label="Предпросмотр на витрине"
                  />
                </div>
              </Col>
              <Col md={2}>
                <span className="form__form-group-label">&nbsp;</span>
                <div className="form__form-group-field">
                  <PopupVideoPlayer
                    title="Предварительный просмотр"
                    color="primary"
                    fieldIndex={index}
                    fieldName={fieldName}
                    fieldSubName="source"
                    reduxFormName={reduxFormName}
                    header
                  />
                  <div className="form__form-group-icon">
                    <ArrowUpBold onClick={() => moveToUp(fields, index)} />
                  </div>
                  <div className="form__form-group-icon">
                    <ArrowDownBoldIcon onClick={() => moveToDown(fields, index)} />
                  </div>
                  <div className="form__form-group-icon">
                    <TrashCanOutlineIcon onClick={() => fields.remove(index)} />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    );
  }
}

export default RenderVideos;
