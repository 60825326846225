import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Col, Row, Badge } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import {
  SHOWCASES_SUCCESS,
  getShowcasesList as getShowcasesListAction,
  showcaseClearState as showcaseClearStateAction,
  showcaseClearFlashMessage as showcaseClearFlashMessageAction,
} from '../../../../redux/actions/showcasesAction';
import DataTable from '../../../../shared/components/data-table';
import { COMPLETED, WAIT } from '../../../../shared/components/data-table/submitStatuses';
import { DATA, ACTION } from '../../../../shared/components/data-table/columnTypes';
import { LINK } from '../../../../shared/components/data-table/buttonTypes';
import { FullWideNotification } from '../../../../shared/components/Notification';

const ShowcaseTable = ({
  getShowcasesList,
  showcaseClearState,
  showcaseClearFlashMessage,
  showNotification,
  showcasesList,
  pagination,
  loadStatus,
  flashMessage,
}) => {
  const [data, setData] = useState({
    columns: [],
    buttons: [],
    isFind: false,
    status: WAIT,
  });

  const getConditions = () => ({});

  const show = (color, message) => showNotification({
    notification: <FullWideNotification
      color={color}
      message={message}
    />,
    position: 'full',
  });

  useEffect(() => {
    if (loadStatus === SHOWCASES_SUCCESS) {
      setData({ ...data, isFind: false });
    }
  }, [loadStatus]);

  useEffect(() => {
    if (flashMessage && flashMessage.length) {
      setTimeout(() => {
        show('success', flashMessage);
        showcaseClearFlashMessage();
      }, 1000);
    }
  }, [flashMessage]);

  useEffect(() => {
    const types = [{
      value: 'flow',
      label: 'Поток',
    }, {
      value: 'instant',
      label: 'Моментальный',
    }];

    const columns = [{
      label: '#',
      dbColumn: 'createdAt',
      listColumn: 'number',
      sortable: true,
      width: 5,
      type: DATA,
    }, {
      label: 'Название потока',
      dbColumn: 'name',
      listColumn: 'name',
      sortable: false,
      width: 25,
      type: DATA,
    }, {
      label: 'Тип потока',
      dbColumn: 'type',
      listColumn: 'type',
      sortable: false,
      width: 15,
      handle: type => (type === 'flow'
        ? <Badge color="warning">Поток</Badge>
        : <Badge color="primary">Моментальный</Badge>),
      type: DATA,
    }, {
      label: 'Старт потока',
      dbColumn: 'begin',
      listColumn: 'begin',
      sortable: false,
      width: 10,
      type: DATA,
    }, {
      label: 'Окончание потока',
      dbColumn: 'end',
      listColumn: 'end',
      sortable: false,
      width: 10,
      type: DATA,
    }, {
      label: 'Сортировка',
      dbColumn: 'sort',
      listColumn: 'sort',
      sortable: true,
      width: 10,
      type: DATA,
    }, {
      label: 'Видимый',
      dbColumn: 'isShow',
      listColumn: 'isShow',
      sortable: true,
      width: 10,
      handle: cell => (cell
        ? <Badge color="success">Да</Badge>
        : <Badge color="danger">Нет</Badge>),
      type: DATA,
    }, {
      label: 'Видит только админ',
      dbColumn: 'isPreview',
      listColumn: 'isPreview',
      sortable: true,
      width: 10,
      handle: cell => (cell
        ? <Badge color="danger">Да</Badge>
        : <Badge color="primary">Нет</Badge>),
      type: DATA,
    }, {
      label: 'Действия',
      dbColumn: '_id',
      listColumn: 'id',
      sortable: false,
      width: 5,
      type: ACTION,
    }];

    const buttons = [{
      name: 'edit',
      color: 'primary',
      icon: 'edit',
      type: LINK,
      link: '/showcases/',
      text: 'Редактировать',
    }];

    setData(({
      ...data, columns, buttons, types, status: COMPLETED,
    }));

    return () => {
      showcaseClearState();
    };
  }, []);

  return (
    <Fragment>
      {data.status === COMPLETED && (
        <Fragment>
          <Row>
            <Col md={12} lg={12}>
              <DataTable
                addButton={{ to: '/showcases/create', caption: 'Добавить поток на витрину' }}
                columns={data.columns}
                buttons={data.buttons}
                getConditions={getConditions}
                getList={getShowcasesList}
                list={showcasesList}
                pagination={pagination}
                isFind={data.isFind}
                loadListStatus={loadStatus}
                declensions={['поток', 'потока', 'потоков']}
              />
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

ShowcaseTable.FORM_NAME = 'showcases_table';

ShowcaseTable.propTypes = {
  getShowcasesList: PropTypes.func.isRequired,
  showcaseClearState: PropTypes.func.isRequired,
  showcaseClearFlashMessage: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  showcasesList: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.any),
  ).isRequired,
  // eslint-disable-next-line react/require-default-props
  pagination: PropTypes.objectOf(PropTypes.any),
  loadStatus: PropTypes.string.isRequired,
  flashMessage: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  showcasesList: state.showcases.list,
  pagination: state.showcases.pagination,
  loadStatus: state.showcases.loadListStatus,
  flashMessage: state.showcases.flash,
});

const mapDispatchToProps = dispatch => ({
  getShowcasesList: params => dispatch(getShowcasesListAction(params)),
  showcaseClearState: () => dispatch(showcaseClearStateAction()),
  showcaseClearFlashMessage: () => dispatch(showcaseClearFlashMessageAction()),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(withRouter(ShowcaseTable)));

export default reduxForm({
  form: ShowcaseTable.FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(withConnect);
