import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderField from '../../../../shared/components/form/TextField';
import renderSelectField from '../../../../shared/components/form/Select';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import validate from '../../validate';

class WizardFormOne extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line
    initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
    countries: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    timezones: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit, countries, timezones } = this.props;

    return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Заполните общею информацию о пользователе</h3>
        <div className="form__form-group">
          <span className="form__form-group-label">Имя пользователя</span>
          <div className="form__form-group-field">
            <Field
              name="name"
              component={renderField}
              type="text"
              placeholder="Имя пользователя"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Дата рождения</span>
          <div className="form__form-group-field">
            <Field
              name="birthday"
              component={renderDatePickerField}
            />
          </div>
        </div>
        <div className="form__form-group form__form-select">
          <span className="form__form-group-label">Пол</span>
          <div className="form__form-group-field">
            <Field
              name="gender"
              component={renderSelectField}
              placeholder="Выберите пол"
              options={[
                { value: 'male', label: 'мужской' },
                { value: 'female', label: 'женский' },
              ]}
            />
          </div>
        </div>
        <div className="form__form-group form__form-select">
          <span className="form__form-group-label">Страна</span>
          <div className="form__form-group-field">
            <Field
              name="country"
              component={renderSelectField}
              placeholder="Выберите страну"
              options={countries}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Город</span>
          <div className="form__form-group-field">
            <Field
              name="city"
              component={renderField}
              type="text"
              placeholder="Город"
            />
          </div>
        </div>
        <div className="form__form-group form__form-select">
          <span className="form__form-group-label">Часовой пояс</span>
          <div className="form__form-group-field">
            <Field
              name="tz"
              component={renderSelectField}
              placeholder="Выберите часовой пояс"
              options={timezones}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Телефон</span>
          <div className="form__form-group-field">
            <Field
              name="phone"
              component={renderField}
              type="text"
              placeholder="Контактный телефон"
            />
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="isActive"
              component={renderCheckBoxField}
              label="Активный пользователь"
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" disabled className="previous">Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next">Следующий шаг</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'users_edit_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: false,
  validate,
})(WizardFormOne);
