import ws from '../../../ws';
import getToken from '../../../redux/actions/getToken';

const getObjectId = async () => new Promise((resolve, reject) => {
  const token = getToken();

  ws.emit('api/academy/flows/getObjectId', { token }, (result) => {
    const { status, payload } = result;

    if (status === 'ok' && payload) {
      resolve(payload);
    } else {
      reject();
    }
  });
});

export default getObjectId;
