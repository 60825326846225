import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import {
  QUESTIONS_SUCCESS, QUESTIONS_FAILED,
  getQuestion as getQuestionAction, getAsyncData as getAsyncDataAction, refreshAsyncData as refreshAsyncDataAction,
  questionClearState as questionClearStateAction, updateQuestion as updateQuestionAction,
  setJustSave as setJustSaveAction, deleteQuestion as deleteQuestionAction, sendToTechnical as sendToTechnicalAction,
  closeQuestion as closeQuestionAction, updateEditTime as updateEditTimeAction,
} from '../../../../redux/actions/questionsAction';
import ButtonToNavigate from '../../../../shared/components/form/ButtonToNavigate';
import renderField from '../../../../shared/components/form/TextField';
import renderTextarea from '../../../../shared/components/form/TextareaField';
import renderEditor from '../../../../shared/components/form/TinyWYSIWYGEditor';
import { FullWideNotification } from '../../../../shared/components/Notification';
import CreateTag from '../../components/CreateTag';
import RenderTags from '../../components/RenderTags';
import RenderVideos from '../../components/RenderVideos';
import RenderCourses from '../../components/RenderCourses';
import Dialog from '../../components/Dialog';
import validate from '../../validate';

class EditForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    submitStatus: PropTypes.string.isRequired,
    loadStatus: PropTypes.string.isRequired,
    asyncStatus: PropTypes.string.isRequired,
    asyncData: PropTypes.objectOf(PropTypes.any).isRequired,
    showNotification: PropTypes.func.isRequired,
    getQuestion: PropTypes.func.isRequired,
    getAsyncData: PropTypes.func.isRequired,
    refreshAsyncData: PropTypes.func.isRequired,
    questionClearState: PropTypes.func.isRequired,
    setJustSave: PropTypes.func.isRequired,
    deleteQuestion: PropTypes.func.isRequired,
    sendToTechnical: PropTypes.func.isRequired,
    setCloseQuestion: PropTypes.func.isRequired,
    updateEditTime: PropTypes.func.isRequired,
  };

  state = {
    showCreateTagForm: false,
    intervalId: 0,
  };

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { getQuestion, getAsyncData, match } = this.props;
    const { questionId } = match.params;
    const intervalId = setInterval(this.updateEditTime, 60 * 1000);

    getQuestion(questionId);
    getAsyncData();

    this.setState({ intervalId });
  }

  componentWillReceiveProps(nextProps) {
    const { submitStatus, loadStatus, history } = nextProps;

    if (loadStatus === QUESTIONS_FAILED) {
      history.push('/404');
    }

    if (submitStatus === QUESTIONS_SUCCESS) {
      history.push('/questions');
    } else if (submitStatus === QUESTIONS_FAILED) {
      this.show('danger', 'Произошла непредвиденная ошибка. Обратитесь к разработчикам.');
    }
  }

  componentWillUnmount() {
    const { questionClearState } = this.props;
    const { intervalId } = this.state;

    questionClearState();
    clearInterval(intervalId);
  }

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message={message}
      />,
      position: 'full',
    });
  };

  updateEditTime = () => {
    const { loadStatus, initialValues, updateEditTime } = this.props;
    const { questionEditId } = initialValues;

    if (loadStatus === QUESTIONS_SUCCESS && questionEditId) {
      updateEditTime(questionEditId);
    }
  };

  updateTags = () => {
    const { refreshAsyncData } = this.props;
    refreshAsyncData();
  };

  showCreateTagForm = () => {
    this.setState({ showCreateTagForm: true });
  };

  handleSave = () => {
    const { setJustSave } = this.props;
    setJustSave();
  };

  handleDelete = () => {
    const { deleteQuestion } = this.props;
    deleteQuestion();
  };

  handleSendToTechnical = () => {
    const { sendToTechnical } = this.props;
    sendToTechnical();
  };

  handleClose = () => {
    const { setCloseQuestion } = this.props;
    setCloseQuestion();
  };

  render() {
    const {
      handleSubmit, submitting, t, showNotification, asyncData, initialValues, loadStatus, asyncStatus,
    } = this.props;
    const {
      isShowDialogue, list, lastAnswerId, isEditQuestion,
    } = initialValues;
    const { tags, courses, maraphons } = asyncData;
    const { showCreateTagForm } = this.state;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">ФОРМА ОТВЕТА НА ВОПРОС</h5>
              <h5 className="subhead">{t('maretials.forms.create_form_description')}</h5>
            </div>
            {loadStatus === QUESTIONS_SUCCESS && asyncStatus === QUESTIONS_SUCCESS ? (
              <>
                {!isEditQuestion ? (
                  <div>
                    <form name="questions" className="form">
                      <div className="form__half">
                        <div className="form__form-group">
                          <span className="form__form-group-label">Название вопроса</span>
                          <div className="form__form-group-field">
                            <Field
                              name="title"
                              component={renderField}
                              type="text"
                              placeholder="Название вопроса"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form__half">
                        <div className="form__form-group">
                          <span className="form__form-group-label">Имя пользователя, который задал вопрос</span>
                          {initialValues.name ? (
                            <div className="form__form-group-field">
                              <Field
                                name="name"
                                component={renderField}
                                type="text"
                                placeholder="Имя пользователя"
                              />
                            </div>
                          ) : (
                            <div className="form__form-group-field">
                              <div className="form__form-group-icon">
                                <AccountOutlineIcon />
                              </div>
                              <Field
                                name="user"
                                component="input"
                                type="text"
                                placeholder="Имя пользователя"
                                disabled
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {isShowDialogue ? <Dialog dialog={list} /> : null}
                      <div className="form__form-group">
                        <span className="form__form-group-label">Вопрос</span>
                        <div className="form__form-group-field">
                          <Field
                            name="question"
                            component={renderTextarea}
                            placeholder="Вопрос"
                          />
                        </div>
                      </div>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Список видео материалов</span>
                        <div className="form__form-group-field">
                          <FieldArray name="videos" component={RenderVideos} />
                        </div>
                      </div>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Ответ</span>
                        <div className="form__form-group-field">
                          <Field
                            name="answer"
                            component={renderEditor}
                          />
                        </div>
                      </div>
                    </form>
                    <div className="form_question_half">
                      <div>
                        <form name="questions" className="form">
                          <FieldArray
                            name="tags"
                            component={RenderTags}
                            props={{ options: tags }}
                            showCreateTagForm={this.showCreateTagForm}
                          />
                        </form>
                        {showCreateTagForm || initialValues.tags.length ? (
                          <CreateTag showNotification={showNotification} updateTags={this.updateTags} />
                        ) : null}
                      </div>
                      <div>
                        <form name="questions" className="form">
                          <div className="form__form-group">
                            <FieldArray
                              name="academy"
                              component={RenderCourses}
                              props={{ courses: [...courses, ...maraphons] }}
                              rerenderOnEveryChange={!false}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                    <form name="questions" className="form" onSubmit={handleSubmit}>
                      <ButtonToolbar className="form__button-toolbar">
                        <Button color="primary" type="submit" disabled={submitting}>
                          Опубликовать и отправить уведомление на почту
                        </Button>
                        <Button color="primary" type="submit" onClick={this.handleSave} disabled={submitting}>
                          Опубликовать
                        </Button>
                        {!lastAnswerId ? (
                          <Button
                            color="warning"
                            type="submit"
                            onClick={this.handleSendToTechnical}
                            disabled={submitting}
                          >
                            Перенаправить в тех. поддержку
                          </Button>
                        ) : null}
                        {!lastAnswerId ? (
                          <Button color="danger" type="submit" onClick={this.handleDelete} disabled={submitting}>
                            Удалить и отправить уведомление на почту
                          </Button>
                        ) : null}
                        {!lastAnswerId ? (
                          <Button color="success" type="submit" onClick={this.handleClose} disabled={submitting}>
                            Закрыть вопрос
                          </Button>
                        ) : null}
                        <Route
                          path="/"
                          render={props => <ButtonToNavigate {...props} title="Отмена" path="/questions" />}
                        />
                      </ButtonToolbar>
                    </form>
                  </div>
                ) : (
                  <div>
                    <h3 style={{ marginBottom: '30px' }}>На данный момент на этот вопрос отвечает другой психолог.</h3>
                    <ButtonToolbar className="form__button-toolbar">
                      <Route
                        path="/"
                        render={props => <ButtonToNavigate {...props} title="Вернуться к вопросам" path="/questions" />}
                      />
                    </ButtonToolbar>
                  </div>
                )}
              </>
            ) : null}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: state.questions.active,
  submitStatus: state.questions.submitStatus,
  asyncData: state.questions.asyncData,
  asyncStatus: state.questions.asyncStatus,
  loadStatus: state.questions.loadActiveStatus,
  justSave: state.questions.justSave,
  isDelete: state.questions.isDelete,
  isSendToTechnical: state.questions.isSendToTechnical,
  closeQuestion: state.questions.closeQuestion,
});

const mapDispatchToProps = dispatch => ({
  getQuestion: questionId => dispatch(getQuestionAction(questionId)),
  getAsyncData: () => dispatch(getAsyncDataAction()),
  refreshAsyncData: () => dispatch(refreshAsyncDataAction()),
  questionClearState: () => dispatch(questionClearStateAction()),
  setJustSave: () => dispatch(setJustSaveAction()),
  deleteQuestion: () => dispatch(deleteQuestionAction()),
  sendToTechnical: () => dispatch(sendToTechnicalAction()),
  setCloseQuestion: () => dispatch(closeQuestionAction()),
  updateEditTime: editId => dispatch(updateEditTimeAction(editId)),
});

const EditFormComponent = reduxForm({
  form: 'questions_edit_form', // a unique identifier for this form
  validate,
  enableReinitialize: true,
  onSubmit: updateQuestionAction,
})(withTranslation('common')(withRouter(EditForm)));

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditFormComponent);
