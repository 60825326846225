import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field } from 'redux-form';
import ArrowUpBold from 'mdi-react/ArrowUpBoldIcon';
import ArrowDownBoldIcon from 'mdi-react/ArrowDownBoldIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import hiddenField from '../../../shared/components/form/HiddenField';
import renderField from '../../../shared/components/form/TextField';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';

const marginTop20 = { marginTop: '20px' };

class RenderOptions extends PureComponent {
  static propTypes = {
    fields: PropTypes.objectOf(PropTypes.any).isRequired,
    meta: PropTypes.objectOf(PropTypes.any).isRequired,
    showIsMain: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    isMain: PropTypes.number,
  };

  static moveToUp(fields, index) {
    if (index > 0) {
      fields.swap(index, index - 1);
    }
  }

  static moveToDown(fields, index) {
    if (fields.length - 1 !== index) {
      fields.swap(index, index + 1);
    }
  }

  render() {
    const {
      fields, meta: { error, submitFailed }, showIsMain, text, isMain,
    } = this.props;
    const { moveToUp, moveToDown } = RenderOptions;

    return (
      <div className="form__form-group">
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" size="sm" onClick={() => fields.push({})}>Добавить вариант</Button>
        </ButtonToolbar>
        {submitFailed && error
        && (
          <div className="form__form-group">
            <div>
              <span className="form__form-group-error">{error}</span>
            </div>
          </div>
        )}
        {fields.map((option, index) => (
          <div key={index} className="form__form-group">
            <span className="form__form-group-label">{text} # {index + 1}</span>
            <div className="form__form-group-field">
              <Field
                name={`${option}.name`}
                component={renderField}
                type="text"
                placeholder={text}
              />
              <Field
                name={`${option}.id`}
                component={hiddenField}
              />
              <div className="form__form-group-icon">
                <ArrowUpBold onClick={() => moveToUp(fields, index)} />
              </div>
              <div className="form__form-group-icon">
                <ArrowDownBoldIcon onClick={() => moveToDown(fields, index)} />
              </div>
              <div className="form__form-group-icon">
                <TrashCanOutlineIcon onClick={() => fields.remove(index)} />
              </div>
            </div>
            {showIsMain ? (
              <div className="form__form-group-field" style={marginTop20}>
                <Field
                  name="isMain"
                  component={renderRadioButtonField}
                  radioValue={index}
                  label="Основной"
                  checked={isMain === index}
                />
              </div>
            ) : null}
          </div>
        ))}
      </div>
    );
  }
}

RenderOptions.defaultProps = {
  isMain: 0,
};

export default RenderOptions;
