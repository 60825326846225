import moment from 'moment';
import { startSubmit, stopSubmit } from 'redux-form';
import ws from '../../ws';
import getToken from './getToken';
import PaginationPageConverter from '../../shared/classes/PaginationPageConverter';

const COURSES_PROGRESS = 'progress';
const COURSES_SUCCESS = 'success';
const COURSES_FAILED = 'failed';

const COURSES_GET_LIST_PROGRESS = 'COURSES_GET_LIST_PROGRESS';
const COURSES_GET_LIST_SUCCESS = 'COURSES_GET_LIST_SUCCESS';
const COURSES_GET_LIST_FAILED = 'COURSES_GET_LIST_FAILED';
const COURSES_FORM_SUBMIT_PROGRESS = 'COURSES_FORM_SUBMIT_PROGRESS';
const COURSES_FORM_SUBMIT_SUCCESS = 'COURSES_FORM_SUBMIT_SUCCESS';
const COURSES_FORM_SUBMIT_FAILED = 'COURSES_FORM_SUBMIT_FAILED';
const COURSES_GET_COURSE_PROGRESS = 'COURSES_GET_COURSE_PROGRESS';
const COURSES_GET_COURSE_SUCCESS = 'COURSES_GET_COURSE_SUCCESS';
const COURSES_GET_COURSE_FAILED = 'COURSES_GET_COURSE_FAILED';
const COURSES_GET_ASYNC_DATA_PROGRESS = 'COURSES_GET_ASYNC_DATA_PROGRESS';
const COURSES_GET_ASYNC_DATA_SUCCESS = 'COURSES_GET_ASYNC_DATA_SUCCESS';
const COURSES_GET_ASYNC_DATA_FAILED = 'COURSES_GET_ASYNC_DATA_FAILED';
const COURSES_GET_ASYNC_FORM_DATA_PROGRESS = 'COURSES_GET_ASYNC_FORM_DATA_PROGRESS';
const COURSES_GET_ASYNC_FORM_DATA_SUCCESS = 'COURSES_GET_ASYNC_FORM_DATA_SUCCESS';
const COURSES_GET_ASYNC_FORM_DATA_FAILED = 'COURSES_GET_ASYNC_FORM_DATA_FAILED';
const COURSES_CLEAR_STATE = 'COURSES_CLEAR_STATE';

const COURSES_CLEAR_FLASH_MESSAGE = 'COURSES_CLEAR_FLASH_MESSAGE';

const courseGetListProgress = () => ({
  type: COURSES_GET_LIST_PROGRESS,
});

const courseGetListSuccess = payload => ({
  type: COURSES_GET_LIST_SUCCESS,
  payload,
});

const courseGetListFailed = () => ({
  type: COURSES_GET_LIST_FAILED,
});

const courseStartFormSubmit = () => ({
  type: COURSES_FORM_SUBMIT_PROGRESS,
});

const courseFormSubmitSuccess = payload => ({
  type: COURSES_FORM_SUBMIT_SUCCESS,
  payload,
});

const courseFormSubmitFailed = () => ({
  type: COURSES_FORM_SUBMIT_FAILED,
});

const courseGetCourseProgress = () => ({
  type: COURSES_GET_COURSE_PROGRESS,
});

const courseGetCourseSuccess = payload => ({
  type: COURSES_GET_COURSE_SUCCESS,
  payload,
});

const courseGetCourseFailed = () => ({
  type: COURSES_GET_COURSE_FAILED,
});

const courseGetAsyncDataProgress = () => ({
  type: COURSES_GET_ASYNC_DATA_PROGRESS,
});

const courseGetAsyncDataSuccess = payload => ({
  type: COURSES_GET_ASYNC_DATA_SUCCESS,
  payload,
});

const courseGetAsyncDataFailed = () => ({
  type: COURSES_GET_ASYNC_DATA_FAILED,
});

const courseGetAsyncFormDataProgress = () => ({
  type: COURSES_GET_ASYNC_FORM_DATA_PROGRESS,
});

const courseGetAsyncFormDataSuccess = payload => ({
  type: COURSES_GET_ASYNC_FORM_DATA_SUCCESS,
  payload,
});

const courseGetAsyncFormDataFailed = () => ({
  type: COURSES_GET_ASYNC_FORM_DATA_FAILED,
});

const courseClearState = () => ({
  type: COURSES_CLEAR_STATE,
});

const convertListToTable = data => new PaginationPageConverter(data, doc => ({
  id: doc.id,
  name: doc.name,
  direction: doc.direction.name,
  isVisible: doc.isVisible,
  createdAt: moment(doc.createdAt).locale('ru').format('DD MMM YY г., HH:mm'),
})).getConvertedData();

const getCoursesList = params => (dispatch) => {
  const token = getToken();
  dispatch(courseGetListProgress());

  ws.emit('api/academy/courses/list', { token, payload: params }, (data) => {
    const { status, payload } = data;
    let action;

    if (status === 'ok') {
      action = courseGetListSuccess(convertListToTable(payload.data));
    } else {
      action = courseGetListFailed();
    }

    dispatch(action);
  });
};

const convertSetCourseToDB = (data) => {
  const {
    name,
    slug,
    author,
    description,
    landing,
    direction,
    themes,
    backgroundColor,
    courseNameColor,
    courseAuthorColor,
    courseProgressColor,
    buttonBackgroundColor,
    buttonTextColor,
    buttonBorderColor,
  } = data;

  const themesArray = [];

  for (let i = 0; i < themes.length; i += 1) {
    themesArray.push(themes[i].theme.value);
  }

  const convertedData = {
    name, slug, author, description, landing, themes: themesArray,
  };

  convertedData.direction = typeof direction === 'string' ? direction : direction.value;
  convertedData.isVisible = Object.prototype.hasOwnProperty.call(data, 'isVisible') ? data.isVisible : false;

  const colors = {};

  if (backgroundColor) {
    colors.background = typeof backgroundColor === 'string' ? backgroundColor : backgroundColor.hex.substr(1);
  }

  if (courseNameColor) {
    colors.courseName = typeof courseNameColor === 'string' ? courseNameColor : courseNameColor.hex.substr(1);
  }

  if (courseAuthorColor) {
    colors.courseAuthor = typeof courseAuthorColor === 'string' ? courseAuthorColor : courseAuthorColor.hex.substr(1);
  }

  if (courseProgressColor) {
    colors.courseProgress = typeof courseProgressColor === 'string'
      ? courseProgressColor : courseProgressColor.hex.substr(1);
  }

  const button = {};

  if (buttonBackgroundColor) {
    button.background = typeof buttonBackgroundColor === 'string'
      ? buttonBackgroundColor : buttonBackgroundColor.hex.substr(1);
  }

  if (buttonTextColor) {
    button.textColor = typeof buttonTextColor === 'string' ? buttonTextColor : buttonTextColor.hex.substr(1);
  }

  if (buttonBorderColor) {
    button.borderColor = typeof buttonBorderColor === 'string' ? buttonBorderColor : buttonBorderColor.hex.substr(1);
  }

  if (Object.keys(button).length) {
    colors.button = button;
  }

  if (Object.keys(colors).length) {
    convertedData.color = colors;
  }

  return convertedData;
};

const generateImageObject = async file => new Promise((resolve) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    resolve({ buffer: e.target.result, type: file.type });
  };

  reader.readAsArrayBuffer(file);
});

const createCourse = async (data, dispatch, props) => {
  const token = getToken();
  const { form, reset } = props;
  const convertedData = convertSetCourseToDB(data);
  const { background, layer, preview } = data;
  const images = {};

  if (background && background.length) {
    images.background = await generateImageObject(background[0]);
  }

  if (layer && layer.length) {
    images.layer = await generateImageObject(layer[0]);
  }

  if (preview && preview.length) {
    images.preview = await generateImageObject(preview[0]);
  }

  dispatch(startSubmit(form));
  dispatch(courseStartFormSubmit());

  ws.emit('api/academy/courses/create', {
    // payload: convertedData, images, token
    token, payload: { convertedData, images },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      reset();
      dispatch(stopSubmit(form));
      dispatch(courseFormSubmitSuccess('Новый курс был успешно создан.'));
      props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(courseFormSubmitFailed());
    }
  });
};

const convertDBCourseToEdit = (payload) => {
  const { course: courseFromDB } = payload;
  const { image, color } = courseFromDB;
  const { button } = color;

  const course = {
    id: courseFromDB.id,
    name: courseFromDB.name,
    isVisible: courseFromDB.isVisible,
    slug: courseFromDB.slug,
    author: courseFromDB.author,
    description: courseFromDB.description,
    landing: courseFromDB.landing,
    direction: courseFromDB.direction,
    themes: courseFromDB.themes.map(item => ({ theme: { value: item.id, label: item.name } })),
    backgroundColor: color.background,
    courseNameColor: color.courseName,
    courseAuthorColor: color.courseAuthor,
    courseProgressColor: color.courseProgress,
    buttonBackgroundColor: button.background,
    buttonTextColor: button.textColor,
    buttonBorderColor: button.borderColor,
    background: !image.background ? image.background : [{ preview: image.background }],
    layer: !image.layer ? image.layer : [{ preview: image.layer }],
    preview: !image.preview ? image.preview : [{ preview: image.preview }],
  };

  return { course };
};

const getCourse = courseId => async (dispatch) => {
  const token = getToken();
  dispatch(courseGetCourseProgress());
  ws.emit('api/academy/courses/getById', {
    // courseId, token
    token, payload: { courseId },
  }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = courseGetCourseSuccess(convertDBCourseToEdit(payload));
    } else {
      action = courseGetCourseFailed();
    }

    dispatch(action);
  });
};

const updateCourse = async (data, dispatch, props) => {
  const token = getToken();
  const {
    id, background, layer, preview,
  } = data;
  const { form } = props;
  const convertedData = convertSetCourseToDB(data);
  const images = {};

  const generateImage = async (file) => {
    let result;

    if (file && file.length && file[0] instanceof File) {
      result = await generateImageObject(file[0]);
    } else if (file && file.length && file[0] instanceof Object) {
      result = true;
    } else {
      result = null;
    }

    return result;
  };

  images.background = await generateImage(background);
  images.layer = await generateImage(layer);
  images.preview = await generateImage(preview);

  dispatch(startSubmit(form));
  dispatch(courseStartFormSubmit());

  ws.emit('api/academy/courses/updateById', {
    // courseId: id, payload: convertedData, images, token,
    token, payload: { courseId: id, convertedData, images },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(stopSubmit(form));
      dispatch(courseFormSubmitSuccess('Курс был успешно отредактирован.'));
      props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(courseFormSubmitFailed());
    }
  });
};

const transformAsyncData = data => ({
  themes: data.themes.map(theme => ({ value: theme.id, label: theme.name })),
  directions: data.directions.map(direction => ({ value: direction.id, label: direction.name })),
});

const getAsyncData = () => async (dispatch) => {
  const token = getToken();
  dispatch(courseGetAsyncDataProgress());

  ws.emit('api/academy/courses/getAsyncData', { token }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = courseGetAsyncDataSuccess(transformAsyncData(payload));
    } else {
      action = courseGetAsyncDataFailed();
    }

    dispatch(action);
  });
};

const transformAsyncDataForTable = data => ({
  directions: data.directions.map(direction => ({ value: direction.id, label: direction.name })),
});

const getAsyncDataForTable = () => (dispatch) => {
  const token = getToken();
  dispatch(courseGetAsyncFormDataProgress());

  ws.emit('api/academy/courses/getAsyncDataForTable', { token }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = courseGetAsyncFormDataSuccess(transformAsyncDataForTable(payload));
    } else {
      action = courseGetAsyncFormDataFailed();
    }

    dispatch(action);
  });
};

const courseClearFlashMessage = () => ({
  type: COURSES_CLEAR_FLASH_MESSAGE,
});

export {
  COURSES_PROGRESS,
  COURSES_SUCCESS,
  COURSES_FAILED,
  COURSES_GET_LIST_PROGRESS,
  COURSES_GET_LIST_SUCCESS,
  COURSES_GET_LIST_FAILED,
  COURSES_FORM_SUBMIT_PROGRESS,
  COURSES_FORM_SUBMIT_SUCCESS,
  COURSES_FORM_SUBMIT_FAILED,
  COURSES_GET_COURSE_PROGRESS,
  COURSES_GET_COURSE_SUCCESS,
  COURSES_GET_COURSE_FAILED,
  COURSES_GET_ASYNC_DATA_PROGRESS,
  COURSES_GET_ASYNC_DATA_SUCCESS,
  COURSES_GET_ASYNC_DATA_FAILED,
  COURSES_GET_ASYNC_FORM_DATA_PROGRESS,
  COURSES_GET_ASYNC_FORM_DATA_SUCCESS,
  COURSES_GET_ASYNC_FORM_DATA_FAILED,
  COURSES_CLEAR_STATE,
  COURSES_CLEAR_FLASH_MESSAGE,
  courseGetListProgress,
  courseGetListSuccess,
  courseGetListFailed,
  courseStartFormSubmit,
  courseFormSubmitSuccess,
  courseFormSubmitFailed,
  courseGetCourseProgress,
  courseGetCourseSuccess,
  courseGetCourseFailed,
  courseGetAsyncDataProgress,
  courseGetAsyncDataSuccess,
  courseGetAsyncDataFailed,
  courseClearState,
  getCoursesList,
  createCourse,
  getCourse,
  updateCourse,
  getAsyncData,
  getAsyncDataForTable,
  courseClearFlashMessage,
};
