import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  IMAGES_SUCCESS, IMAGES_FAILED,
  createImage as createImageAction, imageClearState as imageClearStateAction,
} from '../../../../redux/actions/imagesAction';
import ButtonToNavigate from '../../../../shared/components/form/ButtonToNavigate';
import renderField from '../../../../shared/components/form/TextField';
import renderDropZoneField from '../../../../shared/components/form/DropZone';
import { FullWideNotification } from '../../../../shared/components/Notification';
import validate from '../../validate';

class CreateForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    submitStatus: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    imageClearState: PropTypes.func.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    const { submitStatus, history } = nextProps;

    if (submitStatus === IMAGES_SUCCESS) {
      history.push('/images');
    } else if (submitStatus === IMAGES_FAILED) {
      this.show('danger', 'Произошла непредвиденная ошибка. Обратитесь к разработчикам.');
    }
  }

  componentWillUnmount() {
    const { imageClearState } = this.props;
    imageClearState();
  }

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message={message}
      />,
      position: 'full',
    });
  };

  render() {
    const {
      handleSubmit, submitting, t, showNotification,
    } = this.props;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">{t('images.forms.create_form_title')}</h5>
              <h5 className="subhead">{t('images.forms.create_form_description')}</h5>
            </div>
            <form className="form" onSubmit={handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">Название изображения</span>
                <div className="form__form-group-field">
                  <Field
                    name="name"
                    component={renderField}
                    type="text"
                    placeholder="Название изображения"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Изображение</span>
                <div className="form__form-group-field">
                  <Field
                    name="image"
                    component={renderDropZoneField}
                    showNotification={showNotification}
                    mimes={['image/jpeg', 'image/png']}
                    maxFilesize={2097152} /* 2Mb */
                  />
                </div>
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit" disabled={submitting}>Создать</Button>
                <Route
                  path="/"
                  render={props => <ButtonToNavigate {...props} title="Отмена" path="/images" />}
                />
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  submitStatus: state.images.submitStatus,
});

const mapDispatchToProps = dispatch => ({
  imageClearState: () => dispatch(imageClearStateAction()),
});

const CreateFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateForm);

export default reduxForm({
  form: 'images_create_form', // a unique identifier for this form
  validate,
  onSubmit: createImageAction,
})(withTranslation('common')(withRouter(CreateFormComponent)));
