import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { createFlow as createFlowAction } from '../../../../redux/actions/flowsAction';
import validate from '../../validate';

const marginTop20 = { marginTop: '20px' };
const marginBottom20 = { marginBottom: '20px' };

class WizardFormSeven extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit, previousPage } = this.props;

    return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Настройка вебинаров</h3>
        <h4 className="bold-text center-block" style={marginBottom20}>
          Этот раздел находится в стадии разработки
        </h4>
        <ButtonToolbar className="form__button-toolbar wizard__toolbar" style={marginTop20}>
          <Button color="primary" type="button" className="previous" onClick={previousPage}>Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next">Создать поток</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'flows_create_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: createFlowAction,
  validate,
})(WizardFormSeven);
