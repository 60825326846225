import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import validate from '../../validate';

const lead = {
  width: '100%',
  fontSize: '14px',
  fontWeight: 'bold',
};

const textCenter = {
  textAlign: 'center',
};

const checkboxPosition = {
  margin: '10px 0 0',
  padding: '0',
};

class WizardFormFive extends PureComponent {
  static propTypes = {
    orders: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit, previousPage, orders } = this.props;

    return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
        <h3 className="wizard__title" style={textCenter}>
          Заблокируйте потоки к которым пользователь не может иметь доступ
        </h3>
        {orders.map(order => (
          <div key={order.amoLeadId} className="form__form-group">
            <p style={lead}>Сделка #{order.amoLeadId}</p>
            {order.courses.map((course, index) => (
              <p key={index} style={{ width: '100%' }}>
                <strong>Поток #{index + 1}:</strong> {course.flowName}
                <br />
                <strong>Пакет:</strong> {course.packageName}
              </p>
            ))}
            <div className="form__form-group-field" style={checkboxPosition}>
              <Field
                name={`blocks['order_${order.amoLeadId}']`}
                component={renderCheckBoxField}
                label="Закрыть доступ к этой сделке"
              />
            </div>
          </div>
        ))}
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={previousPage}>Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next">Следующий шаг</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'users_edit_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: false,
  validate,
})(WizardFormFive);
