import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { FieldArray, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import RenderChildren from '../../components/RenderChildren';
import validate from '../../validate';

class WizardFormThree extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit, previousPage } = this.props;

    return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Заполните информацию о детях</h3>
        <FieldArray name="children" component={RenderChildren} />
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={previousPage}>Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next">Следующий шаг</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'users_edit_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: false,
  validate,
})(WizardFormThree);
