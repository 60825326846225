import moment from 'moment';
import { startSubmit, stopSubmit } from 'redux-form';
import ws from '../../ws';
import getToken from './getToken';
import PaginationPageConverter from '../../shared/classes/PaginationPageConverter';

const GROUPS_PROGRESS = 'progress';
const GROUPS_SUCCESS = 'success';
const GROUPS_FAILED = 'failed';

const GROUPS_GET_LIST_PROGRESS = 'GROUPS_GET_LIST_PROGRESS';
const GROUPS_GET_LIST_SUCCESS = 'GROUPS_GET_LIST_SUCCESS';
const GROUPS_GET_LIST_FAILED = 'GROUPS_GET_LIST_FAILED';
const GROUPS_FORM_SUBMIT_PROGRESS = 'GROUPS_FORM_SUBMIT_PROGRESS';
const GROUPS_FORM_SUBMIT_SUCCESS = 'GROUPS_FORM_SUBMIT_SUCCESS';
const GROUPS_FORM_SUBMIT_FAILED = 'GROUPS_FORM_SUBMIT_FAILED';
const GROUPS_GET_GROUP_PROGRESS = 'GROUPS_GET_GROUP_PROGRESS';
const GROUPS_GET_GROUP_SUCCESS = 'GROUPS_GET_GROUP_SUCCESS';
const GROUPS_GET_GROUP_FAILED = 'GROUPS_GET_GROUP_FAILED';
const GROUPS_GET_ASYNC_DATA_PROGRESS = 'GROUPS_GET_ASYNC_DATA_PROGRESS';
const GROUPS_GET_ASYNC_DATA_SUCCESS = 'GROUPS_GET_ASYNC_DATA_SUCCESS';
const GROUPS_GET_ASYNC_DATA_FAILED = 'GROUPS_GET_ASYNC_DATA_FAILED';
const GROUPS_DELETE = 'GROUPS_DELETE';
const GROUPS_CLEAR_STATE = 'GROUPS_CLEAR_STATE';

const GROUPS_CLEAR_FLASH_MESSAGE = 'GROUPS_CLEAR_FLASH_MESSAGE';

const groupGetListProgress = () => ({
  type: GROUPS_GET_LIST_PROGRESS,
});

const groupGetListSuccess = payload => ({
  type: GROUPS_GET_LIST_SUCCESS,
  payload,
});

const groupGetListFailed = () => ({
  type: GROUPS_GET_LIST_FAILED,
});

const groupStartFormSubmit = () => ({
  type: GROUPS_FORM_SUBMIT_PROGRESS,
});

const groupFormSubmitSuccess = payload => ({
  type: GROUPS_FORM_SUBMIT_SUCCESS,
  payload,
});

const groupFormSubmitFailed = () => ({
  type: GROUPS_FORM_SUBMIT_FAILED,
});

const groupGetGroupProgress = () => ({
  type: GROUPS_GET_GROUP_PROGRESS,
});

const groupGetGroupSuccess = payload => ({
  type: GROUPS_GET_GROUP_SUCCESS,
  payload,
});

const groupGetGroupFailed = () => ({
  type: GROUPS_GET_GROUP_FAILED,
});

const groupGetAsyncDataProgress = () => ({
  type: GROUPS_GET_ASYNC_DATA_PROGRESS,
});

const groupGetAsyncDataSuccess = payload => ({
  type: GROUPS_GET_ASYNC_DATA_SUCCESS,
  payload,
});

const groupGetAsyncDataFailed = () => ({
  type: GROUPS_GET_ASYNC_DATA_FAILED,
});

const groupDelete = () => ({
  type: GROUPS_DELETE,
});

const groupClearState = () => ({
  type: GROUPS_CLEAR_STATE,
});

const convertListToTable = (data) => {
  const getType = (type) => {
    let typeName = '';

    switch (type) {
      case 'list': typeName = 'Список'; break;
      case 'sublist': typeName = 'Подсписок'; break;
      case 'done': typeName = 'Список Выполнено'; break;
      case 'field': typeName = 'Поле'; break;
      case 'radio': typeName = 'Переключатели'; break;
      case 'sentences': typeName = 'Закончить предложения'; break;
      case 'interview': typeName = 'Массив галочек'; break;
      case 'mix': typeName = 'Поле с вариантами'; break;
      default: typeName = 'не определено'; break;
    }

    return typeName;
  };

  return new PaginationPageConverter(data, doc => ({
    id: doc.id,
    name: doc.name,
    type: getType(doc.type),
    code: `%group_edit_${doc.id}_${doc.type}%`,
    createdAt: moment(doc.createdAt).locale('ru').format('DD MMM YY г., HH:mm'),
  })).getConvertedData();
};

const getGroupsList = params => (dispatch) => {
  const token = getToken();
  dispatch(groupGetListProgress());

  ws.emit('api/academy/groups/list', { token, payload: params }, (data) => {
    const { status, payload } = data;
    let action;

    if (status === 'ok') {
      action = groupGetListSuccess(convertListToTable(payload.data));
    } else {
      action = groupGetListFailed();
    }

    dispatch(action);
  });
};

const convertSetGroupToDB = (data) => {
  const {
    type,
    name,
    min,
    isAdd,
    isSortable,
    textNearField,
    isIterableTextNearField,
    parent,
    additionalText,
    additionalText2,
    options,
    options2,
    isMain,
  } = data;

  const optionsList = (list, useIsMain = false) => {
    let values = [];

    if (typeof list !== 'undefined') {
      values = list.map((option, index) => {
        const value = { name: option.name };

        if (option.id) {
          // eslint-disable-next-line no-underscore-dangle
          value._id = option.id;
        }

        if (useIsMain && type === 'mix') {
          value.isMain = isMain === index;
        }

        return value;
      });
    }

    return values;
  };

  return {
    type,
    name,
    min: typeof min !== 'undefined' ? min : null,
    isAdd: typeof isAdd !== 'undefined' ? isAdd : false,
    isSortable: typeof isSortable !== 'undefined' ? isSortable : false,
    textNearField: !textNearField ? null : textNearField,
    isIterableTextNearField: typeof isIterableTextNearField !== 'undefined' ? isIterableTextNearField : false,
    // eslint-disable-next-line no-nested-ternary
    parent: typeof parent === 'undefined' || parent === null
      ? null
      : (typeof parent === 'string' ? parent : parent.value),
    additionalText: typeof additionalText !== 'undefined' ? additionalText : null,
    additionalText2: typeof additionalText2 !== 'undefined' ? additionalText2 : null,
    options: optionsList(options, true),
    options2: optionsList(options2),
  };
};

const createGroup = async (data, dispatch, props) => {
  const token = getToken();
  const { form, reset } = props;
  const convertedData = convertSetGroupToDB(data);

  dispatch(startSubmit(form));
  dispatch(groupStartFormSubmit());

  ws.emit('api/academy/groups/create', {
    // token, payload: convertedData,
    token, payload: { convertedData },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      reset();
      dispatch(stopSubmit(form));
      dispatch(groupFormSubmitSuccess('Новая группа полей была успешно создана.'));
      props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(groupFormSubmitFailed());
    }
  });
};

const convertDBGroupToEdit = (payload) => {
  const { group: groupFromDB } = payload;
  const {
    id,
    name,
    type,
    min,
    isAdd,
    isSortable,
    parent,
    textNearField,
    isIterableTextNearField,
    additionalText,
    additionalText2,
    options,
    options2,
  } = groupFromDB;

  const group = {
    id,
    name,
    type,
    min,
    isAdd,
    isSortable,
    parent,
    textNearField,
    isIterableTextNearField,
    additionalText,
    additionalText2,
    options,
    options2,
  };

  if (type === 'mix') {
    group.isMain = options.findIndex(option => option.isMain);
  }

  return { group };
};

const getGroup = groupId => async (dispatch) => {
  const token = getToken();
  dispatch(groupGetGroupProgress());

  ws.emit('api/academy/groups/getGroupById', { token, payload: { groupId } }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = groupGetGroupSuccess(convertDBGroupToEdit(payload));
    } else {
      action = groupGetGroupFailed();
    }

    dispatch(action);
  });
};

const updateGroup = async (data, dispatch, props) => {
  const token = getToken();
  const { id } = data;
  const { form, isDelete } = props;
  const convertedData = convertSetGroupToDB(data);

  dispatch(startSubmit(form));
  dispatch(groupStartFormSubmit());

  if (isDelete) {
    ws.emit('api/academy/groups/deleteById', { token, payload: { groupId: id } }, (result) => {
      const { status } = result;

      if (status === 'ok') {
        dispatch(stopSubmit(form));
        dispatch(groupFormSubmitSuccess('Группа полей была успешно удалена.'));
        props.destroy();
      } else {
        dispatch(stopSubmit(form, 'error'));
        dispatch(groupFormSubmitFailed());
      }
    });
  } else {
    ws.emit('api/academy/groups/updateById', { token, payload: { groupId: id, convertedData } }, (result) => {
      const { status } = result;

      if (status === 'ok') {
        dispatch(stopSubmit(form));
        dispatch(groupFormSubmitSuccess('Группа полей была успешно отредактирована.'));
        props.destroy();
      } else {
        dispatch(stopSubmit(form, 'error'));
        dispatch(groupFormSubmitFailed());
      }
    });
  }
};

const transformAsyncData = data => ({
  lists: data.lists.map(list => ({ value: list.id, label: list.name })),
});

const getAsyncData = () => async (dispatch) => {
  const token = getToken();
  dispatch(groupGetAsyncDataProgress());

  ws.emit('api/academy/groups/getAsyncData', { token }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = groupGetAsyncDataSuccess(transformAsyncData(payload));
    } else {
      action = groupGetAsyncDataFailed();
    }

    dispatch(action);
  });
};

const deleteGroup = () => (dispatch) => {
  dispatch(groupDelete());
};

const groupClearFlashMessage = () => ({
  type: GROUPS_CLEAR_FLASH_MESSAGE,
});

export {
  GROUPS_PROGRESS,
  GROUPS_SUCCESS,
  GROUPS_FAILED,
  GROUPS_GET_LIST_PROGRESS,
  GROUPS_GET_LIST_SUCCESS,
  GROUPS_GET_LIST_FAILED,
  GROUPS_FORM_SUBMIT_PROGRESS,
  GROUPS_FORM_SUBMIT_SUCCESS,
  GROUPS_FORM_SUBMIT_FAILED,
  GROUPS_GET_GROUP_PROGRESS,
  GROUPS_GET_GROUP_SUCCESS,
  GROUPS_GET_GROUP_FAILED,
  GROUPS_CLEAR_STATE,
  GROUPS_GET_ASYNC_DATA_PROGRESS,
  GROUPS_GET_ASYNC_DATA_SUCCESS,
  GROUPS_GET_ASYNC_DATA_FAILED,
  GROUPS_DELETE,
  GROUPS_CLEAR_FLASH_MESSAGE,
  groupGetListProgress,
  groupGetListSuccess,
  groupGetListFailed,
  groupStartFormSubmit,
  groupFormSubmitSuccess,
  groupFormSubmitFailed,
  groupGetGroupProgress,
  groupGetGroupSuccess,
  groupGetGroupFailed,
  groupClearState,
  getGroupsList,
  createGroup,
  getGroup,
  updateGroup,
  getAsyncData,
  deleteGroup,
  groupClearFlashMessage,
};
