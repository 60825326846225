import update from 'immutability-helper';
import {
  HUBS_PROGRESS,
  HUBS_SUCCESS,
  HUBS_FAILED,
  HUBS_GET_LIST_PROGRESS,
  HUBS_GET_LIST_SUCCESS,
  HUBS_GET_LIST_FAILED,
  HUBS_FORM_SUBMIT_PROGRESS,
  HUBS_FORM_SUBMIT_SUCCESS,
  HUBS_FORM_SUBMIT_FAILED,
  HUBS_GET_HUB_PROGRESS,
  HUBS_GET_HUB_SUCCESS,
  HUBS_GET_HUB_FAILED,
  HUBS_GET_ASYNC_DATA_PROGRESS,
  HUBS_GET_ASYNC_DATA_SUCCESS,
  HUBS_GET_ASYNC_DATA_FAILED,
  HUBS_CLEAR_STATE,
  HUBS_CLEAR_FLASH_MESSAGE,
} from '../actions/hubsAction';

const initialState = {
  loadListStatus: '', // progress/success/failed
  loadActiveStatus: '', // progress/success/failed
  submitStatus: '', // progress/success/failed
  asyncStatus: '', // progress/success/failed
  list: [],
  pagination: {},
  active: {},
  asyncData: {},
  flash: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case HUBS_GET_LIST_PROGRESS: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: HUBS_PROGRESS },
        },
      );
    }
    case HUBS_GET_LIST_SUCCESS: {
      return update(
        state, {
          list: { $set: action.payload.list },
          pagination: { $set: action.payload.pagination },
          loadListStatus: { $set: HUBS_SUCCESS },
        },
      );
    }
    case HUBS_GET_LIST_FAILED: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: HUBS_FAILED },
        },
      );
    }
    case HUBS_FORM_SUBMIT_PROGRESS: {
      return update(
        state,
        { submitStatus: { $set: HUBS_PROGRESS } },
      );
    }
    case HUBS_FORM_SUBMIT_SUCCESS: {
      return update(
        state,
        {
          flash: { $set: action.payload },
          submitStatus: { $set: HUBS_SUCCESS },
        },
      );
    }
    case HUBS_FORM_SUBMIT_FAILED: {
      return update(
        state,
        { submitStatus: { $set: HUBS_FAILED } },
      );
    }
    case HUBS_GET_HUB_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: HUBS_PROGRESS },
        },
      );
    }
    case HUBS_GET_HUB_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.hub },
          loadActiveStatus: { $set: HUBS_SUCCESS },
        },
      );
    }
    case HUBS_GET_HUB_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: HUBS_FAILED },
        },
      );
    }
    case HUBS_GET_ASYNC_DATA_PROGRESS: {
      return update(
        state, {
          asyncData: { $set: {} },
          asyncStatus: { $set: HUBS_PROGRESS },
        },
      );
    }
    case HUBS_GET_ASYNC_DATA_SUCCESS: {
      return update(
        state, {
          asyncData: { $set: action.payload },
          asyncStatus: { $set: HUBS_SUCCESS },
        },
      );
    }
    case HUBS_GET_ASYNC_DATA_FAILED: {
      return update(
        state, {
          asyncData: { $set: {} },
          asyncStatus: { $set: HUBS_FAILED },
        },
      );
    }
    case HUBS_CLEAR_STATE: {
      return update(
        state,
        {
          loadListStatus: { $set: '' },
          loadActiveStatus: { $set: '' },
          submitStatus: { $set: '' },
          asyncStatus: { $set: '' },
          list: { $set: [] },
          pagination: { $set: {} },
          active: { $set: {} },
          asyncData: { $set: {} },
        },
      );
    }
    case HUBS_CLEAR_FLASH_MESSAGE: {
      return update(
        state, {
          flash: { $set: '' },
        },
      );
    }
    default:
      return state;
  }
}
