import { startSubmit, stopSubmit } from 'redux-form';
import ws from '../../ws';
import getToken from './getToken';

const WEBINARS_PROGRESS = 'progress';
const WEBINARS_SUCCESS = 'success';
const WEBINARS_FAILED = 'failed';

const WEBINARS_GET_LIST_PROGRESS = 'WEBINARS_GET_LIST_PROGRESS';
const WEBINARS_GET_LIST_SUCCESS = 'WEBINARS_GET_LIST_SUCCESS';
const WEBINARS_GET_LIST_FAILED = 'WEBINARS_GET_LIST_FAILED';
const WEBINARS_FORM_SUBMIT_PROGRESS = 'WEBINARS_FORM_SUBMIT_PROGRESS';
const WEBINARS_FORM_SUBMIT_SUCCESS = 'WEBINARS_FORM_SUBMIT_SUCCESS';
const WEBINARS_FORM_SUBMIT_FAILED = 'WEBINARS_FORM_SUBMIT_FAILED';
const WEBINARS_GET_WEBINAR_PROGRESS = 'WEBINARS_GET_WEBINAR_PROGRESS';
const WEBINARS_GET_WEBINAR_SUCCESS = 'WEBINARS_GET_WEBINAR_SUCCESS';
const WEBINARS_GET_WEBINAR_FAILED = 'WEBINARS_GET_WEBINAR_FAILED';
const WEBINARS_CLEAR_STATE = 'WEBINARS_CLEAR_STATE';

const WEBINARS_CLEAR_FLASH_MESSAGE = 'WEBINARS_CLEAR_FLASH_MESSAGE';

const webinarGetListProgress = () => ({
  type: WEBINARS_GET_LIST_PROGRESS,
});

const webinarGetListSuccess = payload => ({
  type: WEBINARS_GET_LIST_SUCCESS,
  payload,
});

const webinarGetListFailed = () => ({
  type: WEBINARS_GET_LIST_FAILED,
});

const webinarStartFormSubmit = () => ({
  type: WEBINARS_FORM_SUBMIT_PROGRESS,
});

const webinarFormSubmitSuccess = payload => ({
  type: WEBINARS_FORM_SUBMIT_SUCCESS,
  payload,
});

const webinarFormSubmitFailed = () => ({
  type: WEBINARS_FORM_SUBMIT_FAILED,
});

const webinarGetWebinarProgress = () => ({
  type: WEBINARS_GET_WEBINAR_PROGRESS,
});

const webinarGetWebinarSuccess = payload => ({
  type: WEBINARS_GET_WEBINAR_SUCCESS,
  payload,
});

const webinarGetWebinarFailed = () => ({
  type: WEBINARS_GET_WEBINAR_FAILED,
});

const webinarClearState = () => ({
  type: WEBINARS_CLEAR_STATE,
});

const getWebinarsList = () => async (dispatch) => {
  const token = getToken();
  dispatch(webinarGetListProgress());

  ws.emit('api/academy/webinars/list', { token }, (data) => {
    const { status, payload } = data;
    let action;

    if (status === 'ok') {
      action = webinarGetListSuccess(payload);
    } else {
      action = webinarGetListFailed();
    }

    dispatch(action);
  });
};

const convertSetWebinarToDB = (data) => {
  const {
    name, stream, start, end,
  } = data;

  return {
    name,
    date: {
      start: start.toUTCString(),
      end: end.toUTCString(),
    },
    stream,
    isChat: Object.prototype.hasOwnProperty.call(data, 'isChat') ? data.isChat : false,
  };
};

const createWebinar = async (data, dispatch, props) => {
  const token = getToken();
  const { form, reset } = props;
  const convertedData = convertSetWebinarToDB(data);

  dispatch(startSubmit(form));
  dispatch(webinarStartFormSubmit());

  ws.emit('api/academy/webinars/create', {
    // payload: convertedData, token,
    token, payload: { convertedData },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      reset();
      dispatch(stopSubmit(form));
      dispatch(webinarFormSubmitSuccess('Новый вебинар был успешно создан.'));
      props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(webinarFormSubmitFailed());
    }
  });
};

const convertDBWebinarToEdit = (payload) => {
  const { webinar: webinarFromDB } = payload;

  const webinar = {
    id: webinarFromDB.id,
    name: webinarFromDB.name,
    stream: webinarFromDB.stream,
    start: new Date(webinarFromDB.date.start),
    end: new Date(webinarFromDB.date.end),
    isChat: webinarFromDB.isChat,
  };

  return { webinar };
};

const getWebinar = webinarId => async (dispatch) => {
  const token = getToken();
  dispatch(webinarGetWebinarProgress());

  ws.emit('api/academy/webinars/getById', {
    // webinarId, token,
    token, payload: { webinarId },
  }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = webinarGetWebinarSuccess(convertDBWebinarToEdit(payload));
    } else {
      action = webinarGetWebinarFailed();
    }

    dispatch(action);
  });
};

const updateWebinar = async (data, dispatch, props) => {
  const token = getToken();
  const { id } = data;
  const { form } = props;
  const convertedData = convertSetWebinarToDB(data);

  dispatch(startSubmit(form));
  dispatch(webinarStartFormSubmit());

  ws.emit('api/academy/webinars/updateById', {
    // webinarId: id, payload: convertedData, token,
    token, payload: { webinarId: id, convertedData },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(stopSubmit(form));
      dispatch(webinarFormSubmitSuccess('Вебинар был успешно отредактирован.'));
      props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(webinarFormSubmitFailed());
    }
  });
};

const webinarClearFlashMessage = () => ({
  type: WEBINARS_CLEAR_FLASH_MESSAGE,
});

export {
  WEBINARS_PROGRESS,
  WEBINARS_SUCCESS,
  WEBINARS_FAILED,
  WEBINARS_GET_LIST_PROGRESS,
  WEBINARS_GET_LIST_SUCCESS,
  WEBINARS_GET_LIST_FAILED,
  WEBINARS_FORM_SUBMIT_PROGRESS,
  WEBINARS_FORM_SUBMIT_SUCCESS,
  WEBINARS_FORM_SUBMIT_FAILED,
  WEBINARS_GET_WEBINAR_PROGRESS,
  WEBINARS_GET_WEBINAR_SUCCESS,
  WEBINARS_GET_WEBINAR_FAILED,
  WEBINARS_CLEAR_STATE,
  WEBINARS_CLEAR_FLASH_MESSAGE,
  webinarGetListProgress,
  webinarGetListSuccess,
  webinarGetListFailed,
  webinarStartFormSubmit,
  webinarFormSubmitSuccess,
  webinarFormSubmitFailed,
  webinarGetWebinarProgress,
  webinarGetWebinarSuccess,
  webinarGetWebinarFailed,
  webinarClearState,
  getWebinarsList,
  createWebinar,
  getWebinar,
  updateWebinar,
  webinarClearFlashMessage,
};
