import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { FieldArray, reduxForm, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/ru';
import RenderThemes from '../../components/RenderThemes';
import store from '../../../App/store';
import validate from '../../validate';

class WizardFormFour extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    asyncData: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor() {
    super();
    this.state = {
      flowThemes: [],
      dates: [],
      packages: [],
      dataComplete: false,
    };
  }

  componentDidMount() {
    const morph = (day, f1, f2, f5) => {
      const n = Math.abs(day) % 100;
      const n1 = n % 10;
      if (n > 10 && n < 20) return f5;
      if (n1 > 1 && n1 < 5) return f2;
      if (n1 === 1) return f1;
      return f5;
    };

    const selector = formValueSelector('flows_edit_form');
    const {
      course, dateBegin, packages, themes, courseType,
    } = selector(store.getState(), 'course', 'dateBegin', 'packages', 'themes', 'courseType');
    const { themes: courseThemes } = course;
    const { asyncData } = this.props;
    const { themes: allThemes } = asyncData;
    const flowThemes = [];

    for (let i = 0; i < courseThemes.length; i += 1) {
      for (let j = 0; j < allThemes.length; j += 1) {
        if (courseThemes[i] === allThemes[j].value) {
          flowThemes.push(allThemes[j]);
          break;
        }
      }
    }

    moment.locale('ru');
    const startDate = moment(dateBegin);
    const dates = [{ value: '-1', label: 'Доступна сразу после покупки' }];
    dates.push({ value: '0', label: 'Доступна в день старта' });

    for (let i = 1; i <= 200; i += 1) {
      const day = startDate.add(1, 'days').format('dddd, D MMMM YYYY');
      const dayStr = courseType.value === 'flow'
        ? `через ${i} ${morph(i, 'день', 'дня', 'дней')} (${day})`
        : `через ${i} ${morph(i, 'день', 'дня', 'дней')}`;

      dates.push({ value: String(i), label: dayStr });
    }

    if (themes) {
      for (let i = 0; i < themes.length; i += 1) {
        const { day } = themes[i];

        if (day) {
          for (let j = 0; j < dates.length; j += 1) {
            if (day.value === dates[j].value) {
              themes[i].day = { value: day.value, label: dates[j].label };
              break;
            }
          }
        }
      }
    }

    this.setState({
      flowThemes, dates, packages, dataComplete: true,
    });
  }

  render() {
    const { handleSubmit, previousPage } = this.props;
    const {
      flowThemes, dates, packages, dataComplete,
    } = this.state;

    return (
      <form className="form form--horizontal wizard__form wizard__form__lg" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Настройте темы курса</h3>
        {dataComplete && (
        <FieldArray name="themes" component={RenderThemes} props={{ options: { flowThemes, dates, packages } }} />
        )}
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={previousPage}>Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next">Следующий шаг</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'flows_edit_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: false,
  validate,
})(WizardFormFour);
