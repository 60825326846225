import moment from 'moment';
import { startSubmit, stopSubmit } from 'redux-form';
import ws from '../../ws';
import getToken from './getToken';
import PaginationPageConverter from '../../shared/classes/PaginationPageConverter';

const HUBS_PROGRESS = 'progress';
const HUBS_SUCCESS = 'success';
const HUBS_FAILED = 'failed';

const HUBS_GET_LIST_PROGRESS = 'HUBS_GET_LIST_PROGRESS';
const HUBS_GET_LIST_SUCCESS = 'HUBS_GET_LIST_SUCCESS';
const HUBS_GET_LIST_FAILED = 'HUBS_GET_LIST_FAILED';
const HUBS_FORM_SUBMIT_PROGRESS = 'HUBS_FORM_SUBMIT_PROGRESS';
const HUBS_FORM_SUBMIT_SUCCESS = 'HUBS_FORM_SUBMIT_SUCCESS';
const HUBS_FORM_SUBMIT_FAILED = 'HUBS_FORM_SUBMIT_FAILED';
const HUBS_GET_HUB_PROGRESS = 'HUBS_GET_HUB_PROGRESS';
const HUBS_GET_HUB_SUCCESS = 'HUBS_GET_HUB_SUCCESS';
const HUBS_GET_HUB_FAILED = 'HUBS_GET_HUB_FAILED';
const HUBS_GET_ASYNC_DATA_PROGRESS = 'HUBS_GET_ASYNC_DATA_PROGRESS';
const HUBS_GET_ASYNC_DATA_SUCCESS = 'HUBS_GET_ASYNC_DATA_SUCCESS';
const HUBS_GET_ASYNC_DATA_FAILED = 'HUBS_GET_ASYNC_DATA_FAILED';
const HUBS_CLEAR_STATE = 'HUBS_CLEAR_STATE';

const HUBS_CLEAR_FLASH_MESSAGE = 'HUBS_CLEAR_FLASH_MESSAGE';

const hubGetListProgress = () => ({
  type: HUBS_GET_LIST_PROGRESS,
});

const hubGetListSuccess = payload => ({
  type: HUBS_GET_LIST_SUCCESS,
  payload,
});

const hubGetListFailed = () => ({
  type: HUBS_GET_LIST_FAILED,
});

const hubStartFormSubmit = () => ({
  type: HUBS_FORM_SUBMIT_PROGRESS,
});

const hubFormSubmitSuccess = payload => ({
  type: HUBS_FORM_SUBMIT_SUCCESS,
  payload,
});

const hubFormSubmitFailed = () => ({
  type: HUBS_FORM_SUBMIT_FAILED,
});

const hubGetHubProgress = () => ({
  type: HUBS_GET_HUB_PROGRESS,
});

const hubGetHubSuccess = payload => ({
  type: HUBS_GET_HUB_SUCCESS,
  payload,
});

const hubGetHubFailed = () => ({
  type: HUBS_GET_HUB_FAILED,
});

const hubGetAsyncDataProgress = () => ({
  type: HUBS_GET_ASYNC_DATA_PROGRESS,
});

const hubGetAsyncDataSuccess = payload => ({
  type: HUBS_GET_ASYNC_DATA_SUCCESS,
  payload,
});

const hubGetAsyncDataFailed = () => ({
  type: HUBS_GET_ASYNC_DATA_FAILED,
});

const hubClearState = () => ({
  type: HUBS_CLEAR_STATE,
});

const convertListToTable = data => new PaginationPageConverter(data, doc => ({
  id: doc.id,
  name: doc.name,
  amoId: doc.amoId,
  createdAt: moment(doc.createdAt).locale('ru').format('DD MMM YY г., HH:mm'),
})).getConvertedData();

const getHubsList = params => (dispatch) => {
  const token = getToken();
  dispatch(hubGetListProgress());

  ws.emit('api/academy/hubs/list', { token, payload: params }, (data) => {
    const { status, payload } = data;
    let action;

    if (status === 'ok') {
      action = hubGetListSuccess(convertListToTable(payload.data));
    } else {
      action = hubGetListFailed();
    }

    dispatch(action);
  });
};

const convertSetHubToDB = (data) => {
  const { name, amoId, flows: flowList } = data;

  return {
    name,
    amoId,
    flows: flowList.map((item) => {
      const { value, selected } = item.flow;
      const packages = [];

      if (selected) {
        const keys = Object.keys(selected);

        for (let i = 0; i < keys.length; i += 1) {
          if (selected[keys[i]]) {
            packages.push(keys[i]);
          }
        }
      }

      return { flow: value, packages };
    }),
  };
};

const createHub = async (data, dispatch, props) => {
  const token = getToken();
  const { form, reset } = props;
  const convertedData = convertSetHubToDB(data);

  dispatch(startSubmit(form));
  dispatch(hubStartFormSubmit());

  ws.emit('api/academy/hubs/create', {
    // payload: convertedData, token,
    token, payload: { convertedData },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      reset();
      dispatch(stopSubmit(form));
      dispatch(hubFormSubmitSuccess('Новый хаб был успешно создан.'));
      props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(hubFormSubmitFailed());
    }
  });
};

const getFlowName = flow => `${flow.type === 'course' ? 'Курс' : 'Марафон'}: ${flow.name}`
  + ` (${flow.courseType === 'flow' ? 'поток' : 'индивидуальный'}`
  + `${flow.startDate !== null ? ` - дата старта ${flow.startDate}` : ''})`;

const convertDBHubToEdit = (payload) => {
  const { hub: hubFromDB } = payload;

  const hub = {
    id: hubFromDB.id,
    name: hubFromDB.name,
    amoId: hubFromDB.amoId,
    flows: hubFromDB.flows.map(flow => ({
      flow: {
        value: flow.id,
        label: getFlowName(flow),
        checkboxes: flow.packages,
        selected: flow.selected,
      },
    })),
  };

  return { hub };
};

const getHub = hubId => async (dispatch) => {
  const token = getToken();
  dispatch(hubGetHubProgress());

  ws.emit('api/academy/hubs/getById', {
    // hubId, token,
    token, payload: { hubId },
  }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = hubGetHubSuccess(convertDBHubToEdit(payload));
    } else {
      action = hubGetHubFailed();
    }

    dispatch(action);
  });
};

const updateHub = async (data, dispatch, props) => {
  const token = getToken();
  const { id } = data;
  const { form } = props;
  const convertedData = convertSetHubToDB(data);

  dispatch(startSubmit(form));
  dispatch(hubStartFormSubmit());

  ws.emit('api/academy/hubs/updateById', {
    // hubId: id, payload: convertedData, token,
    token, payload: { hubId: id, convertedData },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(stopSubmit(form));
      dispatch(hubFormSubmitSuccess('Хаб был успешно отредактирован.'));
      props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(hubFormSubmitFailed());
    }
  });
};

const transformAsyncData = data => ({
  flows: data.flows.map(task => ({ value: task.id, label: getFlowName(task), checkboxes: task.packages })),
});

const getAsyncData = () => async (dispatch) => {
  const token = getToken();
  dispatch(hubGetAsyncDataProgress());

  ws.emit('api/academy/hubs/getAsyncData', { token }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = hubGetAsyncDataSuccess(transformAsyncData(payload));
    } else {
      action = hubGetAsyncDataFailed();
    }

    dispatch(action);
  });
};

const hubClearFlashMessage = () => ({
  type: HUBS_CLEAR_FLASH_MESSAGE,
});

export {
  HUBS_PROGRESS,
  HUBS_SUCCESS,
  HUBS_FAILED,
  HUBS_GET_LIST_PROGRESS,
  HUBS_GET_LIST_SUCCESS,
  HUBS_GET_LIST_FAILED,
  HUBS_FORM_SUBMIT_PROGRESS,
  HUBS_FORM_SUBMIT_SUCCESS,
  HUBS_FORM_SUBMIT_FAILED,
  HUBS_GET_HUB_PROGRESS,
  HUBS_GET_HUB_SUCCESS,
  HUBS_GET_HUB_FAILED,
  HUBS_GET_ASYNC_DATA_PROGRESS,
  HUBS_GET_ASYNC_DATA_SUCCESS,
  HUBS_GET_ASYNC_DATA_FAILED,
  HUBS_CLEAR_STATE,
  HUBS_CLEAR_FLASH_MESSAGE,
  hubGetListProgress,
  hubGetListSuccess,
  hubGetListFailed,
  hubStartFormSubmit,
  hubFormSubmitSuccess,
  hubFormSubmitFailed,
  hubGetHubProgress,
  hubGetHubSuccess,
  hubGetHubFailed,
  hubGetAsyncDataProgress,
  hubGetAsyncDataSuccess,
  hubGetAsyncDataFailed,
  hubClearState,
  getHubsList,
  createHub,
  getHub,
  updateHub,
  getAsyncData,
  hubClearFlashMessage,
};
