import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';

import QuestionTablePage from '../../../Questions/QuestionTable/index';
import QuestionCreatePage from '../../../Questions/QuestionCreate/index';
import QuestionEditPage from '../../../Questions/QuestionEdit/index';
import QuestionStatisticsPage from '../../../Questions/QuestionStatistics/index';

export default () => (
  <Switch>
    <ProtectedRoute path="/questions" exact component={QuestionTablePage} />
    <ProtectedRoute path="/questions/create" component={QuestionCreatePage} />
    <ProtectedRoute path="/questions/statistics" component={QuestionStatisticsPage} />
    <ProtectedRoute path="/questions/:questionId" exact component={QuestionEditPage} />
  </Switch>
);
