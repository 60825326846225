import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import {
  Button, ButtonToolbar, Card, CardBody, Col, Row, Badge,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field, reduxForm, formValueSelector, reset,
} from 'redux-form';
import {
  USERS_SUCCESS,
  getUsersList as getUsersListAction,
  userClearState as userClearStateAction,
  userClearFlashMessage as userClearFlashMessageAction,
} from '../../../../redux/actions/usersAction';
import renderField from '../../../../shared/components/form/TextField';
import renderSelectField from '../../../../shared/components/form/Select';
import DataTable from '../../../../shared/components/data-table';
import { COMPLETED, WAIT } from '../../../../shared/components/data-table/submitStatuses';
import { DATA, ACTION } from '../../../../shared/components/data-table/columnTypes';
import { LINK } from '../../../../shared/components/data-table/buttonTypes';
import { FullWideNotification } from '../../../../shared/components/Notification';
import store from '../../../App/store';

const UserTable = ({
  getUsersList,
  userClearState,
  userClearFlashMessage,
  showNotification,
  usersList,
  pagination,
  loadStatus,
  flashMessage,
  resetForm,
}) => {
  const [data, setData] = useState({
    columns: [],
    buttons: [],
    userRoles: [],
    isFind: false,
    status: WAIT,
  });

  const clearFilters = () => {
    resetForm(UserTable.FORM_NAME);
    setData({ ...data, isFind: true });
  };

  const getConditions = () => {
    const selector = formValueSelector(UserTable.FORM_NAME);
    return selector(store.getState(), 'email', 'phone', 'name', 'leadId', 'contactID', 'role');
  };

  const handleFind = () => {
    setData({ ...data, isFind: true });
  };

  const show = (color, message) => showNotification({
    notification: <FullWideNotification
      color={color}
      message={message}
    />,
    position: 'full',
  });

  useEffect(() => {
    if (loadStatus === USERS_SUCCESS) {
      setData({ ...data, isFind: false });
    }
  }, [loadStatus]);

  useEffect(() => {
    if (flashMessage && flashMessage.length) {
      setTimeout(() => {
        show('success', flashMessage);
        userClearFlashMessage();
      }, 1000);
    }
  }, [flashMessage]);

  useEffect(() => {
    const userRoles = [{
      value: '1',
      label: 'Ученик',
    }, {
      value: '2',
      label: 'Администратор',
    }, {
      value: '3',
      label: 'Психолог',
    }];

    const columns = [{
      label: '#',
      dbColumn: 'createdAt',
      listColumn: 'number',
      sortable: true,
      width: 5,
      type: DATA,
    }, {
      label: 'Email',
      dbColumn: 'email',
      listColumn: 'email',
      sortable: false,
      width: 23,
      type: DATA,
    }, {
      label: 'Имя',
      dbColumn: 'name',
      listColumn: 'name',
      sortable: true,
      width: 17,
      type: DATA,
    }, {
      label: 'Роль',
      dbColumn: 'roles',
      listColumn: 'roles',
      sortable: false,
      width: 15,
      type: DATA,
    }, {
      label: 'Статус',
      dbColumn: 'isActive',
      listColumn: 'isActive',
      sortable: true,
      width: 13,
      handle: status => (status
        ? <Badge color="success">Активный</Badge>
        : <Badge color="danger">Неактивный</Badge>),
      type: DATA,
    }, {
      label: 'Создан',
      dbColumn: 'createdAt',
      listColumn: 'createdAt',
      sortable: true,
      width: 17,
      type: DATA,
    }, {
      label: 'Действия',
      dbColumn: '_id',
      listColumn: 'id',
      sortable: false,
      width: 10,
      type: ACTION,
    }];

    const buttons = [{
      name: 'edit',
      color: 'primary',
      icon: 'edit',
      type: LINK,
      link: '/users/',
      text: 'Редактировать',
    }, {
      name: 'prolongation',
      color: 'primary',
      icon: 'calendar',
      type: LINK,
      link: '/prolongation/',
      text: 'Продлить доступ',
    }];

    setData(({
      ...data, columns, buttons, userRoles, status: COMPLETED,
    }));

    return () => {
      userClearState();
    };
  }, []);

  return (
    <Fragment>
      {data.status === COMPLETED && (
        <Fragment>
          <Row>
            <Col md={12} lg={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Поиск по пользователям</h5>
                  </div>
                  <form className="form gap-20">
                    <Col className="p-0">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Email</span>
                        <div className="form__form-group-field">
                          <Field
                            name="email"
                            component={renderField}
                            type="text"
                            placeholder="Email пользователя"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="p-0">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Телефон</span>
                        <div className="form__form-group-field">
                          <Field
                            name="phone"
                            component={renderField}
                            type="text"
                            placeholder="Телефон пользователя"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="p-0">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Имя</span>
                        <div className="form__form-group-field">
                          <Field
                            name="name"
                            component={renderField}
                            type="text"
                            placeholder="Имя пользователя"
                          />
                        </div>
                      </div>
                    </Col>
                  </form>
                  <form className="form gap-20">
                    <Col className="p-0">
                      <div className="form__form-group">
                        <span className="form__form-group-label">ID сделки</span>
                        <div className="form__form-group-field">
                          <Field
                            name="leadId"
                            component={renderField}
                            type="text"
                            placeholder="ID сделки"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="p-0">
                      <div className="form__form-group">
                        <span className="form__form-group-label">ID контакта</span>
                        <div className="form__form-group-field">
                          <Field
                            name="contactID"
                            component={renderField}
                            type="text"
                            placeholder="ID контакта"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="p-0">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Роль</span>
                        <div className="form__form-group-field">
                          <Field
                            name="role"
                            component={renderSelectField}
                            placeholder="Выберите роль"
                            options={data.userRoles}
                          />
                        </div>
                      </div>
                    </Col>
                  </form>
                  <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                    <Button color="primary" type="button" onClick={handleFind}>Найти</Button>
                    <Button color="secondary" type="button" onClick={clearFilters}>Очистить</Button>
                  </ButtonToolbar>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <DataTable
                addButton={{ to: '/users/create', caption: 'Добавить пользователя' }}
                columns={data.columns}
                buttons={data.buttons}
                getConditions={getConditions}
                getList={getUsersList}
                list={usersList}
                pagination={pagination}
                isFind={data.isFind}
                loadListStatus={loadStatus}
                declensions={['пользователя', 'пользователей', 'пользователей']}
              />
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

UserTable.FORM_NAME = 'users_table';

UserTable.propTypes = {
  getUsersList: PropTypes.func.isRequired,
  userClearState: PropTypes.func.isRequired,
  userClearFlashMessage: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  usersList: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.any),
  ).isRequired,
  // eslint-disable-next-line react/require-default-props
  pagination: PropTypes.objectOf(PropTypes.any),
  loadStatus: PropTypes.string.isRequired,
  flashMessage: PropTypes.string.isRequired,
  resetForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  usersList: state.users.list,
  pagination: state.users.pagination,
  loadStatus: state.users.loadListStatus,
  flashMessage: state.users.flash,
});

const mapDispatchToProps = dispatch => ({
  getUsersList: params => dispatch(getUsersListAction(params)),
  userClearState: () => dispatch(userClearStateAction()),
  userClearFlashMessage: () => dispatch(userClearFlashMessageAction()),
  resetForm: formName => dispatch(reset(formName)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(withRouter(UserTable)));

export default reduxForm({
  form: UserTable.FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(withConnect);
