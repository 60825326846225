import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Row, Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  Table,
  ButtonToolbar,
  UncontrolledTooltip,
} from 'reactstrap';
import PropTypes from 'prop-types';
import SettingsIcon from 'mdi-react/SettingsIcon';
import EditIcon from 'mdi-react/EditIcon';
import KeyIcon from 'mdi-react/KeyIcon';
import CalendarIcon from 'mdi-react/CalendarIcon';
import ExternalLinkIcon from 'mdi-react/ExternalLinkIcon';
import ChevronDoubleLeftIcon from 'mdi-react/ChevronDoubleLeftIcon';
import ChevronDoubleRightIcon from 'mdi-react/ChevronDoubleRightIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import Select from 'react-select';
import perPageList from './perPageList';
import { AUTH_SUCCESS as SUCCESS } from '../../../redux/types/authTypes';
import { COMPLETED, WAIT } from './submitStatuses';
import { DATA, ACTION } from './columnTypes';
import { LINK, HANDLE, OPEN_WINDOW } from './buttonTypes';
import METHOD_CHANGE_STATUS from './buttonMethods';

const DataTable = ({
  addButton,
  perPage,
  columns,
  buttons,
  sortedColumn,
  orderBy,
  currentPage,
  getConditions,
  getList,
  list,
  pagination,
  isFind,
  loadListStatus,
  declensions,
  isShowAction,
}) => {
  const [data, setData] = useState({
    perPage: perPageList.find(item => item.value === perPage),
    columns,
    buttons,
    sortedColumn,
    orderBy,
    currentPage,
    list: [],
    pagination: {},
    status: COMPLETED,
    declensions,
    isShowAction,
    confirm: {
      isOpen: false,
      text: '',
      handle: () => {},
    },
  });

  const handleSelectChange = name => (selected) => {
    setData({
      ...data, [name]: selected, currentPage: 1, status: WAIT,
    });
  };

  const getData = () => {
    const searchParams = getConditions();
    const searchParamsKeys = Object.keys(searchParams);
    const conditions = {};

    const getConditionValue = (value) => {
      let newValue = value;

      if (value === null) {
        newValue = null;
      } else if (Array.isArray(value)) {
        newValue = value.map(item => item.value);
      } else if (typeof value === 'object') {
        newValue = value.value;
      }

      return newValue;
    };

    // eslint-disable-next-line no-return-assign
    searchParamsKeys.forEach(key => conditions[key] = getConditionValue(searchParams[key]));

    const params = {
      page: data.currentPage,
      column: data.columns[data.sortedColumn].dbColumn,
      order: data.orderBy,
      limit: data.perPage.value,
      conditions,
    };

    getList(params);
  };

  const sortByColumn = (column, sortable) => {
    if (sortable) {
      // eslint-disable-next-line no-shadow
      let { orderBy, sortedColumn } = data;

      if (column === sortedColumn) {
        orderBy = orderBy === 'asc' ? 'desc' : 'asc';
      } else {
        sortedColumn = column;
        orderBy = 'asc';
      }

      setData({
        ...data, sortedColumn, orderBy, status: WAIT,
      });
    }
  };

  const nextPage = (page) => {
    if (page !== null) {
      setData({ ...data, currentPage: page, status: WAIT });
    }
  };

  const getPaginationPages = (current, total) => {
    const pages = [];
    const pageLimit = 7;
    let upperLimit = Math.min(current, total);
    let lowerLimit = upperLimit;

    for (let i = 1; i < pageLimit && i < total;) {
      if (lowerLimit > 1) {
        lowerLimit -= 1;
        i += 1;
      }

      if (i < pageLimit && upperLimit < total) {
        upperLimit += 1;
        i += 1;
      }
    }

    for (let i = lowerLimit; i <= upperLimit; i += 1) {
      pages.push({ page: i });
    }

    return pages;
  };

  const morph = () => {
    const n = Math.abs(data.pagination ? data.pagination.totalDocs : 0) % 100;
    const n1 = n % 10;
    let value;

    if (n > 10 && n < 20) {
      // eslint-disable-next-line prefer-destructuring
      value = data.declensions[2];
    } else if (n1 > 1 && n1 < 5) {
      // eslint-disable-next-line prefer-destructuring
      value = data.declensions[1];
    } else if (n1 === 1) {
      // eslint-disable-next-line prefer-destructuring
      value = data.declensions[0];
    } else {
      // eslint-disable-next-line prefer-destructuring
      value = data.declensions[2];
    }

    return value;
  };

  const getIcon = (icon) => {
    let mdiIcon;

    switch (icon) {
      case 'edit':
        mdiIcon = <EditIcon />;
        break;
      case 'settings':
        mdiIcon = <SettingsIcon />;
        break;
      case 'calendar':
        mdiIcon = <CalendarIcon />;
        break;
      case 'key':
        mdiIcon = <KeyIcon />;
        break;
      case 'link':
        mdiIcon = <ExternalLinkIcon />;
        break;
      default:
        mdiIcon = <EditIcon />;
        break;
    }

    return mdiIcon;
  };

  const getSortableClass = (index, column) => {
    let className = '';

    if (index === column.sortedColumn && column.orderBy === 'desc') {
      className = 'desc';
    } else if (index === column.sortedColumn && column.orderBy === 'asc') {
      className = 'asc';
    }

    return className;
  };

  const confirmClose = () => {
    setData({
      ...data,
      confirm: {
        isOpen: false,
        text: '',
        handle: () => {},
      },
    });
  };

  const changeStatus = (item, button) => {
    const { params } = button;
    // const { field, tooltip, text } = params;
    const { field, text } = params;
    const status = item[field];

    const confirmOpen = () => {
      setData({
        ...data,
        confirm: {
          isOpen: true,
          text: status ? text.up : text.down,
          handle: () => Promise.resolve(!status)
            .then(nextValue => button.handle(item.id, nextValue))
            .then((newValue) => {
              const { list: dataList } = data;
              const listIndex = dataList.findIndex(listItem => listItem.id === item.id);

              if (listIndex !== -1) {
                dataList[listIndex][field] = newValue;

                setData({
                  ...data,
                  list: dataList,
                  confirm: {
                    isOpen: false,
                    text: '',
                    handle: () => {},
                  },
                });
              }
            })
            .catch(() => {}),
        },
      });
    };

    return (
      <Fragment>
        <Button
          className="mr-2 btn-icon btn-icon-only btn-pill"
          size="sm"
          outline
          color={status ? 'success' : 'danger'}
          id={`button-${button.name}-${item.id}`}
          onClick={confirmOpen}
        >
          <i className={`${status ? 'pe-7s-angle-up-circle' : 'pe-7s-close-circle'} btn-icon-wrapper`} />
        </Button>
      </Fragment>
    );
  };

  useEffect(() => {
    if (loadListStatus === SUCCESS) {
      setData({
        ...data, list, pagination, status: COMPLETED,
      });
    }
  }, [loadListStatus]);

  useEffect(() => {
    if (data.status === WAIT) {
      getData();
    }
  }, [data.status]);

  useEffect(() => {
    if (isFind) {
      setData({ ...data, currentPage: 1, status: WAIT });
    }
  }, [isFind]);

  useEffect(() => {
    setData({ ...data, status: WAIT });
  }, []);

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col md={3}>
              <Form>
                <FormGroup>
                  <Select
                    value={data.perPage}
                    name="perPage"
                    id="fieldPerPage"
                    options={perPageList}
                    onChange={handleSelectChange('perPage')}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Form>
            </Col>
            <Col md={9} className="text-md-right">
              {addButton !== null ? (
                <Link to={addButton.to}>
                  <Button className="btn-icon" color="success" size="sm">
                    <i className="pe-7s-plus btn-icon-wrapper" />
                    {addButton.caption}
                  </Button>
                </Link>
              ) : null}
            </Col>
          </Row>
          <Row className="p-2">
            <Table className="data-grid" hover striped responsive>
              <thead>
                <tr>
                  {data.columns.map((column, index) => (
                    <Fragment key={index}>
                      {column.type === DATA ? (
                        <th
                          style={{ width: `${column.width}%` }}
                          className={column.sortable ? 'sortable' : ''}
                          onClick={() => sortByColumn(index, column.sortable)}
                        >
                          {column.label}
                          {column.sortable && (
                            <span className={`col-sort ${getSortableClass(index, data)}`} />
                          )}
                        </th>
                      ) : null}
                      {column.type === ACTION && data.isShowAction ? (
                        <th style={{ width: `${column.width}%` }}>
                          {column.label}
                        </th>
                      ) : null}
                    </Fragment>
                  ))}
                </tr>
              </thead>
              {data.list.length && loadListStatus === SUCCESS ? (
                <tbody>
                  {data.list.map(item => (
                    <tr key={item.id}>
                      {data.columns.map((column, index) => (
                        <Fragment key={index}>
                          {column.type === DATA ? (
                            <td>
                              {/* eslint-disable-next-line no-prototype-builtins */}
                              {column.hasOwnProperty('handle') ? (
                                column.handle(item[column.listColumn])
                              ) : (
                                item[column.listColumn]
                              )}
                            </td>
                          ) : null}
                          {column.type === ACTION && data.isShowAction ? (
                            <td className="actions">
                              {data.buttons.map((button, dataIndex) => (
                                <Fragment key={dataIndex}>
                                  {button.type === LINK ? (
                                    <Fragment>
                                      <Link to={`${button.link}${item.id}`}>
                                        <ButtonToolbar className="btn-toolbar--center">
                                          <Button
                                            className="icon m-0"
                                            size="sm"
                                            outline
                                            color={button.color}
                                            id={`button-${button.name}-${item.id}`}
                                          >
                                            {getIcon(button.icon)}
                                          </Button>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target={`button-${button.name}-${item.id}`}
                                          >
                                            {button.text}
                                          </UncontrolledTooltip>
                                        </ButtonToolbar>
                                      </Link>
                                    </Fragment>
                                  ) : null}
                                  {button.type === HANDLE && button.method === METHOD_CHANGE_STATUS ? (
                                    <Fragment>
                                      {changeStatus(item, button)}
                                    </Fragment>
                                  ) : null}
                                  {button.type === OPEN_WINDOW ? (
                                    <Fragment>
                                      {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                      <a href={button.handle(item)} target="_blank">
                                        <ButtonToolbar className="btn-toolbar--center">
                                          <Button
                                            className="icon m-0"
                                            size="sm"
                                            outline
                                            color={button.color}
                                            id={`button-${button.name}-${item.id}`}
                                          >
                                            {getIcon(button.icon)}
                                          </Button>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target={`button-${button.name}-${item.id}`}
                                          >
                                            {button.text}
                                          </UncontrolledTooltip>
                                        </ButtonToolbar>
                                      </a>
                                    </Fragment>
                                  ) : null}
                                </Fragment>
                              ))}
                            </td>
                          ) : null}
                        </Fragment>
                      ))}
                    </tr>
                  ))}
                </tbody>
              ) : null}
              {!data.list.length && loadListStatus === SUCCESS ? (
                <tbody>
                  <tr>
                    <td colSpan={data.columns.length} className="text-center">
                      Не найдено ни одной записи
                    </td>
                  </tr>
                </tbody>
              ) : null}
            </Table>
          </Row>
          <Row className="mt-3 pagination">
            <Col md={6} className="p-0">
              <p>Отображается страница {data.pagination.page} с {data.pagination.pagingCounter} {' '}
              по {data.pagination.pagingCounter + data.pagination.docsNumber - 1} {' '}
                из всего {data.pagination.totalDocs} {morph()}.
              </p>
            </Col>
            <Col md={6}>
              <Pagination className="float-right">
                <PaginationItem
                  disabled={data.pagination.page === 1}
                  onClick={() => (data.pagination.page !== 1 ? nextPage(1) : null)}
                >
                  <PaginationLink>
                    <ChevronDoubleLeftIcon size="16" />
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem
                  disabled={!data.pagination.hasPrevPage}
                  onClick={() => (data.pagination.prevPage ? nextPage(data.pagination.prevPage) : null)}
                >
                  <PaginationLink>
                    <ChevronLeftIcon size="16" />
                  </PaginationLink>
                </PaginationItem>
                {getPaginationPages(data.pagination.page, data.pagination.totalPages).map((page, index) => (
                  <PaginationItem
                    key={index}
                    active={page.page === data.pagination.page}
                    onClick={() => nextPage(page.page)}
                  >
                    <PaginationLink>{page.page}</PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem
                  disabled={!data.pagination.hasNextPage}
                  onClick={() => (data.pagination.hasNextPage ? nextPage(data.pagination.nextPage) : null)}
                >
                  <PaginationLink>
                    <ChevronRightIcon size="16" />
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem
                  disabled={data.pagination.totalPages === data.currentPage}
                  onClick={() => (data.pagination.page !== data.pagination.totalPages
                    ? nextPage(data.pagination.totalPages)
                    : null)
                  }
                >
                  <PaginationLink>
                    <ChevronDoubleRightIcon size="16" />
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Modal isOpen={data.confirm.isOpen} toggle={confirmClose}>
        <ModalHeader toggle={confirmClose}>Подтвердите</ModalHeader>
        <ModalBody>
          {data.confirm.text}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={confirmClose}>
            Отменить
          </Button>
          <Button color="primary" onClick={data.confirm.handle}>
            Подтверждаю
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

DataTable.defaultProps = {
  addButton: null,
  perPage: 10,
  buttons: [],
  sortedColumn: 0,
  orderBy: 'desc',
  currentPage: 1,
  isFind: false,
  declensions: ['запись', 'записи', 'записей'],
  isShowAction: true,
};

DataTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  addButton: PropTypes.object,
  perPage: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  buttons: PropTypes.array,
  sortedColumn: PropTypes.number,
  orderBy: PropTypes.string,
  currentPage: PropTypes.number,
  getConditions: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  list: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pagination: PropTypes.object.isRequired,
  isFind: PropTypes.bool,
  loadListStatus: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  declensions: PropTypes.array,
  isShowAction: PropTypes.bool,
};

export default DataTable;
