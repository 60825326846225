import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { Col, Card, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { destroy } from 'redux-form';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  MARAPHON_FLOWS_SUCCESS, MARAPHON_FLOWS_FAILED,
  maraphonFlowClearState as maraphonFlowClearStateAction, getAsyncData as getAsyncDataAction,
  getMaraphonFlow as getMaraphonFlowAction,
} from '../../../../redux/actions/maraphonFlowsAction';
import WizardFormOne from './WizardFormOne';
import WizardFormTwo from './WizardFormTwo';
import WizardFormThree from './WizardFormThree';
import WizardFormFour from './WizardFormFour';
import WizardFormFive from './WizardFormFive';
import { FullWideNotification } from '../../../../shared/components/Notification';

class WizardForm extends PureComponent {
  static propTypes = {
    initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    asyncData: PropTypes.objectOf(PropTypes.any).isRequired,
    asyncStatus: PropTypes.string.isRequired,
    submitStatus: PropTypes.string.isRequired,
    loadStatus: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    getAsyncData: PropTypes.func.isRequired,
    maraphonFlowClearState: PropTypes.func.isRequired,
    getMaraphonFlow: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      page: 1,
    };
  }

  componentDidMount() {
    const {
      // eslint-disable-next-line react/prop-types
      getAsyncData, getMaraphonFlow, match, reinitializeReduxForm,
    } = this.props;
    const { flowId } = match.params;

    reinitializeReduxForm();

    getAsyncData();
    getMaraphonFlow(flowId);
  }

  componentWillReceiveProps(nextProps) {
    const { submitStatus, loadStatus, history } = nextProps;

    if (loadStatus === MARAPHON_FLOWS_FAILED) {
      history.push('/404');
    }

    if (submitStatus === MARAPHON_FLOWS_SUCCESS) {
      history.push('/maraphon_flows');
    } else if (submitStatus === MARAPHON_FLOWS_FAILED) {
      this.show('danger', 'Произошла непредвиденная ошибка. Обратитесь к разработчикам.');
    }
  }

  componentWillUnmount() {
    const { maraphonFlowClearState } = this.props;
    maraphonFlowClearState();
  }

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message={message}
      />,
      position: 'full',
    });
  };

  nextPage = () => {
    this.setState(prevState => ({ page: prevState.page + 1 }));
  };

  previousPage = () => {
    this.setState(prevState => ({ page: prevState.page - 1 }));
  };

  render() {
    const {
      asyncData, asyncStatus, loadStatus, initialValues,
    } = this.props; // showNotification
    const { page } = this.state;

    return (
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <div className="wizard">
              <div className="wizard__steps">
                <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}><p>Шаг 1</p></div>
                <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}><p>Шаг 2</p></div>
                <div className={`wizard__step${page === 3 ? ' wizard__step--active' : ''}`}><p>Шаг 3</p></div>
                <div className={`wizard__step${page === 4 ? ' wizard__step--active' : ''}`}><p>Шаг 4</p></div>
                <div className={`wizard__step${page === 5 ? ' wizard__step--active' : ''}`}><p>Шаг 5</p></div>
              </div>
              {(asyncStatus === MARAPHON_FLOWS_SUCCESS && loadStatus === MARAPHON_FLOWS_SUCCESS)
              && (
              <div className="wizard__form-wrapper">
                {page === 1
                && (
                  <WizardFormOne
                    onSubmit={this.nextPage}
                    asyncData={asyncData}
                    initialValues={initialValues}
                  />
                )}
                {page === 2
                && (
                  <WizardFormTwo
                    previousPage={this.previousPage}
                    onSubmit={this.nextPage}
                    asyncData={asyncData}
                  />
                )}
                {page === 3
                && (
                  <WizardFormThree
                    previousPage={this.previousPage}
                    onSubmit={this.nextPage}
                    asyncData={asyncData}
                  />
                )}
                {page === 4
                && (
                  <WizardFormFour
                    previousPage={this.previousPage}
                    onSubmit={this.nextPage}
                    asyncData={asyncData}
                  />
                )}
                {page === 5
                && (
                  <WizardFormFive
                    previousPage={this.previousPage}
                  />
                )}
              </div>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: state.maraphonFlows.active,
  asyncData: state.maraphonFlows.asyncData,
  asyncStatus: state.maraphonFlows.asyncStatus,
  submitStatus: state.maraphonFlows.submitStatus,
  loadStatus: state.maraphonFlows.loadActiveStatus,
});

const mapDispatchToProps = dispatch => ({
  getMaraphonFlow: flowId => dispatch(getMaraphonFlowAction(flowId)),
  getAsyncData: () => dispatch(getAsyncDataAction()),
  maraphonFlowClearState: () => dispatch(maraphonFlowClearStateAction()),
  reinitializeReduxForm: () => dispatch(destroy('maraphon_flows_edit_form')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(withRouter(WizardForm)));
