import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';

import FlowTablePage from '../../../Flows/FlowTable/index';
import FlowCreatePage from '../../../Flows/FlowCreate/index';
import FlowEditPage from '../../../Flows/FlowEdit/index';

export default () => (
  <Switch>
    <ProtectedRoute path="/flows" exact component={FlowTablePage} />
    <ProtectedRoute path="/flows/create" component={FlowCreatePage} />
    <ProtectedRoute path="/flows/:flowId" exact component={FlowEditPage} />
  </Switch>
);
