const validate = (values) => {
  const errors = {};
  const requiredErrorMessage = 'Это поле является обязательным';

  if (!values.question) {
    errors.question = requiredErrorMessage;
  }

  return errors;
};

export default validate;
