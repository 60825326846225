import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarCategory from './SidebarCategory';
import SidebarLink from './SidebarLink';

class SidebarContent extends Component {
  static propTypes = {
    // changeToDark: PropTypes.func.isRequired,
    // changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    // const { changeToLight, changeToDark } = this.props;

    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarCategory title="Курсы школы" icon="graduation-hat">
            <SidebarLink title="Потоки" route="/flows" onClick={this.hideSidebar} />
            <SidebarLink title="Курсы" route="/courses" onClick={this.hideSidebar} />
            <SidebarLink title="Темы" route="/themes" onClick={this.hideSidebar} />
            <SidebarLink title="Материалы" route="/materials" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarCategory title="Марафоны" icon="diamond">
            <SidebarLink title="Потоки" route="/maraphon_flows" onClick={this.hideSidebar} />
            <SidebarLink title="Марафоны" route="/maraphons" onClick={this.hideSidebar} />
            <SidebarLink title="Материалы" route="/maraphon_tasks" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarLink title="Витрина" icon="star" route="/showcases" onClick={this.hideSidebar} />
          <SidebarLink title="Хабы" icon="layers" route="/hubs" onClick={this.hideSidebar} />
          <SidebarLink title="Изображения" icon="picture" route="/images" onClick={this.hideSidebar} />
          <SidebarLink title="Баннеры" icon="bullhorn" route="/banners" onClick={this.hideSidebar} />
          <SidebarCategory title="Пользователи" icon="users">
            <SidebarLink title="Все пользователи" route="/users" onClick={this.hideSidebar} />
            <SidebarLink title="Дубликаты пользователей" route="/duplicates" onClick={this.hideSidebar} />
            <SidebarLink title="Статистика по подпискам" route="/users/statistics" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarCategory title="Вопросы" icon="question-circle">
            <SidebarLink title="Вопросы от учеников" route="/questions" onClick={this.hideSidebar} />
            <SidebarLink title="Статистика по вопросам" route="/questions/statistics" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarLink title="Теги" icon="tag" route="/tags" onClick={this.hideSidebar} />
          <SidebarLink title="Группы полей" icon="keyboard" route="/groups" onClick={this.hideSidebar} />
          {/* <SidebarLink title="Вебинары" icon="envelope" route="/webinars" onClick={this.hideSidebar} /> */}
          <SidebarLink title="Статические страницы" icon="file-empty" route="/pages" onClick={this.hideSidebar} />
          <SidebarLink title="Частые вопросы" icon="bubble" route="/faqs" onClick={this.hideSidebar} />
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
