import moment from 'moment';
import { startSubmit, stopSubmit } from 'redux-form';
import ws from '../../ws';
import getToken from './getToken';
import PaginationPageConverter from '../../shared/classes/PaginationPageConverter';

const IMAGES_PROGRESS = 'progress';
const IMAGES_SUCCESS = 'success';
const IMAGES_FAILED = 'failed';

const IMAGES_GET_LIST_PROGRESS = 'IMAGES_GET_LIST_PROGRESS';
const IMAGES_GET_LIST_SUCCESS = 'IMAGES_GET_LIST_SUCCESS';
const IMAGES_GET_LIST_FAILED = 'IMAGES_GET_LIST_FAILED';
const IMAGES_FORM_SUBMIT_PROGRESS = 'IMAGES_FORM_SUBMIT_PROGRESS';
const IMAGES_FORM_SUBMIT_SUCCESS = 'IMAGES_FORM_SUBMIT_SUCCESS';
const IMAGES_FORM_SUBMIT_FAILED = 'IMAGES_FORM_SUBMIT_FAILED';
const IMAGES_GET_MATERIAL_PROGRESS = 'IMAGES_GET_MATERIAL_PROGRESS';
const IMAGES_GET_MATERIAL_SUCCESS = 'IMAGES_GET_MATERIAL_SUCCESS';
const IMAGES_GET_MATERIAL_FAILED = 'IMAGES_GET_MATERIAL_FAILED';
const IMAGES_CLEAR_STATE = 'IMAGES_CLEAR_STATE';

const IMAGES_CLEAR_FLASH_MESSAGE = 'IMAGES_CLEAR_FLASH_MESSAGE';

const imageGetListProgress = () => ({
  type: IMAGES_GET_LIST_PROGRESS,
});

const imageGetListSuccess = payload => ({
  type: IMAGES_GET_LIST_SUCCESS,
  payload,
});

const imageGetListFailed = () => ({
  type: IMAGES_GET_LIST_FAILED,
});

const imageStartFormSubmit = () => ({
  type: IMAGES_FORM_SUBMIT_PROGRESS,
});

const imageFormSubmitSuccess = payload => ({
  type: IMAGES_FORM_SUBMIT_SUCCESS,
  payload,
});

const imageFormSubmitFailed = () => ({
  type: IMAGES_FORM_SUBMIT_FAILED,
});

const imageGetImageProgress = () => ({
  type: IMAGES_GET_MATERIAL_PROGRESS,
});

const imageGetImageSuccess = payload => ({
  type: IMAGES_GET_MATERIAL_SUCCESS,
  payload,
});

const imageGetImageFailed = () => ({
  type: IMAGES_GET_MATERIAL_FAILED,
});

const imageClearState = () => ({
  type: IMAGES_CLEAR_STATE,
});

const convertListToTable = data => new PaginationPageConverter(data, doc => ({
  id: doc.id,
  name: doc.name,
  image: doc.image,
  createdAt: moment(doc.createdAt).locale('ru').format('DD MMM YY г., HH:mm'),
})).getConvertedData();

const getImagesList = params => (dispatch) => {
  const token = getToken();
  dispatch(imageGetListProgress());

  ws.emit('api/academy/images/list', { token, payload: params }, (data) => {
    const { status, payload } = data;
    let action;

    if (status === 'ok') {
      action = imageGetListSuccess(convertListToTable(payload.data));
    } else {
      action = imageGetListFailed();
    }

    dispatch(action);
  });
};

const convertSetImageToDB = data => ({
  name: data.name,
});

const generateImageObject = async file => new Promise((resolve) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    resolve({ buffer: e.target.result, type: file.type });
  };

  reader.readAsArrayBuffer(file);
});

const createImage = async (data, dispatch, props) => {
  const token = getToken();
  const { form, reset } = props;
  const convertedData = convertSetImageToDB(data);
  const { image } = data;
  const images = {};

  if (image && image.length) {
    images.image = await generateImageObject(image[0]);
  }

  dispatch(startSubmit(form));
  dispatch(imageStartFormSubmit());

  ws.emit('api/academy/images/create', {
    // payload: convertedData, images, token,
    token, payload: { convertedData, images },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      reset();
      dispatch(stopSubmit(form));
      dispatch(imageFormSubmitSuccess('Новое изображение было успешно создано.'));
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(imageFormSubmitFailed());
    }
  });
};

const convertDBImageToEdit = (payload) => {
  const { image: imageFromDB } = payload;

  const image = {
    id: imageFromDB.id,
    name: imageFromDB.name,
    image: !imageFromDB.image ? imageFromDB.image : [{ preview: imageFromDB.image }],
  };

  return { image };
};

const getImage = imageId => async (dispatch) => {
  const token = getToken();
  dispatch(imageGetImageProgress());

  ws.emit('api/academy/images/getById', {
    // imageId, token,
    token, payload: { imageId },
  }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = imageGetImageSuccess(convertDBImageToEdit(payload));
    } else {
      action = imageGetImageFailed();
    }

    dispatch(action);
  });
};

const updateImage = async (data, dispatch, props) => {
  const token = getToken();
  const { id, image } = data;
  const { form } = props;
  const convertedData = convertSetImageToDB(data);
  const images = {};

  const generateImage = async (file) => {
    let result;

    if (file && file.length && file[0] instanceof File) {
      result = await generateImageObject(file[0]);
    } else if (file && file.length && file[0] instanceof Object) {
      result = true;
    } else {
      result = null;
    }

    return result;
  };

  images.image = await generateImage(image);

  dispatch(startSubmit(form));
  dispatch(imageStartFormSubmit());

  ws.emit('api/academy/images/updateById', {
    // imageId: id, payload: convertedData, images, token,
    token, payload: { imageId: id, convertedData, images },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(stopSubmit(form));
      dispatch(imageFormSubmitSuccess('Изображение было успешно отредактировано.'));
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(imageFormSubmitFailed());
    }
  });
};

const imageClearFlashMessage = () => ({
  type: IMAGES_CLEAR_FLASH_MESSAGE,
});

export {
  IMAGES_PROGRESS,
  IMAGES_SUCCESS,
  IMAGES_FAILED,
  IMAGES_GET_LIST_PROGRESS,
  IMAGES_GET_LIST_SUCCESS,
  IMAGES_GET_LIST_FAILED,
  IMAGES_FORM_SUBMIT_PROGRESS,
  IMAGES_FORM_SUBMIT_SUCCESS,
  IMAGES_FORM_SUBMIT_FAILED,
  IMAGES_GET_MATERIAL_PROGRESS,
  IMAGES_GET_MATERIAL_SUCCESS,
  IMAGES_GET_MATERIAL_FAILED,
  IMAGES_CLEAR_STATE,
  IMAGES_CLEAR_FLASH_MESSAGE,
  imageGetListProgress,
  imageGetListSuccess,
  imageGetListFailed,
  imageStartFormSubmit,
  imageFormSubmitSuccess,
  imageFormSubmitFailed,
  imageGetImageProgress,
  imageGetImageSuccess,
  imageGetImageFailed,
  imageClearState,
  getImagesList,
  createImage,
  getImage,
  updateImage,
  imageClearFlashMessage,
};
