import moment from 'moment';
import { startSubmit, stopSubmit } from 'redux-form';
import ws from '../../ws';
import getToken from './getToken';
import PaginationPageConverter from '../../shared/classes/PaginationPageConverter';

const MARAPHONS_PROGRESS = 'progress';
const MARAPHONS_SUCCESS = 'success';
const MARAPHONS_FAILED = 'failed';

const MARAPHONS_GET_LIST_PROGRESS = 'MARAPHONS_GET_LIST_PROGRESS';
const MARAPHONS_GET_LIST_SUCCESS = 'MARAPHONS_GET_LIST_SUCCESS';
const MARAPHONS_GET_LIST_FAILED = 'MARAPHONS_GET_LIST_FAILED';
const MARAPHONS_FORM_SUBMIT_PROGRESS = 'MARAPHONS_FORM_SUBMIT_PROGRESS';
const MARAPHONS_FORM_SUBMIT_SUCCESS = 'MARAPHONS_FORM_SUBMIT_SUCCESS';
const MARAPHONS_FORM_SUBMIT_FAILED = 'MARAPHONS_FORM_SUBMIT_FAILED';
const MARAPHONS_GET_COURSE_PROGRESS = 'MARAPHONS_GET_COURSE_PROGRESS';
const MARAPHONS_GET_COURSE_SUCCESS = 'MARAPHONS_GET_COURSE_SUCCESS';
const MARAPHONS_GET_COURSE_FAILED = 'MARAPHONS_GET_COURSE_FAILED';
const MARAPHONS_GET_ASYNC_DATA_PROGRESS = 'MARAPHONS_GET_ASYNC_DATA_PROGRESS';
const MARAPHONS_GET_ASYNC_DATA_SUCCESS = 'MARAPHONS_GET_ASYNC_DATA_SUCCESS';
const MARAPHONS_GET_ASYNC_DATA_FAILED = 'MARAPHONS_GET_ASYNC_DATA_FAILED';
const MARAPHONS_GET_ASYNC_FORM_DATA_PROGRESS = 'MARAPHONS_GET_ASYNC_FORM_DATA_PROGRESS';
const MARAPHONS_GET_ASYNC_FORM_DATA_SUCCESS = 'MARAPHONS_GET_ASYNC_FORM_DATA_SUCCESS';
const MARAPHONS_GET_ASYNC_FORM_DATA_FAILED = 'MARAPHONS_GET_ASYNC_FORM_DATA_FAILED';
const MARAPHONS_CLEAR_STATE = 'MARAPHONS_CLEAR_STATE';

const MARAPHONS_CLEAR_FLASH_MESSAGE = 'MARAPHONS_CLEAR_FLASH_MESSAGE';

const maraphonGetListProgress = () => ({
  type: MARAPHONS_GET_LIST_PROGRESS,
});

const maraphonGetListSuccess = payload => ({
  type: MARAPHONS_GET_LIST_SUCCESS,
  payload,
});

const maraphonGetListFailed = () => ({
  type: MARAPHONS_GET_LIST_FAILED,
});

const maraphonStartFormSubmit = () => ({
  type: MARAPHONS_FORM_SUBMIT_PROGRESS,
});

const maraphonFormSubmitSuccess = payload => ({
  type: MARAPHONS_FORM_SUBMIT_SUCCESS,
  payload,
});

const maraphonFormSubmitFailed = () => ({
  type: MARAPHONS_FORM_SUBMIT_FAILED,
});

const maraphonGetCourseProgress = () => ({
  type: MARAPHONS_GET_COURSE_PROGRESS,
});

const maraphonGetCourseSuccess = payload => ({
  type: MARAPHONS_GET_COURSE_SUCCESS,
  payload,
});

const maraphonGetCourseFailed = () => ({
  type: MARAPHONS_GET_COURSE_FAILED,
});

const maraphonGetAsyncDataProgress = () => ({
  type: MARAPHONS_GET_ASYNC_DATA_PROGRESS,
});

const maraphonGetAsyncDataSuccess = payload => ({
  type: MARAPHONS_GET_ASYNC_DATA_SUCCESS,
  payload,
});

const maraphonGetAsyncDataFailed = () => ({
  type: MARAPHONS_GET_ASYNC_DATA_FAILED,
});

const courseGetAsyncFormDataProgress = () => ({
  type: MARAPHONS_GET_ASYNC_FORM_DATA_PROGRESS,
});

const courseGetAsyncFormDataSuccess = payload => ({
  type: MARAPHONS_GET_ASYNC_FORM_DATA_SUCCESS,
  payload,
});

const courseGetAsyncFormDataFailed = () => ({
  type: MARAPHONS_GET_ASYNC_FORM_DATA_FAILED,
});

const maraphonClearState = () => ({
  type: MARAPHONS_CLEAR_STATE,
});

const convertListToTable = data => new PaginationPageConverter(data, doc => ({
  id: doc.id,
  name: doc.name,
  direction: doc.direction.name,
  isVisible: doc.isVisible,
  createdAt: moment(doc.createdAt).locale('ru').format('DD MMM YY г., HH:mm'),
})).getConvertedData();

const getMaraphonsList = params => (dispatch) => {
  const token = getToken();
  dispatch(maraphonGetListProgress());

  ws.emit('api/academy/maraphons/list', { token, payload: params }, (data) => {
    const { status, payload } = data;
    let action;

    if (status === 'ok') {
      action = maraphonGetListSuccess(convertListToTable(payload.data));
    } else {
      action = maraphonGetListFailed();
    }

    dispatch(action);
  });
};

const convertSetMaraphonToDB = (data) => {
  const {
    name,
    slug,
    author,
    description,
    landing,
    direction,
    tasks,
    backgroundColor,
    courseNameColor,
    courseAuthorColor,
    courseProgressColor,
    buttonBackgroundColor,
    buttonTextColor,
    buttonBorderColor,
  } = data;

  const tasksArray = [];

  for (let i = 0; i < tasks.length; i += 1) {
    tasksArray.push(tasks[i].task.value);
  }

  const convertedData = {
    name, slug, author, description, landing, tasks: tasksArray,
  };

  convertedData.direction = typeof direction === 'string' ? direction : direction.value;
  convertedData.isVisible = Object.prototype.hasOwnProperty.call(data, 'isVisible') ? data.isVisible : false;

  const colors = {};

  if (backgroundColor) {
    colors.background = typeof backgroundColor === 'string' ? backgroundColor : backgroundColor.hex.substr(1);
  }

  if (courseNameColor) {
    colors.courseName = typeof courseNameColor === 'string' ? courseNameColor : courseNameColor.hex.substr(1);
  }

  if (courseAuthorColor) {
    colors.courseAuthor = typeof courseAuthorColor === 'string' ? courseAuthorColor : courseAuthorColor.hex.substr(1);
  }

  if (courseProgressColor) {
    colors.courseProgress = typeof courseProgressColor === 'string'
      ? courseProgressColor : courseProgressColor.hex.substr(1);
  }

  const button = {};

  if (buttonBackgroundColor) {
    button.background = typeof buttonBackgroundColor === 'string'
      ? buttonBackgroundColor : buttonBackgroundColor.hex.substr(1);
  }

  if (buttonTextColor) {
    button.textColor = typeof buttonTextColor === 'string' ? buttonTextColor : buttonTextColor.hex.substr(1);
  }

  if (buttonBorderColor) {
    button.borderColor = typeof buttonBorderColor === 'string' ? buttonBorderColor : buttonBorderColor.hex.substr(1);
  }

  if (Object.keys(button).length) {
    colors.button = button;
  }

  if (Object.keys(colors).length) {
    convertedData.color = colors;
  }

  return convertedData;
};

const generateImageObject = async file => new Promise((resolve) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    resolve({ buffer: e.target.result, type: file.type });
  };

  reader.readAsArrayBuffer(file);
});

const createMaraphon = async (data, dispatch, props) => {
  const token = getToken();
  const { form, reset } = props;
  const convertedData = convertSetMaraphonToDB(data);
  const { background, layer, preview } = data;
  const images = {};

  if (background && background.length) {
    images.background = await generateImageObject(background[0]);
  }

  if (layer && layer.length) {
    images.layer = await generateImageObject(layer[0]);
  }

  if (preview && preview.length) {
    images.preview = await generateImageObject(preview[0]);
  }

  dispatch(startSubmit(form));
  dispatch(maraphonStartFormSubmit());

  ws.emit('api/academy/maraphons/create', {
    // payload: convertedData, images, token,
    token, payload: { convertedData, images },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      reset();
      dispatch(stopSubmit(form));
      dispatch(maraphonFormSubmitSuccess('Новый марафон был успешно создан.'));
      props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(maraphonFormSubmitFailed());
    }
  });
};

const convertDBMaraphonToEdit = (payload) => {
  const { maraphon: maraphonFromDB } = payload;
  const { image, color } = maraphonFromDB;
  const { button } = color;

  const maraphon = {
    id: maraphonFromDB.id,
    name: maraphonFromDB.name,
    isVisible: maraphonFromDB.isVisible,
    slug: maraphonFromDB.slug,
    author: maraphonFromDB.author,
    description: maraphonFromDB.description,
    landing: maraphonFromDB.landing,
    direction: maraphonFromDB.direction,
    tasks: maraphonFromDB.tasks.map(item => ({ task: { value: item.id, label: item.name } })),
    backgroundColor: color.background,
    courseNameColor: color.courseName,
    courseAuthorColor: color.courseAuthor,
    courseProgressColor: color.courseProgress,
    buttonBackgroundColor: button.background,
    buttonTextColor: button.textColor,
    buttonBorderColor: button.borderColor,
    background: !image.background ? image.background : [{ preview: image.background }],
    layer: !image.layer ? image.layer : [{ preview: image.layer }],
    preview: !image.preview ? image.preview : [{ preview: image.preview }],
  };

  return { maraphon };
};

const getMaraphon = maraphonId => async (dispatch) => {
  const token = getToken();
  dispatch(maraphonGetCourseProgress());

  ws.emit('api/academy/maraphons/getById', {
    // maraphonId, token,
    token, payload: { maraphonId },
  }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = maraphonGetCourseSuccess(convertDBMaraphonToEdit(payload));
    } else {
      action = maraphonGetCourseFailed();
    }

    dispatch(action);
  });
};

const updateMaraphon = async (data, dispatch, props) => {
  const token = getToken();
  const {
    id, background, layer, preview,
  } = data;
  const { form } = props;
  const convertedData = convertSetMaraphonToDB(data);
  const images = {};

  const generateImage = async (file) => {
    let result;

    if (file && file.length && file[0] instanceof File) {
      result = await generateImageObject(file[0]);
    } else if (file && file.length && file[0] instanceof Object) {
      result = true;
    } else {
      result = null;
    }

    return result;
  };

  images.background = await generateImage(background);
  images.layer = await generateImage(layer);
  images.preview = await generateImage(preview);

  dispatch(startSubmit(form));
  dispatch(maraphonStartFormSubmit());

  ws.emit('api/academy/maraphons/updateById', {
    // maraphonId: id, payload: convertedData, images, token,
    token, payload: { maraphonId: id, convertedData, images },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(stopSubmit(form));
      dispatch(maraphonFormSubmitSuccess('Марафон был успешно отредактирован.'));
      props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(maraphonFormSubmitFailed());
    }
  });
};

const transformAsyncData = data => ({
  tasks: data.tasks.map(task => ({ value: task.id, label: task.name })),
  directions: data.directions.map(direction => ({ value: direction.id, label: direction.name })),
});

const getAsyncData = () => async (dispatch) => {
  const token = getToken();
  dispatch(maraphonGetAsyncDataProgress());

  ws.emit('api/academy/maraphons/getAsyncData', { token }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = maraphonGetAsyncDataSuccess(transformAsyncData(payload));
    } else {
      action = maraphonGetAsyncDataFailed();
    }

    dispatch(action);
  });
};

const transformAsyncDataForTable = data => ({
  directions: data.directions.map(direction => ({ value: direction.id, label: direction.name })),
});

const getAsyncDataForTable = () => (dispatch) => {
  const token = getToken();
  dispatch(courseGetAsyncFormDataProgress());

  ws.emit('api/academy/maraphons/getAsyncDataForTable', { token }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = courseGetAsyncFormDataSuccess(transformAsyncDataForTable(payload));
    } else {
      action = courseGetAsyncFormDataFailed();
    }

    dispatch(action);
  });
};

const maraphonClearFlashMessage = () => ({
  type: MARAPHONS_CLEAR_FLASH_MESSAGE,
});

export {
  MARAPHONS_PROGRESS,
  MARAPHONS_SUCCESS,
  MARAPHONS_FAILED,
  MARAPHONS_GET_LIST_PROGRESS,
  MARAPHONS_GET_LIST_SUCCESS,
  MARAPHONS_GET_LIST_FAILED,
  MARAPHONS_FORM_SUBMIT_PROGRESS,
  MARAPHONS_FORM_SUBMIT_SUCCESS,
  MARAPHONS_FORM_SUBMIT_FAILED,
  MARAPHONS_GET_COURSE_PROGRESS,
  MARAPHONS_GET_COURSE_SUCCESS,
  MARAPHONS_GET_COURSE_FAILED,
  MARAPHONS_GET_ASYNC_DATA_PROGRESS,
  MARAPHONS_GET_ASYNC_DATA_SUCCESS,
  MARAPHONS_GET_ASYNC_DATA_FAILED,
  MARAPHONS_GET_ASYNC_FORM_DATA_PROGRESS,
  MARAPHONS_GET_ASYNC_FORM_DATA_SUCCESS,
  MARAPHONS_GET_ASYNC_FORM_DATA_FAILED,
  MARAPHONS_CLEAR_STATE,
  MARAPHONS_CLEAR_FLASH_MESSAGE,
  maraphonGetListProgress,
  maraphonGetListSuccess,
  maraphonGetListFailed,
  maraphonStartFormSubmit,
  maraphonFormSubmitSuccess,
  maraphonFormSubmitFailed,
  maraphonGetCourseProgress,
  maraphonGetCourseSuccess,
  maraphonGetCourseFailed,
  maraphonGetAsyncDataProgress,
  maraphonGetAsyncDataSuccess,
  maraphonGetAsyncDataFailed,
  maraphonClearState,
  getMaraphonsList,
  createMaraphon,
  getMaraphon,
  updateMaraphon,
  getAsyncData,
  getAsyncDataForTable,
  maraphonClearFlashMessage,
};
