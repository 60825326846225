import update from 'immutability-helper';
import {
  PROLONGATION_PROGRESS,
  PROLONGATION_SUCCESS,
  PROLONGATION_FAILED,
  PROLONGATION_FORM_SUBMIT_PROGRESS,
  PROLONGATION_FORM_SUBMIT_SUCCESS,
  PROLONGATION_FORM_SUBMIT_FAILED,
  PROLONGATION_GET_PROLONGATION_PROGRESS,
  PROLONGATION_GET_PROLONGATION_SUCCESS,
  PROLONGATION_GET_PROLONGATION_FAILED,
  PROLONGATION_COURSE_ACTIVATION_PROGRESS,
  PROLONGATION_COURSE_ACTIVATION_SUCCESS,
  PROLONGATION_COURSE_ACTIVATION_FAILED,
  PROLONGATION_CLEAR_STATE,
} from '../actions/prolongationAction';

const initialState = {
  loadActiveStatus: '', // progress/success/failed
  submitStatus: '', // progress/success/failed
  activateStatus: '', // progress/success/failed
  active: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PROLONGATION_FORM_SUBMIT_PROGRESS: {
      return update(
        state,
        { submitStatus: { $set: PROLONGATION_PROGRESS } },
      );
    }
    case PROLONGATION_FORM_SUBMIT_SUCCESS: {
      return update(
        state,
        {
          submitStatus: { $set: PROLONGATION_SUCCESS },
        },
      );
    }
    case PROLONGATION_FORM_SUBMIT_FAILED: {
      return update(
        state,
        { submitStatus: { $set: PROLONGATION_FAILED } },
      );
    }
    case PROLONGATION_GET_PROLONGATION_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: PROLONGATION_PROGRESS },
        },
      );
    }
    case PROLONGATION_GET_PROLONGATION_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.prolongation },
          loadActiveStatus: { $set: PROLONGATION_SUCCESS },
        },
      );
    }
    case PROLONGATION_GET_PROLONGATION_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: PROLONGATION_FAILED },
        },
      );
    }
    case PROLONGATION_COURSE_ACTIVATION_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: PROLONGATION_PROGRESS },
          activateStatus: { $set: PROLONGATION_PROGRESS },
        },
      );
    }
    case PROLONGATION_COURSE_ACTIVATION_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.prolongation },
          loadActiveStatus: { $set: PROLONGATION_SUCCESS },
          activateStatus: { $set: PROLONGATION_SUCCESS },
        },
      );
    }
    case PROLONGATION_COURSE_ACTIVATION_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: PROLONGATION_FAILED },
          activateStatus: { $set: PROLONGATION_FAILED },
        },
      );
    }
    case PROLONGATION_CLEAR_STATE: {
      return update(
        state,
        {
          loadActiveStatus: { $set: '' },
          submitStatus: { $set: '' },
          activateStatus: { $set: '' },
          active: { $set: {} },
        },
      );
    }
    default:
      return state;
  }
}
