import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import store from '../../../App/store';
import validate from '../../validate';

const marginTop20 = { marginTop: '20px' };
const marginBottom20 = { marginBottom: '20px' };

class WizardFormFive extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      packages: [],
      dataComplete: false,
    };
  }

  componentDidMount() {
    const selector = formValueSelector('flows_edit_form');
    const packages = selector(store.getState(), 'packages');

    this.setState({ packages, dataComplete: true });
  }

  render() {
    const { handleSubmit, previousPage } = this.props;
    const { packages, dataComplete } = this.state;

    return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Настройка доступа для домашних заданий</h3>
        <h4 className="bold-text center-block" style={marginBottom20}>
          Выберите пакеты для которых доступны домашние задания
        </h4>
        {dataComplete && (
          packages.map(pack => (
            <div className="form__form-group-field" key={pack.id}>
              <Field
                name={`homeWork[${pack.id}]`}
                component={renderCheckBoxField}
                label={`Доступны для пакета ${pack.name}`}
              />
            </div>
          ))
        )}
        <ButtonToolbar className="form__button-toolbar wizard__toolbar" style={marginTop20}>
          <Button color="primary" type="button" className="previous" onClick={previousPage}>Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next">Следующий шаг</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'flows_edit_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: false,
  validate,
})(WizardFormFive);
