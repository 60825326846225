/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { changeThemeToDark, changeThemeToLight } from '../../redux/actions/themeActions';

class Landing extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  changeToDark = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToLight());
  };

  render() {
    // const { theme } = this.props;

    return <Redirect to="/flows" />;
  }
}

export default connect(state => ({ theme: state.theme }))(Landing);
