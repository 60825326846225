const validate = (values) => {
  const errors = {};
  const requiredErrorMessage = 'Это поле является обязательным';

  if (!values.name) {
    errors.name = requiredErrorMessage;
  } else if (values.name.length < 3) {
    errors.name = 'Введите не менее 3-х символов';
  }

  if (!values.gender) {
    errors.gender = requiredErrorMessage;
  }

  if (!values.phone) {
    errors.phone = requiredErrorMessage;
  }

  if (values.children && values.children.length) {
    const childrenArrayErrors = [];

    values.children.forEach((child, childIndex) => {
      const childrenErrors = {};

      if (!child.name) {
        childrenErrors.name = requiredErrorMessage;
      }

      if (!child.gender) {
        childrenErrors.gender = requiredErrorMessage;
      }

      if (Object.keys(childrenErrors).length) {
        childrenArrayErrors[childIndex] = childrenErrors;
      }
    });

    if (childrenArrayErrors.length) {
      errors.children = childrenArrayErrors;
    }
  }

  return errors;
};

export default validate;
