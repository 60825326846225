import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Card, CardBody, Col, Button, ButtonToolbar, Row,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  BANNERS_SUCCESS, BANNERS_FAILED,
  getBanner as getBannerAction,
  bannerClearState as bannerClearStateAction,
  updateBanner as updateBannerAction,
} from '../../../../redux/actions/bannersAction';
import ButtonToNavigate from '../../../../shared/components/form/ButtonToNavigate';
// import renderField from '../../../../shared/components/form/TextField';
// import renderSelectField from '../../../../shared/components/form/Select';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderDropZoneField from '../../../../shared/components/form/DropZone';
import { FullWideNotification } from '../../../../shared/components/Notification';
import validate from '../../validate';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import renderField from '../../../../shared/components/form/TextField';
import renderSelectField from '../../../../shared/components/form/Select';

class EditForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
    submitStatus: PropTypes.string.isRequired,
    loadStatus: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    getBanner: PropTypes.func.isRequired,
    bannerClearState: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      formName: '',
      showLink: false,
      size: {
        desktop: '',
        mobile: '',
      },
      isLoad: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { getBanner, match } = this.props;
    const { bannerId } = match.params;

    getBanner(bannerId);
  }

  componentWillReceiveProps(nextProps) {
    const {
      submitStatus, loadStatus, history, initialValues,
    } = nextProps;
    const { isLoad } = this.state;

    if (loadStatus === BANNERS_SUCCESS && !isLoad) {
      const { name, useLink, size } = initialValues;
      this.setState({
        formName: name, showLink: useLink, size, isLoad: true,
      });
    }

    if (loadStatus === BANNERS_FAILED) {
      history.push('/404');
    }

    if (submitStatus === BANNERS_SUCCESS) {
      history.push('/banners');
    } else if (submitStatus === BANNERS_FAILED) {
      this.show('danger', 'Произошла непредвиденная ошибка. Обратитесь к разработчикам.');
    }
  }

  componentWillUnmount() {
    const { bannerClearState } = this.props;
    bannerClearState();
  }

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message={message}
      />,
      position: 'full',
    });
  };

  handleChange = (e, newValue) => {
    const { state } = this;
    this.setState({ ...state, showLink: newValue });
  };

  render() {
    const {
      handleSubmit, submitting, t, loadStatus, showNotification,
    } = this.props;
    const { showLink, formName, size } = this.state;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">{formName}</h5>
              <h5 className="subhead">{t('banners.forms.create_form_description')}</h5>
            </div>
            {loadStatus === BANNERS_SUCCESS ? (
              <form className="form" onSubmit={handleSubmit}>
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      name="isVisible"
                      component={renderCheckBoxField}
                      label="Отображать баннер"
                    />
                  </div>
                </div>
                <Row className="w-100">
                  <Col md={6} sm={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Начать показ, с</span>
                      <div className="form__form-group-field">
                        <Field
                          name="start"
                          component={renderDatePickerField}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6} sm={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Окончить показ, включительно</span>
                      <div className="form__form-group-field">
                        <Field
                          name="end"
                          component={renderDatePickerField}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      name="useLink"
                      component={renderCheckBoxField}
                      label="Использовать баннер как ссылку"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                {showLink && (
                  <Row className="w-100">
                    <Col md={6} sm={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Ссылка</span>
                        <div className="form__form-group-field">
                          <Field
                            name="link"
                            component={renderField}
                            type="text"
                            placeholder="Ссылка"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md={6} sm={12}>
                      <div className="form__form-group form__form-select">
                        <span className="form__form-group-label">Открывать</span>
                        <div className="form__form-group-field">
                          <Field
                            name="target"
                            component={renderSelectField}
                            placeholder="Открывать"
                            options={[
                              { value: '_self', label: 'В текущем окне' },
                              { value: '_blank', label: 'В новом окне' },
                            ]}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Баннер для компьютера, размер изображения {size.desktop} пикселей, формат jpg
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="desktop"
                      component={renderDropZoneField}
                      showNotification={showNotification}
                      mimes={['image/jpeg', 'image/png']}
                      maxFilesize={2097152} /* 2Mb */
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Баннер для мобильного, размер изображения {size.mobile} пикселей, формат jpg
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="mobile"
                      component={renderDropZoneField}
                      showNotification={showNotification}
                      mimes={['image/jpeg', 'image/png']}
                      maxFilesize={2097152} /* 2Mb */
                    />
                  </div>
                </div>
                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit" disabled={submitting}>Редактировать</Button>
                  <Route
                    path="/"
                    render={props => <ButtonToNavigate {...props} title="Отмена" path="/banners" />}
                  />
                </ButtonToolbar>
              </form>
            ) : null}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: state.banners.active,
  submitStatus: state.banners.submitStatus,
  loadStatus: state.banners.loadActiveStatus,
});

const mapDispatchToProps = dispatch => ({
  getBanner: bannerId => dispatch(getBannerAction(bannerId)),
  bannerClearState: () => dispatch(bannerClearStateAction()),
});

const EditFormComponent = reduxForm({
  form: 'banners_edit_form', // a unique identifier for this form
  validate,
  enableReinitialize: true,
  onSubmit: updateBannerAction,
})(withTranslation('common')(withRouter(EditForm)));

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditFormComponent);
