export default class UrlValidator {
  static isValidUrl(url) {
    try {
      // eslint-disable-next-line no-new
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  }
}
