const validate = (values) => {
  const errors = {};
  const requiredErrorMessage = 'Это поле является обязательным';

  if (!values.name) {
    errors.name = requiredErrorMessage;
  } else if (values.name.length < 3) {
    errors.name = 'Введите не менее 3-х символов';
  }

  if (!values.course) {
    errors.course = requiredErrorMessage;
  }

  if (!values.courseType) {
    errors.courseType = requiredErrorMessage;
  }

  if (!values.courseType) {
    errors.courseType = requiredErrorMessage;
  } else if (values.courseType.value === 'flow') {
    if (!values.dateBegin) {
      errors.dateBegin = requiredErrorMessage;
    }

    if (!values.dateEnd) {
      errors.dateEnd = requiredErrorMessage;
    } else if (values.dateBegin && values.dateEnd.getTime() < values.dateBegin.getTime()) {
      errors.dateEnd = 'Дата окончания не может быть меньше даты старта';
    }

    if (!values.dateAvailable) {
      errors.dateAvailable = requiredErrorMessage;
    } else if (values.dateEnd && values.dateAvailable.getTime() < values.dateEnd.getTime()) {
      errors.dateAvailable = 'Дата доступа не может быть меньше даты окончания';
    }
  } else if (values.courseType.value === 'instant') {
    const moreThanZeroMessage = 'Введите число больше нуля';
    const tooManyMessage = 'Вы ввели слишком большое число';

    if (!values.ending) {
      errors.ending = requiredErrorMessage;
    } else if (values.ending <= 0) {
      errors.ending = moreThanZeroMessage;
    } else if (values.ending >= 10000) {
      errors.ending = tooManyMessage;
    } else if (values.days && (Number(values.days) < Number(values.ending))) {
      errors.ending = 'Это число должно быть меньше даты доступа к курсу';
    }

    if (!values.days) {
      errors.days = requiredErrorMessage;
    } else if (values.days <= 0) {
      errors.days = moreThanZeroMessage;
    } else if (values.days >= 10000) {
      errors.days = tooManyMessage;
    }
  }

  if (!values.packages || !values.packages.length) {
    errors.packages = { _error: 'Поток должен содержать хотя бы один пакет' };
  } else {
    const packageArrayErrors = [];
    const amoArray = [];

    values.packages.forEach((pack, packageIndex) => {
      const packageErrors = {};

      if (typeof pack.name === 'undefined') {
        packageErrors.name = requiredErrorMessage;
      }

      if (typeof pack.amoId === 'undefined') {
        packageErrors.amoId = requiredErrorMessage;
      } else if (amoArray.indexOf(pack.amoId) !== -1) {
        packageErrors.amoId = 'Этот номер AMO ID был введен ранее';
      } else {
        amoArray.push(pack.amoId);
      }

      if (pack.bonuses && pack.bonuses.length) {
        const checklistBonusesArrayErrors = [];

        pack.bonuses.forEach((bonus, bonusIndex) => {
          if (typeof bonus.bonus === 'undefined') {
            checklistBonusesArrayErrors[bonusIndex] = { bonus: requiredErrorMessage };
          }
        });

        if (checklistBonusesArrayErrors.length) {
          packageErrors.bonuses = checklistBonusesArrayErrors;
          packageArrayErrors[packageIndex] = packageErrors;
        }
      } else if (Object.keys(packageErrors)) {
        packageArrayErrors[packageIndex] = packageErrors;
      }
    });

    if (packageArrayErrors.length) {
      errors.packages = packageArrayErrors;
    }
  }

  if (values.curators && values.curators.length) {
    const curatorArrayErrors = [];

    values.curators.forEach((curator, curatorIndex) => {
      const curatorErrors = {};

      if (typeof curator.curator === 'undefined') {
        curatorErrors.curator = requiredErrorMessage;
        curatorArrayErrors[curatorIndex] = curatorErrors;
      }
    });

    if (curatorArrayErrors.length) {
      errors.curators = curatorArrayErrors;
    }
  }

  if (values.themes && values.themes.length) {
    const themeArrayErrors = [];

    values.themes.forEach((theme, themeIndex) => {
      const themeErrors = {};

      if (typeof theme.day === 'undefined') {
        themeErrors.day = requiredErrorMessage;
        themeArrayErrors[themeIndex] = themeErrors;
      }
    });

    if (themeArrayErrors.length) {
      errors.themes = themeArrayErrors;
    }
  }

  if (typeof values.materials === 'object') {
    const materialObjectErrors = {};
    const keys = Object.keys(values.materials);

    for (let i = 0; i < keys.length; i += 1) {
      const materialArrayErrors = [];

      values.materials[keys[i]].forEach((material, materialIndex) => {
        const materialErrors = {};

        if (typeof material.importance === 'undefined') {
          materialErrors.importance = requiredErrorMessage;
          materialArrayErrors[materialIndex] = materialErrors;
        }
      });

      if (materialArrayErrors.length) {
        materialObjectErrors[keys[i]] = materialArrayErrors;
      }
    }

    if (Object.keys(materialObjectErrors).length) {
      errors.materials = materialObjectErrors;
    }
  }

  return errors;
};

export default validate;
