import { startSubmit, stopSubmit } from 'redux-form';
import { userFormSubmitSuccess } from './usersAction';
import ws from '../../ws';
import getToken from './getToken';

const PROLONGATION_PROGRESS = 'progress';
const PROLONGATION_SUCCESS = 'success';
const PROLONGATION_FAILED = 'failed';

const PROLONGATION_FORM_SUBMIT_PROGRESS = 'PROLONGATION_FORM_SUBMIT_PROGRESS';
const PROLONGATION_FORM_SUBMIT_SUCCESS = 'PROLONGATION_FORM_SUBMIT_SUCCESS';
const PROLONGATION_FORM_SUBMIT_FAILED = 'PROLONGATION_FORM_SUBMIT_FAILED';
const PROLONGATION_GET_PROLONGATION_PROGRESS = 'PROLONGATION_GET_PROLONGATION_PROGRESS';
const PROLONGATION_GET_PROLONGATION_SUCCESS = 'PROLONGATION_GET_PROLONGATION_SUCCESS';
const PROLONGATION_GET_PROLONGATION_FAILED = 'PROLONGATION_GET_PROLONGATION_FAILED';
const PROLONGATION_COURSE_ACTIVATION_PROGRESS = 'PROLONGATION_COURSE_ACTIVATION_PROGRESS';
const PROLONGATION_COURSE_ACTIVATION_SUCCESS = 'PROLONGATION_COURSE_ACTIVATION_SUCCESS';
const PROLONGATION_COURSE_ACTIVATION_FAILED = 'PROLONGATION_COURSE_ACTIVATION_FAILED';
const PROLONGATION_CLEAR_STATE = 'PROLONGATION_CLEAR_STATE';

const prolongationStartFormSubmit = () => ({
  type: PROLONGATION_FORM_SUBMIT_PROGRESS,
});

const prolongationFormSubmitSuccess = () => ({
  type: PROLONGATION_FORM_SUBMIT_SUCCESS,
});

const prolongationFormSubmitFailed = () => ({
  type: PROLONGATION_FORM_SUBMIT_FAILED,
});

const prolongationGetAccessProgress = () => ({
  type: PROLONGATION_GET_PROLONGATION_PROGRESS,
});

const prolongationGetAccessSuccess = payload => ({
  type: PROLONGATION_GET_PROLONGATION_SUCCESS,
  payload,
});

const prolongationGetAccessFailed = () => ({
  type: PROLONGATION_GET_PROLONGATION_FAILED,
});

const prolongationCourseActivationProgress = () => ({
  type: PROLONGATION_COURSE_ACTIVATION_PROGRESS,
});

const prolongationCourseActivationSuccess = payload => ({
  type: PROLONGATION_COURSE_ACTIVATION_SUCCESS,
  payload,
});

const prolongationCourseActivationFailed = () => ({
  type: PROLONGATION_COURSE_ACTIVATION_FAILED,
});

const prolongationClearState = () => ({
  type: PROLONGATION_CLEAR_STATE,
});

const convertSetProlongationToDB = (data) => {
  const prolongation = data.prolongation.map(item => ({
    flow: item.id,
    adminAvailableDate: item.adminAvailableDate !== null ? item.adminAvailableDate.toUTCString() : null,
    adminQuestionDate: item.adminQuestionDate !== null ? item.adminQuestionDate.toUTCString() : null,
  }));

  return { prolongation };
};

const convertDBProlongationToEdit = payload => ({
  prolongation: {
    id: payload.id,
    prolongation: payload.prolongation.map((item) => {
      const data = item;

      data.adminAvailableDate = item.adminAvailableDate !== null ? new Date(item.adminAvailableDate) : null;
      data.adminQuestionDate = item.adminQuestionDate !== null ? new Date(item.adminQuestionDate) : null;

      return data;
    }),
  },
});

const getProlongation = userId => async (dispatch) => {
  const token = getToken();
  dispatch(prolongationGetAccessProgress());

  ws.emit('api/academy/prolongation/getById', {
    // userId, token,
    token, payload: { userId },
  }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = prolongationGetAccessSuccess(convertDBProlongationToEdit(payload));
    } else {
      action = prolongationGetAccessFailed();
    }

    dispatch(action);
  });
};

const updateProlongation = async (data, dispatch, props) => {
  const token = getToken();
  const { id } = data;
  const { form } = props;
  const convertedData = convertSetProlongationToDB(data);

  dispatch(startSubmit(form));
  dispatch(prolongationStartFormSubmit());

  ws.emit('api/academy/prolongation/updateById', {
    // userId: id, payload: convertedData, token,
    token, payload: { userId: id, convertedData },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(stopSubmit(form));
      dispatch(userFormSubmitSuccess('Данные о курсах были успешно отредактированы.'));
      dispatch(prolongationFormSubmitSuccess());
      props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(prolongationFormSubmitFailed());
    }
  });
};

const changeProlongation = (userId, flowId, adminAvailableDate, adminQuestionDate) => new Promise((resolve, reject) => {
  const token = getToken();
  const data = {
    userId,
    flowId,
    adminAvailableDate: adminAvailableDate !== null ? adminAvailableDate.toUTCString() : null,
    adminQuestionDate: adminQuestionDate !== null ? adminQuestionDate.toUTCString() : null,
  };

  ws.emit('api/academy/prolongation/updateFlowById', { token, payload: data }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      resolve();
    } else {
      reject();
    }
  });
});

const activateCourse = (userId, flowId) => (dispatch) => {
  const token = getToken();
  dispatch(prolongationCourseActivationProgress());
  console.log(userId);
  console.log(flowId);
  ws.emit('api/academy/prolongation/activateCourse', { token, payload: { userId, flowId } }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = prolongationCourseActivationSuccess(convertDBProlongationToEdit(payload));
    } else {
      action = prolongationCourseActivationFailed();
    }

    dispatch(action);
  });
};

export {
  PROLONGATION_PROGRESS,
  PROLONGATION_SUCCESS,
  PROLONGATION_FAILED,
  PROLONGATION_FORM_SUBMIT_PROGRESS,
  PROLONGATION_FORM_SUBMIT_SUCCESS,
  PROLONGATION_FORM_SUBMIT_FAILED,
  PROLONGATION_GET_PROLONGATION_PROGRESS,
  PROLONGATION_GET_PROLONGATION_SUCCESS,
  PROLONGATION_GET_PROLONGATION_FAILED,
  PROLONGATION_COURSE_ACTIVATION_PROGRESS,
  PROLONGATION_COURSE_ACTIVATION_SUCCESS,
  PROLONGATION_COURSE_ACTIVATION_FAILED,
  PROLONGATION_CLEAR_STATE,
  prolongationStartFormSubmit,
  prolongationFormSubmitSuccess,
  prolongationFormSubmitFailed,
  prolongationGetAccessProgress,
  prolongationGetAccessSuccess,
  prolongationGetAccessFailed,
  prolongationCourseActivationProgress,
  prolongationCourseActivationSuccess,
  prolongationCourseActivationFailed,
  prolongationClearState,
  getProlongation,
  updateProlongation,
  changeProlongation,
  activateCourse,
};
