import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import {
  Card, CardBody, Col, Row, Button, ButtonToolbar,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  DUPLICATES_SUCCESS, DUPLICATES_FAILED,
  getDuplicate as getDuplicateAction,
  updateDuplicate as updateDuplicateAction,
  deleteDuplicate as deleteDuplicateAction,
  duplicateClearState as duplicateClearStateAction,
} from '../../../../redux/actions/duplicatesAction';
import Modal from '../../../../shared/components/Modal';
import Collapse from '../../../../shared/components/Collapse';
import { BasicNotification } from '../../../../shared/components/Notification';
import 'moment/locale/ru';
import './styles.css';

class EditForm extends Component {
  static propTypes = {
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    active: PropTypes.objectOf(PropTypes.any).isRequired,
    loadStatus: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    getDuplicate: PropTypes.func.isRequired,
    duplicateClearState: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      duplicates: [],
      values: [],
      errors: [],
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { getDuplicate, match } = this.props;
    const { email } = match.params;

    getDuplicate(decodeURIComponent(email));
  }

  componentWillReceiveProps(nextProps) {
    const { loadStatus, history, active } = nextProps;

    if (loadStatus === DUPLICATES_FAILED) {
      history.push('/404');
    } else if (loadStatus === DUPLICATES_SUCCESS) {
      const { isLoad } = this.state;

      if (!isLoad) {
        const { duplicates } = active;

        this.setState({
          isLoad: true,
          duplicates: duplicates.map(duplicate => ({ ...duplicate, isDeleted: false })),
          values: duplicates.map(duplicate => duplicate.amoContactId.toString()),
          errors: duplicates.map(() => ''),
        });
      }
    }
  }

  componentWillUnmount() {
    const { duplicateClearState } = this.props;
    duplicateClearState();
  }

  handleChange = index => (event) => {
    const { values, errors } = this.state;
    values[index] = event.target.value;

    if (values[index].length) {
      errors[index] = '';
    }

    this.setState({ values, errors });
  };

  handleUpdate = (index) => {
    const { values, duplicates, errors } = this.state;

    if (!values[index].length) {
      errors[index] = 'Это поле является обязательным';
      this.setState({ errors });
    } else {
      const { userId } = duplicates[index];

      updateDuplicateAction(userId, values[index])
        .then(() => {
          duplicates[index].amoContactId = values[index];
          this.setState({ duplicates });
          this.show('success', `Идентификатор пользователя AmoCRM был успешно обновлен на ${values[index]}!`);
        })
        .catch(() => {
          this.show(
            'danger',
            'Не удалось обновить идентификатор пользователя AmoCRM. Обратитесь к разработчикам.',
          );
        });
    }
  };

  handleDelete = (index) => {
    const { duplicates } = this.state;
    const { id } = duplicates[index];

    deleteDuplicateAction(id)
      .then(() => {
        duplicates[index].isDeleted = true;
        this.setState({ duplicates });
        this.show('success', 'Кабинет пользователя был успешно удален!');
      })
      .catch(() => {
        this.show(
          'danger',
          'Не удалось удалить дубликат. Обратитесь к разработчикам.',
        );
      });
  };

  showDate = date => moment(date).locale('ru').format('LLL');

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <BasicNotification
        color={color}
        message={message}
      />,
      position: 'right-up',
    });
  };

  render() {
    const {
      isLoad, duplicates, values, errors,
    } = this.state;
    const colWidth = duplicates.length === 2 ? 6 : 4;

    return (
      <Row className="form w-100">
        {isLoad ? (
          <Fragment>
            {duplicates.map((duplicate, index) => (
              <Col key={duplicate.id} md={12} lg={colWidth} xl={colWidth}>
                <Card>
                  <CardBody style={duplicate.isDeleted ? { background: '#ffdada' } : {}}>
                    <div className="card__title card__title-mb2">
                      <h5 className="bold-text">Кабинет пользователя #{index + 1}</h5>
                      <Modal
                        color="danger"
                        title="Вы действительно хотите удалить этот кабинет?"
                        header
                        btn="Удалить"
                        btnDisabled={duplicate.isDeleted}
                        btnSuccess="Удалить"
                        message="Если вы удалите кабинет пользователя, то все данные связанные с этим пользователем,
                        такие как вопросы пользователя, ответы психолога, статистика прохождения курсов так же будут
                        удалены!"
                        success={() => this.handleDelete(index)}
                      />
                    </div>
                    <div className="subhead subhead--mb10">
                      Идентификатор кабинета {duplicate.id}
                    </div>
                    <div className="dashboard__booking-reservations">
                      <p className="dashboard__booking-reservations-title">Дата создания кабинета</p>
                      <p className="dashboard__booking-reservations-number">
                        {this.showDate(duplicate.createdAt)}
                      </p>
                    </div>
                    <h5 className="bold-text dashboard__product-title">Информация о пользователе</h5>
                    <h6 className="dashboard__product-id">Идентификатор пользователя {duplicate.userId}</h6>
                    <div className="dashboard__user">
                      <div className="dashboard__user-info">
                        <p><span>Имя:</span> {duplicate.name}</p>
                        <p><span>E-mail:</span> {duplicate.email}</p>
                        <p><span>Телефон:</span> {duplicate.phone}</p>
                        <p><span>ID пользователя в AmoCRM:</span> {duplicate.amoContactId}</p>
                      </div>
                      <div className="dashboard__user-button">
                        <a
                          className="btn btn-primary"
                          href={`https://karpachoff.amocrm.ru/contacts/detail/${duplicate.amoContactId}`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Открыть кабинет <br /> пользователя в AmoCRM
                        </a>
                      </div>
                    </div>
                    <div className="dashboard__code">
                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <input
                            type="number"
                            name={`code_${duplicate.userId}`}
                            placeholder="ID пользователя в AmoCRM"
                            value={values[index]}
                            onChange={this.handleChange(index)}
                          />
                        </div>
                        {errors[index] ? (<div className="form__form-group-error">{errors[index]}</div>) : null}
                      </div>
                      <ButtonToolbar className="form__button-toolbar">
                        <Button
                          color="success"
                          onClick={() => this.handleUpdate(index)}
                          disabled={duplicate.isDeleted}
                        >
                          Изменить
                        </Button>
                      </ButtonToolbar>
                    </div>
                    <h5 className="bold-text dashboard__product-title mb-2">Информация о сделках</h5>
                    {duplicate.orders.map(order => (
                      <Collapse
                        key={`key_${order.amoLeadId}`}
                        title={`Сделка # ${order.amoLeadId}`}
                        className="with-shadow"
                      >
                        <Fragment>
                          <div className="dashboard__user">
                            <div className="dashboard__user-info">
                              <p><span>Номер сделки:</span> {order.amoLeadId}</p>
                              <p><span>Дата создания:</span> {this.showDate(order.createdAt)}</p>
                            </div>
                            <div className="dashboard__user-button">
                              <a
                                className="btn btn-primary"
                                href={`https://karpachoff.amocrm.ru/leads/detail/${order.amoLeadId}`}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                Открыть в AmoCRM
                              </a>
                            </div>
                          </div>
                          {order.flows.map((flow, flowIndex) => (
                            <p key={flow.id} className="flow"><span>Курс {flowIndex + 1}:</span> {flow.name}</p>
                          ))}
                        </Fragment>
                      </Collapse>
                    ))}
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Fragment>
        ) : null}
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  active: state.duplicates.active,
  loadStatus: state.duplicates.loadActiveStatus,
});

const mapDispatchToProps = dispatch => ({
  getDuplicate: email => dispatch(getDuplicateAction(email)),
  duplicateClearState: () => dispatch(duplicateClearStateAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(withRouter(EditForm)));
