import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { FieldArray, reduxForm, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import RenderModules from '../../components/RenderModules';
import { updateMaraphonFlow as updateMaraphonFlowAction } from '../../../../redux/actions/maraphonFlowsAction';
import store from '../../../App/store';
import validate from '../../validate';

class WizardFormFive extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      tasks: [],
      dataComplete: false,
    };
  }

  componentDidMount() {
    const selector = formValueSelector('maraphon_flows_edit_form');
    const tasks = selector(store.getState(), 'tasks').map(task => ({
      value: task.id,
      label: typeof task.name === 'undefined' ? task.original : task.name,
    }));

    this.setState({ tasks, dataComplete: true });
  }

  render() {
    const { handleSubmit, previousPage } = this.props;
    const { tasks, dataComplete } = this.state;

    return (
      <form className="form form--horizontal wizard__form wizard__form__lg" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Настройте модули марафона</h3>
        {dataComplete && (
          <FieldArray name="modules" component={RenderModules} props={{ options: { tasks } }} />
        )}
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={previousPage}>Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next">Редактировать марафон</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'maraphon_flows_edit_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: false,
  onSubmit: updateMaraphonFlowAction,
  validate,
})(WizardFormFive);
