const validate = (values) => {
  // eslint-disable-next-line
  const validUrl = /(http(s)?:\/\/.)(www\.)?[-a-za-я0-9@:%._\+~#=]{2,256}\.[a-zа-я]{2,6}\b([-a-zа-я0-9@:%_\+.~#?&//=]*)/i;
  const errors = {};

  if (!values.name) {
    errors.name = 'Это поле является обязательным';
  } else if (values.name.length < 3) {
    errors.name = 'Введите не менее 3-х символов';
  }

  if (!values.slug) {
    errors.slug = 'Это поле является обязательным';
  } else if (!/^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(values.slug)) {
    errors.slug = 'Введенный вами URL не соответствует стандарту';
  }

  if (!values.author) {
    errors.author = 'Это поле является обязательным';
  }

  if (!values.description) {
    errors.description = 'Это поле является обязательным';
  }

  if (values.landing && !values.landing.match(validUrl)) {
    errors.landing = 'Вы ввели неверный URL адрес';
  }

  if (!values.direction) {
    errors.direction = 'Это поле является обязательным';
  }

  if (!values.themes || !values.themes.length) {
    errors.themes = { _error: 'Курс должен содержать хотя бы одну тему' };
  } else {
    const themesArrayErrors = [];

    values.themes.forEach((theme, themeIndex) => {
      const themeErrors = {};

      if (typeof theme.theme === 'undefined') {
        themeErrors.theme = 'Это поле является обязательным';
        themesArrayErrors[themeIndex] = themeErrors;
      }
    });

    if (themesArrayErrors.length) {
      errors.themes = themesArrayErrors;
    }
  }

  return errors;
};

export default validate;
