import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import {
  Field, FieldArray, reduxForm, formValueSelector,
} from 'redux-form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  MATERIALS_SUCCESS, MATERIALS_FAILED,
  getMaterial as getMaterialAction,
  materialClearState as materialClearStateAction,
  updateMaterial as updateMaterialAction,
  previewMaterial as previewMaterialAction,
} from '../../../../redux/actions/materialsAction';
import ButtonToNavigate from '../../../../shared/components/form/ButtonToNavigate';
import renderEditor from '../../../../shared/components/tinymce/TinyMCE';
import renderField from '../../../../shared/components/form/TextField';
import renderSelectField from '../../../../shared/components/form/Select';
import { FullWideNotification } from '../../../../shared/components/Notification';
import RenderVideos from '../../components/RenderVideos';
import RenderAudio from '../../components/RenderAudio';
import RenderCustomAudio from '../../components/RenderCustomAudio';
import store from '../../../App/store';
import validate from '../../validate';

class EditForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    submitStatus: PropTypes.string.isRequired,
    loadStatus: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    getMaterial: PropTypes.func.isRequired,
    materialClearState: PropTypes.func.isRequired,
    previewMaterial: PropTypes.func.isRequired,
    previewId: PropTypes.string.isRequired,
  };

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { getMaterial, match } = this.props;
    const { materialId } = match.params;

    getMaterial(materialId);
  }

  componentWillReceiveProps(nextProps) {
    const { submitStatus, loadStatus, history } = nextProps;

    if (loadStatus === MATERIALS_FAILED) {
      history.push('/404');
    }

    if (submitStatus === MATERIALS_SUCCESS) {
      history.push('/materials');
    } else if (submitStatus === MATERIALS_FAILED) {
      this.show('danger', 'Произошла непредвиденная ошибка. Обратитесь к разработчикам.');
    }
  }

  componentWillUnmount() {
    const { materialClearState } = this.props;
    materialClearState();
  }

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message={message}
      />,
      position: 'full',
    });
  };

  preview = async () => {
    const { previewMaterial, previewId } = this.props;
    const selector = formValueSelector('materials_edit_form');
    const form = selector(
      store.getState(), 'header', 'img', 'typeName', 'duration', 'videos', 'audio', 'customAudio', 'view',
    );
    const newPreviewId = await previewMaterial(previewId, form);

    window.open(`https://karpachoff.academy/preview-material/${newPreviewId}`, '_blank');
  };

  render() {
    const {
      handleSubmit, submitting, t, loadStatus,
    } = this.props;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">ФОРМА РЕДАКТИРОВАНИЯ МАТЕРИАЛА</h5>
              <h5 className="subhead">{t('maretials.forms.create_form_description')}</h5>
            </div>
            {loadStatus === MATERIALS_SUCCESS ? (
              <form className="form" target="_blank" onSubmit={handleSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Название материала</span>
                  <div className="form__form-group-field">
                    <Field
                      name="header"
                      component={renderField}
                      type="text"
                      placeholder="Название материала"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Иконка материала</span>
                  <div className="form__form-group-field">
                    <Field
                      name="img"
                      component={renderSelectField}
                      type="text"
                      placeholder="Выберите иконку для материала"
                      options={[
                        { value: 'aditional_materials', label: 'Допматериалы' },
                        { value: 'article', label: 'Статьи' },
                        { value: 'check_list', label: 'Чек-листы' },
                        { value: 'qa', label: 'Ответы на вопросы' },
                        { value: 'schema', label: 'Схемы' },
                        { value: 'task', label: 'Задания' },
                        { value: 'video', label: 'Видео' },
                      ]}
                    />
                  </div>
                </div>
                <div className="form__form-group" style={{ marginTop: '10px' }}>
                  <span className="form__form-group-label">Тип материала</span>
                  <div className="form__form-group-field">
                    <Field
                      name="typeName"
                      component={renderField}
                      type="text"
                      placeholder="Тип материала"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Длительность</span>
                  <div className="form__form-group-field">
                    <Field
                      name="duration"
                      component={renderField}
                      type="text"
                      placeholder="Длительность"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Список видео материалов</span>
                  <div className="form__form-group-field">
                    <FieldArray
                      name="videos"
                      component={RenderVideos}
                      props={{ reduxFormName: EditForm.FORM_NAME, fieldName: 'videos' }}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Список аудио материалов</span>
                  <div className="form__form-group-field">
                    <FieldArray name="audio" component={RenderAudio} />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Список аудио файлов</span>
                  <div className="form__form-group-field">
                    <FieldArray
                      name="customAudio"
                      component={RenderCustomAudio}
                      props={{ reduxFormName: EditForm.FORM_NAME, fieldName: 'customAudio' }}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Описание</span>
                  <div className="form__form-group-field">
                    <Field
                      name="view"
                      component={renderEditor}
                    />
                  </div>
                </div>
                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit" disabled={submitting}>Редактировать</Button>
                  <Button color="success" type="button" onClick={this.preview}>Предварительный просмотр</Button>
                  <Route
                    path="/"
                    render={props => <ButtonToNavigate {...props} title="Отмена" path="/materials" />}
                  />
                </ButtonToolbar>
              </form>
            ) : null}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

EditForm.FORM_NAME = 'materials_edit_form';

const mapStateToProps = state => ({
  initialValues: state.materials.active,
  submitStatus: state.materials.submitStatus,
  loadStatus: state.materials.loadActiveStatus,
  previewId: state.materials.previewId,
});

const mapDispatchToProps = dispatch => ({
  getMaterial: materialId => dispatch(getMaterialAction(materialId)),
  materialClearState: () => dispatch(materialClearStateAction()),
  previewMaterial: (previewId, data) => dispatch(previewMaterialAction(previewId, data)),
});

const EditFormComponent = reduxForm({
  form: EditForm.FORM_NAME, // a unique identifier for this form
  validate,
  enableReinitialize: true,
  onSubmit: updateMaterialAction,
})(withTranslation('common')(withRouter(EditForm)));

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditFormComponent);
