const validate = (values) => {
  const errors = {};
  const requiredErrorMessage = 'Это поле является обязательным';

  if (!values.name) {
    errors.name = requiredErrorMessage;
  } else if (values.name.length < 3) {
    errors.name = 'Введите не менее 3-х символов';
  }

  if (!values.maraphon) {
    errors.maraphon = requiredErrorMessage;
  }

  if (!values.maraphonType) {
    errors.maraphonType = requiredErrorMessage;
  } else if (values.maraphonType.value === 'flow') {
    if (!values.dateBegin) {
      errors.dateBegin = requiredErrorMessage;
    }

    if (!values.dateEnd) {
      errors.dateEnd = requiredErrorMessage;
    } else if (values.dateBegin && values.dateEnd.getTime() < values.dateBegin.getTime()) {
      errors.dateEnd = 'Дата окончания не может быть меньше даты старта';
    }

    if (!values.dateAvailable) {
      errors.dateAvailable = requiredErrorMessage;
    } else if (values.dateEnd && values.dateAvailable.getTime() < values.dateEnd.getTime()) {
      errors.dateAvailable = 'Дата доступа не может быть меньше даты окончания';
    }
  } else if (values.maraphonType.value === 'instant') {
    const moreThanZeroMessage = 'Введите число больше нуля';
    const tooManyMessage = 'Вы ввели слишком большое число';

    if (!values.ending) {
      errors.ending = requiredErrorMessage;
    } else if (values.ending <= 0) {
      errors.ending = moreThanZeroMessage;
    } else if (values.ending >= 10000) {
      errors.ending = tooManyMessage;
    } else if (values.days && (Number(values.days) < Number(values.ending))) {
      errors.ending = 'Это число должно быть меньше даты доступа к марафону';
    }

    if (!values.days) {
      errors.days = requiredErrorMessage;
    } else if (values.days <= 0) {
      errors.days = moreThanZeroMessage;
    } else if (values.days >= 10000) {
      errors.days = tooManyMessage;
    }
  }

  if (!values.packages || !values.packages.length) {
    errors.packages = { _error: 'Поток должен содержать хотя бы один пакет' };
  } else {
    const packageArrayErrors = [];
    const amoArray = [];

    values.packages.forEach((pack, packageIndex) => {
      const packageErrors = {};

      if (typeof pack.name === 'undefined') {
        packageErrors.name = requiredErrorMessage;
      }

      if (typeof pack.amoId === 'undefined') {
        packageErrors.amoId = requiredErrorMessage;
      } else if (amoArray.indexOf(pack.amoId) !== -1) {
        packageErrors.amoId = 'Этот номер AMO ID был введен ранее';
      } else {
        amoArray.push(pack.amoId);
      }

      if (typeof pack.isShowQuestions !== 'undefined' && pack.isShowQuestions) {
        if (typeof pack.questionOption === 'undefined') {
          packageErrors.isShowQuestions = 'Выберите значение';
        }
      }

      if (pack.bonuses && pack.bonuses.length) {
        const checklistBonusesArrayErrors = [];

        pack.bonuses.forEach((bonus, bonusIndex) => {
          if (typeof bonus.bonus === 'undefined') {
            checklistBonusesArrayErrors[bonusIndex] = { bonus: requiredErrorMessage };
          }
        });

        if (checklistBonusesArrayErrors.length) {
          packageErrors.bonuses = checklistBonusesArrayErrors;
          packageArrayErrors[packageIndex] = packageErrors;
        }
      } else if (Object.keys(packageErrors)) {
        packageArrayErrors[packageIndex] = packageErrors;
      }
    });

    if (packageArrayErrors.length) {
      errors.packages = packageArrayErrors;
    }
  }

  if (values.curators && values.curators.length) {
    const curatorArrayErrors = [];

    values.curators.forEach((curator, curatorIndex) => {
      const curatorErrors = {};

      if (typeof curator.curator === 'undefined') {
        curatorErrors.curator = requiredErrorMessage;
        curatorArrayErrors[curatorIndex] = curatorErrors;
      }
    });

    if (curatorArrayErrors.length) {
      errors.curators = curatorArrayErrors;
    }
  }

  if (values.tasks && values.tasks.length) {
    const taskArrayErrors = [];

    values.tasks.forEach((task, taskIndex) => {
      const taskErrors = {};

      if (typeof task.day === 'undefined') {
        taskErrors.day = requiredErrorMessage;
        taskArrayErrors[taskIndex] = taskErrors;
      }
    });

    if (taskArrayErrors.length) {
      errors.tasks = taskArrayErrors;
    }
  }

  if (values.modules && values.modules.length) {
    const moduleArrayErrors = [];

    values.modules.forEach((module, moduleIndex) => {
      const moduleErrors = {};

      if (typeof module.name === 'undefined') {
        moduleErrors.name = requiredErrorMessage;
      }

      if (module.tasks && module.tasks.length) {
        const tasksArrayErrors = [];

        module.tasks.forEach((task, taskIndex) => {
          if (typeof task.task === 'undefined') {
            tasksArrayErrors[taskIndex] = { task: requiredErrorMessage };
          }
        });

        if (tasksArrayErrors.length) {
          moduleErrors.tasks = tasksArrayErrors;
          moduleArrayErrors[moduleIndex] = moduleErrors;
        }
      } else if (Object.keys(moduleErrors)) {
        moduleArrayErrors[moduleIndex] = moduleErrors;
      }
    });

    if (moduleArrayErrors.length) {
      errors.modules = moduleArrayErrors;
    }
  }

  return errors;
};

export default validate;
