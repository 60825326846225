import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';

import HubTablePage from '../../../Hubs/HubTable/index';
import HubCreatePage from '../../../Hubs/HubCreate/index';
import HubEditPage from '../../../Hubs/HubEdit/index';

export default () => (
  <Switch>
    <ProtectedRoute path="/hubs" exact component={HubTablePage} />
    <ProtectedRoute path="/hubs/create" component={HubCreatePage} />
    <ProtectedRoute path="/hubs/:hubId" exact component={HubEditPage} />
  </Switch>
);
