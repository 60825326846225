import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  GROUPS_SUCCESS, GROUPS_FAILED,
  createGroup as createGroupAction, getAsyncData as getAsyncDataAction, groupClearState as groupClearStateAction,
} from '../../../../redux/actions/groupsAction';
import ButtonToNavigate from '../../../../shared/components/form/ButtonToNavigate';
import renderField from '../../../../shared/components/form/TextField';
import renderSelectField from '../../../../shared/components/form/Select';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import RenderOptions from '../../components/RenderOptions';
import { FullWideNotification } from '../../../../shared/components/Notification';
import validate from '../../validate';

class CreateForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    asyncData: PropTypes.objectOf(PropTypes.any).isRequired,
    submitStatus: PropTypes.string.isRequired,
    asyncStatus: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    getAsyncData: PropTypes.func.isRequired,
    groupClearState: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      type: 'list',
    };
  }

  componentDidMount() {
    const { getAsyncData } = this.props;
    getAsyncData();
  }

  componentWillReceiveProps(nextProps) {
    const { submitStatus, history } = nextProps;

    if (submitStatus === GROUPS_SUCCESS) {
      history.push('/groups');
    } else if (submitStatus === GROUPS_FAILED) {
      this.show('danger', 'Произошла непредвиденная ошибка. Обратитесь к разработчикам.');
    }
  }

  componentWillUnmount() {
    const { groupClearState } = this.props;
    groupClearState();
  }

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message={message}
      />,
      position: 'full',
    });
  };

  handleChangeType = (value) => {
    this.setState({ type: value });
  };

  render() {
    const {
      handleSubmit, submitting, asyncStatus, asyncData, t,
    } = this.props;
    const { type } = this.state;
    const { lists } = asyncData;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">{t('groups.forms.create_form_title')}</h5>
              <h5 className="subhead">{t('groups.forms.create_form_description')}</h5>
            </div>
            {asyncStatus === GROUPS_SUCCESS ? (
              <form className="form" onSubmit={handleSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Тип группы полей</span>
                  <div className="form__form-group-field">
                    <Field
                      name="type"
                      component={renderRadioButtonField}
                      label="Список"
                      radioValue="list"
                      onChange={this.handleChangeType}
                      defaultChecked
                    />
                    <Field
                      name="type"
                      component={renderRadioButtonField}
                      label="Подсписок"
                      radioValue="sublist"
                      onChange={this.handleChangeType}
                    />
                    <Field
                      name="type"
                      component={renderRadioButtonField}
                      label="Список Выполнено"
                      radioValue="done"
                      onChange={this.handleChangeType}
                    />
                    <Field
                      name="type"
                      component={renderRadioButtonField}
                      label="Поле"
                      radioValue="field"
                      onChange={this.handleChangeType}
                    />
                    <Field
                      name="type"
                      component={renderRadioButtonField}
                      label="Переключатели"
                      radioValue="radio"
                      onChange={this.handleChangeType}
                    />
                    <Field
                      name="type"
                      component={renderRadioButtonField}
                      label="Закончить предложения"
                      radioValue="sentences"
                      onChange={this.handleChangeType}
                    />
                    <Field
                      name="type"
                      component={renderRadioButtonField}
                      label="Массив галочек"
                      radioValue="interview"
                      onChange={this.handleChangeType}
                    />
                    <Field
                      name="type"
                      component={renderRadioButtonField}
                      label="Поле с вариантами"
                      radioValue="mix"
                      onChange={this.handleChangeType}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Название группы полей</span>
                  <div className="form__form-group-field">
                    <Field
                      name="name"
                      component={renderField}
                      type="text"
                      placeholder="Название группы полей"
                    />
                  </div>
                </div>
                {type === 'sublist' || type === 'done' ? (
                  <div className="form__form-group form__form-select" style={{ marginBottom: '40px' }}>
                    <span className="form__form-group-label">Первичный список</span>
                    <div className="form__form-group-field">
                      <Field
                        name="parent"
                        component={renderSelectField}
                        placeholder="Выберите список"
                        options={lists}
                      />
                    </div>
                  </div>
                ) : null}
                {type === 'list' || type === 'sublist' || type === 'mix' ? (
                  <>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Минимальное количество полей</span>
                      <div className="form__form-group-field">
                        <Field
                          name="min"
                          component={renderField}
                          type="number"
                          placeholder="Минимальное количество полей"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="isAdd"
                          component={renderCheckBoxField}
                          label="Разрешить пользователю добавлять поля"
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {type === 'list' || type === 'sublist' ? (
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="isSortable"
                        component={renderCheckBoxField}
                        label="Разрешить пользователю сортировать поля"
                      />
                    </div>
                  </div>
                ) : null}
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {type === 'sublist' ? 'Надпись над подсписком' : 'Надпись возле поля'}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="textNearField"
                      component={renderField}
                      type="text"
                      placeholder={type === 'sublist' ? 'Надпись над подсписком' : 'Надпись возле поля'}
                    />
                  </div>
                </div>
                {type !== 'field' ? (
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="isIterableTextNearField"
                        component={renderCheckBoxField}
                        label="Нумеровать поля"
                      />
                    </div>
                  </div>
                ) : null}
                {type === 'sublist' || type === 'done' ? (
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {type === 'sublist' ? 'Надпись возле основного поля' : 'Текст на кнопке'}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="additionalText"
                        component={renderField}
                        type="text"
                        placeholder={type === 'sublist' ? 'Надпись возле основного поля' : 'Текст на кнопке'}
                      />
                    </div>
                  </div>
                ) : null}
                {type === 'sublist' ? (
                  <div className="form__form-group">
                    <span className="form__form-group-label">Текст под основным полем</span>
                    <div className="form__form-group-field">
                      <Field
                        name="additionalText2"
                        component={renderField}
                        type="text"
                        placeholder="Текст под основным полем"
                      />
                    </div>
                  </div>
                ) : null}
                {type === 'radio' || type === 'sentences' || type === 'interview' || type === 'mix' ? (
                  <div className="form__form-group form__form-select">
                    <span className="form__form-group-label">Опции</span>
                    <div className="form__form-group-field">
                      <FieldArray
                        name="options"
                        component={RenderOptions}
                        props={{
                          showIsMain: type === 'mix',
                          text: 'Вариант',
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {type === 'interview' || type === 'mix' ? (
                  <div className="form__form-group form__form-select">
                    <span className="form__form-group-label">Дополнительные Опции</span>
                    <div className="form__form-group-field">
                      <FieldArray
                        name="options2"
                        component={RenderOptions}
                        props={{
                          showIsMain: false,
                          text: 'Вариант',
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit" disabled={submitting}>Создать</Button>
                  <Route
                    path="/"
                    render={props => <ButtonToNavigate {...props} title="Отмена" path="/groups" />}
                  />
                </ButtonToolbar>
              </form>
            ) : null}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  submitStatus: state.groups.submitStatus,
  asyncStatus: state.groups.asyncStatus,
  asyncData: state.groups.asyncData,
});

const mapDispatchToProps = dispatch => ({
  getAsyncData: () => dispatch(getAsyncDataAction()),
  groupClearState: () => dispatch(groupClearStateAction()),
});

const CreateFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateForm);

export default reduxForm({
  form: 'groups_create_form', // a unique identifier for this form
  validate,
  onSubmit: createGroupAction,
})(withTranslation('common')(withRouter(CreateFormComponent)));
