const required = value => (value || typeof value === 'number' ? undefined : 'Это поле является обязательным');
const minLength = min => value => (value && value.length < min
  ? `Введите не менее ${min}-ти символов`
  : undefined);
const minLength6 = minLength(6);
// eslint-disable-next-line
const email = (value) => value && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i.test(value)
  ? 'Введен некорректный E-mail адрес'
  : undefined;

export {
  required,
  minLength6,
  email,
};
