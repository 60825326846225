import moment from 'moment';
import { startSubmit, stopSubmit } from 'redux-form';
import ws from '../../ws';
import getToken from './getToken';
import PaginationPageConverter from '../../shared/classes/PaginationPageConverter';

const SHOWCASES_PROGRESS = 'progress';
const SHOWCASES_SUCCESS = 'success';
const SHOWCASES_FAILED = 'failed';

const SHOWCASES_GET_LIST_PROGRESS = 'SHOWCASES_GET_LIST_PROGRESS';
const SHOWCASES_GET_LIST_SUCCESS = 'SHOWCASES_GET_LIST_SUCCESS';
const SHOWCASES_GET_LIST_FAILED = 'SHOWCASES_GET_LIST_FAILED';
const SHOWCASES_FORM_SUBMIT_PROGRESS = 'SHOWCASES_FORM_SUBMIT_PROGRESS';
const SHOWCASES_FORM_SUBMIT_SUCCESS = 'SHOWCASES_FORM_SUBMIT_SUCCESS';
const SHOWCASES_FORM_SUBMIT_FAILED = 'SHOWCASES_FORM_SUBMIT_FAILED';
const SHOWCASES_GET_SHOWCASE_PROGRESS = 'SHOWCASES_GET_SHOWCASE_PROGRESS';
const SHOWCASES_GET_SHOWCASE_SUCCESS = 'SHOWCASES_GET_SHOWCASE_SUCCESS';
const SHOWCASES_GET_SHOWCASE_FAILED = 'SHOWCASES_GET_SHOWCASE_FAILED';
const SHOWCASES_GET_ASYNC_DATA_PROGRESS = 'SHOWCASES_GET_ASYNC_DATA_PROGRESS';
const SHOWCASES_GET_ASYNC_DATA_SUCCESS = 'SHOWCASES_GET_ASYNC_DATA_SUCCESS';
const SHOWCASES_GET_ASYNC_DATA_FAILED = 'SHOWCASES_GET_ASYNC_DATA_FAILED';
const SHOWCASES_CLEAR_STATE = 'SHOWCASES_CLEAR_STATE';

const SHOWCASES_CLEAR_FLASH_MESSAGE = 'SHOWCASES_CLEAR_FLASH_MESSAGE';

const showcaseGetListProgress = () => ({
  type: SHOWCASES_GET_LIST_PROGRESS,
});

const showcaseGetListSuccess = payload => ({
  type: SHOWCASES_GET_LIST_SUCCESS,
  payload,
});

const showcaseGetListFailed = () => ({
  type: SHOWCASES_GET_LIST_FAILED,
});

const showcaseStartFormSubmit = () => ({
  type: SHOWCASES_FORM_SUBMIT_PROGRESS,
});

const showcaseFormSubmitSuccess = payload => ({
  type: SHOWCASES_FORM_SUBMIT_SUCCESS,
  payload,
});

const showcaseFormSubmitFailed = () => ({
  type: SHOWCASES_FORM_SUBMIT_FAILED,
});

const showcaseGetShowcaseProgress = () => ({
  type: SHOWCASES_GET_SHOWCASE_PROGRESS,
});

const showcaseGetShowcaseSuccess = payload => ({
  type: SHOWCASES_GET_SHOWCASE_SUCCESS,
  payload,
});

const showcaseGetShowcaseFailed = () => ({
  type: SHOWCASES_GET_SHOWCASE_FAILED,
});

const showcaseGetAsyncDataProgress = () => ({
  type: SHOWCASES_GET_ASYNC_DATA_PROGRESS,
});

const showcaseGetAsyncDataSuccess = payload => ({
  type: SHOWCASES_GET_ASYNC_DATA_SUCCESS,
  payload,
});

const showcaseGetAsyncDataFailed = () => ({
  type: SHOWCASES_GET_ASYNC_DATA_FAILED,
});

const showcaseClearState = () => ({
  type: SHOWCASES_CLEAR_STATE,
});

const getMaraphonName = ({ name, maraphonType, date }) => {
  const { begin, end, available } = date;
  let maraphonName = name;

  if (maraphonType === 'flow') {
    const startDate = moment(begin).format('DD.MM.YYYY');
    const endDate = moment(end).format('DD.MM.YYYY');
    maraphonName = `${maraphonName} (Поток: с ${startDate} по ${endDate}`;

    if (available) {
      const availableDate = moment(available).format('DD.MM.YYYY');
      maraphonName = `${maraphonName}, доступный до ${availableDate})`;
    } else {
      maraphonName = `${maraphonName})`;
    }
  } else {
    maraphonName = `${maraphonName} (Моментальный)`;
  }

  return maraphonName;
};

const convertListToTable = data => new PaginationPageConverter(data, doc => ({
  id: doc.id,
  name: doc.alternative.name !== null ? doc.alternative.name : doc.maraphonFlow.name,
  type: doc.maraphonFlow.maraphonType,
  begin: doc.maraphonFlow.date.begin !== null
    ? moment(doc.maraphonFlow.date.begin).locale('ru').format('DD MMM YY г.')
    : 'отсутствует',
  end: doc.maraphonFlow.date.end !== null
    ? moment(doc.maraphonFlow.date.end).locale('ru').format('DD MMM YY г.')
    : 'отсутствует',
  sort: doc.sort,
  isShow: doc.isShow,
  isPreview: doc.isPreview,
})).getConvertedData();

const getShowcasesList = params => (dispatch) => {
  const token = getToken();
  dispatch(showcaseGetListProgress());

  ws.emit('api/academy/showcases/list', { token, payload: params }, (data) => {
    const { status, payload } = data;
    let action;

    if (status === 'ok') {
      action = showcaseGetListSuccess(convertListToTable(payload.data));
    } else {
      action = showcaseGetListFailed();
    }

    dispatch(action);
  });
};

const convertSetShowcaseToDB = (data) => {
  const {
    maraphonFlow, sort, isShow, isPreview, alternativeName, alternativeDescription, price, productId, discountIsEnable,
    discountPrice, discountPercent, discountDate, descriptionTop, descriptionBottom, descriptionModal, reviews, faq,
  } = data;

  const getTextValueOrNull = field => (typeof field === 'string' && field.length ? field : null);
  const getBooleanValue = field => (typeof field === 'boolean' ? field : false);
  const isEnable = getBooleanValue(discountIsEnable);
  const isShowOnShowcase = getBooleanValue(isShow);
  const isShowOnlyForAdmin = getBooleanValue(isPreview);

  return {
    maraphonFlow: maraphonFlow.value,
    sort: parseInt(sort, 10),
    alternative: {
      name: getTextValueOrNull(alternativeName),
      description: getTextValueOrNull(alternativeDescription),
    },
    description: {
      top: getTextValueOrNull(descriptionTop),
      bottom: getTextValueOrNull(descriptionBottom),
      modal: getTextValueOrNull(descriptionModal),
    },
    purchase: {
      discount: {
        isEnable,
        price: isEnable ? parseInt(discountPrice, 10) : null,
        date: isEnable && discountDate instanceof Date ? discountDate.toISOString() : null,
        percent: isEnable ? parseInt(discountPercent, 10) : null,
      },
      price: parseInt(price, 10),
      productId,
    },
    reviews: Array.isArray(reviews)
      ? reviews.map(item => ({
        _id: item.id,
        small: getTextValueOrNull(item.small),
        large: getTextValueOrNull(item.large),
      }))
      : [],
    faq: Array.isArray(faq)
      ? faq.map(item => ({
        _id: item.id,
        question: getTextValueOrNull(item.question),
        answer: getTextValueOrNull(item.answer),
      }))
      : [],
    isShow: isShowOnShowcase,
    isPreview: isShowOnShowcase ? isShowOnlyForAdmin : false,
  };
};

const createShowcase = async (data, dispatch, props) => {
  const token = getToken();
  const { form, reset } = props;
  const convertedData = convertSetShowcaseToDB(data);

  dispatch(startSubmit(form));
  dispatch(showcaseStartFormSubmit());

  ws.emit('api/academy/showcases/create', { token, payload: { convertedData } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      reset();
      dispatch(stopSubmit(form));
      dispatch(showcaseFormSubmitSuccess('Новый курс был успешно добавлен на витрину.'));
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(showcaseFormSubmitFailed());
    }
  });
};

const convertDBShowcaseToEdit = (payload) => {
  const { showcase: showcaseFromDB } = payload;
  const {
    id, maraphonFlow, sort, alternative, description, purchase, faq, reviews, isShow, isPreview,
  } = showcaseFromDB;
  const { discount, price, productId } = purchase;
  const getStringValue = field => (typeof field !== 'string' ? '' : field);
  const convertNumericToString = field => (typeof field === 'number' ? field.toString() : '');

  const showcase = {
    id,
    maraphonFlow: { value: maraphonFlow.id, label: getMaraphonName(maraphonFlow) },
    sort: convertNumericToString(sort),
    isShow,
    isPreview,
    alternativeName: getStringValue(alternative.name),
    alternativeDescription: getStringValue(alternative.description),
    descriptionTop: getStringValue(description.top),
    descriptionBottom: getStringValue(description.bottom),
    descriptionModal: getStringValue(description.modal),
    price: convertNumericToString(price),
    productId,
    discountIsEnable: discount.isEnable,
    discountPrice: convertNumericToString(discount.price),
    discountPercent: convertNumericToString(discount.percent),
    discountDate: typeof discount.date === 'string' ? new Date(discount.date) : undefined,
    reviews: reviews.map(item => ({
      // eslint-disable-next-line no-underscore-dangle
      id: item._id,
      small: item.small,
      large: item.large,
    })),
    faq: faq.map(item => ({
      // eslint-disable-next-line no-underscore-dangle
      id: item._id,
      question: item.question,
      answer: item.answer,
    })),
  };

  return { showcase };
};

const getShowcase = showcaseId => async (dispatch) => {
  const token = getToken();
  dispatch(showcaseGetShowcaseProgress());

  ws.emit('api/academy/showcases/getById', { token, payload: { showcaseId } }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = showcaseGetShowcaseSuccess(convertDBShowcaseToEdit(payload));
    } else {
      action = showcaseGetShowcaseFailed();
    }

    dispatch(action);
  });
};

const updateShowcase = async (data, dispatch, props) => {
  const token = getToken();
  const { id } = data;
  const { form } = props;
  const convertedData = convertSetShowcaseToDB(data);

  dispatch(startSubmit(form));
  dispatch(showcaseStartFormSubmit());

  ws.emit('api/academy/showcases/updateById', { token, payload: { showcaseId: id, convertedData } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(stopSubmit(form));
      dispatch(showcaseFormSubmitSuccess('Курс на витрине был успешно отредактирован.'));
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(showcaseFormSubmitFailed());
    }
  });
};

const transformAsyncData = data => ({
  flows: data.flows.map(flow => ({ value: flow.id, label: getMaraphonName(flow) })),
});

const getAsyncData = () => async (dispatch) => {
  const token = getToken();
  dispatch(showcaseGetAsyncDataProgress());

  ws.emit('api/academy/showcases/getAsyncData', { token }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = showcaseGetAsyncDataSuccess(transformAsyncData(payload));
    } else {
      action = showcaseGetAsyncDataFailed();
    }

    dispatch(action);
  });
};

const showcaseClearFlashMessage = () => ({
  type: SHOWCASES_CLEAR_FLASH_MESSAGE,
});

export {
  SHOWCASES_PROGRESS,
  SHOWCASES_SUCCESS,
  SHOWCASES_FAILED,
  SHOWCASES_GET_LIST_PROGRESS,
  SHOWCASES_GET_LIST_SUCCESS,
  SHOWCASES_GET_LIST_FAILED,
  SHOWCASES_FORM_SUBMIT_PROGRESS,
  SHOWCASES_FORM_SUBMIT_SUCCESS,
  SHOWCASES_FORM_SUBMIT_FAILED,
  SHOWCASES_GET_SHOWCASE_PROGRESS,
  SHOWCASES_GET_SHOWCASE_SUCCESS,
  SHOWCASES_GET_SHOWCASE_FAILED,
  SHOWCASES_GET_ASYNC_DATA_PROGRESS,
  SHOWCASES_GET_ASYNC_DATA_SUCCESS,
  SHOWCASES_GET_ASYNC_DATA_FAILED,
  SHOWCASES_CLEAR_STATE,
  SHOWCASES_CLEAR_FLASH_MESSAGE,
  showcaseGetListProgress,
  showcaseGetListSuccess,
  showcaseGetListFailed,
  showcaseStartFormSubmit,
  showcaseFormSubmitSuccess,
  showcaseFormSubmitFailed,
  showcaseGetShowcaseProgress,
  showcaseGetShowcaseSuccess,
  showcaseGetShowcaseFailed,
  showcaseGetAsyncDataProgress,
  showcaseGetAsyncDataSuccess,
  showcaseGetAsyncDataFailed,
  showcaseClearState,
  getShowcasesList,
  createShowcase,
  getShowcase,
  updateShowcase,
  getAsyncData,
  showcaseClearFlashMessage,
};
