import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  MARAPHON_TASK_SUCCESS, MARAPHON_TASK_FAILED,
  getMaraphonTask as getMaraphonTaskAction,
  maraphonTaskClearState as maraphonTaskClearStateAction,
  updateMaraphonTask as updateMaraphonTaskAction,
} from '../../../../redux/actions/maraphonTaskAction';
import ButtonToNavigate from '../../../../shared/components/form/ButtonToNavigate';
import renderEditor from '../../../../shared/components/tinymce/TinyMCE';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderField from '../../../../shared/components/form/TextField';
import { FullWideNotification } from '../../../../shared/components/Notification';
import RenderVideos from '../../components/RenderVideos';
import RenderAudio from '../../components/RenderAudio';
import RenderCustomAudio from '../../components/RenderCustomAudio';
import RenderBillet from '../../components/RenderBillet';
import RenderTask from '../../components/RenderTask';
import validate from '../../validate';

class EditForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    submitStatus: PropTypes.string.isRequired,
    loadStatus: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    getMaraphonTask: PropTypes.func.isRequired,
    maraphonTaskClearState: PropTypes.func.isRequired,
  };

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { getMaraphonTask, match } = this.props;
    const { taskId } = match.params;

    getMaraphonTask(taskId);
  }

  componentWillReceiveProps(nextProps) {
    const { submitStatus, loadStatus, history } = nextProps;

    if (loadStatus === MARAPHON_TASK_FAILED) {
      history.push('/404');
    }

    if (submitStatus === MARAPHON_TASK_SUCCESS) {
      this.show('success', 'Тема была успешно отредактирована.');
    } else if (submitStatus === MARAPHON_TASK_FAILED) {
      this.show('danger', 'Произошла непредвиденная ошибка. Обратитесь к разработчикам.');
    }
  }

  componentWillUnmount() {
    const { maraphonTaskClearState } = this.props;
    maraphonTaskClearState();
  }

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message={message}
      />,
      position: 'full',
    });
  };

  render() {
    const {
      handleSubmit, submitting, t, loadStatus,
    } = this.props;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">ФОРМА РЕДАКТИРОВАНИЯ ТЕМЫ</h5>
              <h5 className="subhead">{t('maretials.forms.create_form_description')}</h5>
            </div>
            {loadStatus === MARAPHON_TASK_SUCCESS ? (
              <form className="form" target="_blank" onSubmit={handleSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Название темы</span>
                  <div className="form__form-group-field">
                    <Field
                      name="name"
                      component={renderField}
                      type="text"
                      placeholder="Название темы"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Список видео материалов</span>
                  <div className="form__form-group-field">
                    <FieldArray
                      name="videos"
                      component={RenderVideos}
                      props={{ reduxFormName: EditForm.FORM_NAME, fieldName: 'videos' }}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Список аудио материалов</span>
                  <div className="form__form-group-field">
                    <FieldArray name="audio" component={RenderAudio} />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Список аудио файлов</span>
                  <div className="form__form-group-field">
                    <FieldArray
                      name="customAudio"
                      component={RenderCustomAudio}
                      props={{ reduxFormName: EditForm.FORM_NAME, fieldName: 'customAudio' }}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Контент</span>
                  <div className="form__form-group-field">
                    <Field
                      name="content"
                      component={renderEditor}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Список материалов</span>
                  <div className="form__form-group-field">
                    <FieldArray name="billets" component={RenderBillet} />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Контент на странице со списком заданий</span>
                  <div className="form__form-group-field">
                    <Field
                      name="tasksContent"
                      component={renderEditor}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      name="isShowContent"
                      component={renderCheckBoxField}
                      label="Не отображать контент на странице со списком заданий"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Список заданий</span>
                  <div className="form__form-group-field">
                    <FieldArray name="tasks" component={RenderTask} />
                  </div>
                </div>
                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit" disabled={submitting}>Применить</Button>
                  <Route
                    path="/"
                    render={props => <ButtonToNavigate {...props} title="Закрыть" path="/maraphon_tasks" />}
                  />
                </ButtonToolbar>
              </form>
            ) : null}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

EditForm.FORM_NAME = 'maraphon_tasks_edit_form';

const mapStateToProps = state => ({
  initialValues: state.maraphonTasks.active,
  submitStatus: state.maraphonTasks.submitStatus,
  loadStatus: state.maraphonTasks.loadActiveStatus,
});

const mapDispatchToProps = dispatch => ({
  getMaraphonTask: taskId => dispatch(getMaraphonTaskAction(taskId)),
  maraphonTaskClearState: () => dispatch(maraphonTaskClearStateAction()),
});

const EditFormComponent = reduxForm({
  form: EditForm.FORM_NAME, // a unique identifier for this form
  validate,
  enableReinitialize: true,
  onSubmit: updateMaraphonTaskAction,
})(withTranslation('common')(withRouter(EditForm)));

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditFormComponent);
