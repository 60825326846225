const validate = (values) => {
  const errors = {};
  const requiredErrorMessage = 'Это поле является обязательным';

  if (!values.header) {
    errors.header = requiredErrorMessage;
  } else if (values.header.length < 3) {
    errors.header = 'Введите не менее 3-х символов';
  }

  if (!values.img) {
    errors.img = requiredErrorMessage;
  }

  if (!values.typeName) {
    errors.typeName = requiredErrorMessage;
  }

  if (!values.duration) {
    errors.duration = requiredErrorMessage;
  }

  if (values.videos && values.videos.length) {
    const videoArrayErrors = [];

    values.videos.forEach((video, videoIndex) => {
      const videoErrors = {};

      if (typeof video.video === 'undefined') {
        videoErrors.video = requiredErrorMessage;
        videoArrayErrors[videoIndex] = videoErrors;
      }
    });

    if (videoArrayErrors.length) {
      errors.videos = videoArrayErrors;
    }
  }

  if (values.audio && values.audio.length) {
    const audioArrayErrors = [];

    values.audio.forEach((audio, audioIndex) => {
      const audioErrors = {};

      if (typeof audio.audio === 'undefined') {
        audioErrors.audio = requiredErrorMessage;
        audioArrayErrors[audioIndex] = audioErrors;
      }
    });

    if (audioArrayErrors.length) {
      errors.audio = audioArrayErrors;
    }
  }

  return errors;
};

export default validate;
