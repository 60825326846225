import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderField from '../../../../shared/components/form/TextField';
import { required, minLength6, email } from '../../fieldLevelValidate';
import validate from '../../validate';
import asyncValidate from '../../asyncValidate';

class WizardFormTwo extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit, previousPage } = this.props;

    return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Заполните данные для авторизации</h3>
        <div className="form__form-group">
          <span className="form__form-group-label">E-mail</span>
          <div className="form__form-group-field">
            <Field
              name="email"
              component={renderField}
              type="text"
              placeholder="E-mail"
              validate={[required, email]}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Пароль</span>
          <div className="form__form-group-field">
            <Field
              name="password"
              component={renderField}
              type="text"
              placeholder="Пароль"
              validate={[required, minLength6]}
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={previousPage}>Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next">Следующий шаг</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'users_create_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  asyncValidate,
  asyncBlurFields: ['email'],
})(WizardFormTwo);
