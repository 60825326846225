import ws from '../../ws';
import getToken from '../../redux/actions/getToken';

class EmailExistsError extends Error {
  email = 'Пользователь с таким E-mail уже существует';
}

const asyncValidate = async (values) => {
  const token = getToken();
  const data = { email: values.email, token };
  data.id = Object.prototype.hasOwnProperty.call(values, 'id') ? values.id : null;

  return new Promise((resolve, reject) => {
    ws.emit('api/academy/users/checkAsyncData', data, (result) => {
      const { status, payload } = result;

      if (status === 'ok' && payload) {
        reject(new EmailExistsError());
      } else {
        resolve();
      }
    });
  });
};

export default asyncValidate;
