import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { FieldArray, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import RenderTasks from '../../components/RenderTasks';
import validate from '../../validate';

class WizardFormFour extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    asyncData: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  render() {
    const { handleSubmit, previousPage, asyncData } = this.props;
    const { tasks } = asyncData;

    return (
      <form className="form form--horizontal wizard__form wizard__form__lg" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Выберите и отсортируйте материалы для дополнительной проработки</h3>
        <FieldArray name="extra_tasks" component={RenderTasks} props={{ options: tasks }} />
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={previousPage}>Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next">Следующий шаг</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'themes_create_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(WizardFormFour);
