import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  QUESTIONS_SUCCESS, QUESTIONS_FAILED,
  createQuestion as createQuestionAction, questionClearState as questionClearStateAction,
  getAsyncData as getAsyncDataAction, refreshAsyncData as refreshAsyncDataAction,
} from '../../../../redux/actions/questionsAction';
import ButtonToNavigate from '../../../../shared/components/form/ButtonToNavigate';
import renderField from '../../../../shared/components/form/TextField';
import renderTextarea from '../../../../shared/components/form/TextareaField';
import renderEditor from '../../../../shared/components/form/TinyWYSIWYGEditor';
import CreateTag from '../../components/CreateTag';
import RenderTags from '../../components/RenderTags';
import RenderVideos from '../../components/RenderVideos';
import RenderCourses from '../../components/RenderCourses';
import '../../components/styles.css';
import { FullWideNotification } from '../../../../shared/components/Notification';
import validate from '../../validate';

class CreateForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    asyncStatus: PropTypes.string.isRequired,
    asyncData: PropTypes.objectOf(PropTypes.any).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    submitStatus: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    questionClearState: PropTypes.func.isRequired,
    getAsyncData: PropTypes.func.isRequired,
    refreshAsyncData: PropTypes.func.isRequired,
  };

  state = {
    showCreateTagForm: false,
  };

  componentDidMount() {
    const { getAsyncData } = this.props;
    getAsyncData();
  }

  componentWillReceiveProps(nextProps) {
    const { submitStatus, history } = nextProps;

    if (submitStatus === QUESTIONS_SUCCESS) {
      history.push('/questions');
    } else if (submitStatus === QUESTIONS_FAILED) {
      this.show('danger', 'Произошла непредвиденная ошибка. Обратитесь к разработчикам.');
    }
  }

  componentWillUnmount() {
    const { questionClearState } = this.props;
    questionClearState();
  }

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message={message}
      />,
      position: 'full',
    });
  };

  updateTags = () => {
    const { refreshAsyncData } = this.props;
    refreshAsyncData();
  };

  showCreateTagForm = () => {
    this.setState({ showCreateTagForm: true });
  };

  render() {
    const {
      handleSubmit, submitting, showNotification, asyncData, asyncStatus, t,
    } = this.props;
    const { tags, courses, maraphons } = asyncData;
    const { showCreateTagForm } = this.state;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">{t('questions.forms.create_form_title')}</h5>
              <h5 className="subhead">{t('questions.forms.create_form_description')}</h5>
            </div>
            {asyncStatus === QUESTIONS_SUCCESS ? (
              <div>
                <form name="questions" className="form">
                  <div className="form__half">
                    <div className="form__form-group">
                      <span className="form__form-group-label">Название вопроса</span>
                      <div className="form__form-group-field">
                        <Field
                          name="title"
                          component={renderField}
                          type="text"
                          placeholder="Название вопроса"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form__half">
                    <div className="form__form-group">
                      <span className="form__form-group-label">Имя пользователя, который задал вопрос</span>
                      <div className="form__form-group-field">
                        <Field
                          name="name"
                          component={renderField}
                          type="text"
                          placeholder="Имя пользователя"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Вопрос</span>
                    <div className="form__form-group-field">
                      <Field
                        name="question"
                        component={renderTextarea}
                        placeholder="Вопрос"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Список видео материалов</span>
                    <div className="form__form-group-field">
                      <FieldArray name="videos" component={RenderVideos} />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Ответ</span>
                    <div className="form__form-group-field">
                      <Field
                        name="answer"
                        component={renderEditor}
                      />
                    </div>
                  </div>
                </form>
                <div className="form_question_half">
                  <div>
                    <form name="questions" className="form">
                      <FieldArray
                        name="tags"
                        component={RenderTags}
                        props={{ options: tags }}
                        showCreateTagForm={this.showCreateTagForm}
                      />
                    </form>
                    {showCreateTagForm ? (
                      <CreateTag showNotification={showNotification} updateTags={this.updateTags} />
                    ) : null}
                  </div>
                  <div>
                    <form name="questions" className="form">
                      <div className="form__form-group">
                        <FieldArray
                          name="academy"
                          component={RenderCourses}
                          props={{ courses: [...courses, ...maraphons] }}
                          rerenderOnEveryChange={!false}
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <form name="questions" className="form" onSubmit={handleSubmit}>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit" disabled={submitting}>Создать</Button>
                    <Route
                      path="/"
                      render={props => <ButtonToNavigate {...props} title="Отмена" path="/questions" />}
                    />
                  </ButtonToolbar>
                </form>
              </div>
            ) : null}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  asyncData: state.questions.asyncData,
  asyncStatus: state.questions.asyncStatus,
  submitStatus: state.questions.submitStatus,
});

const mapDispatchToProps = dispatch => ({
  getAsyncData: () => dispatch(getAsyncDataAction()),
  refreshAsyncData: () => dispatch(refreshAsyncDataAction()),
  questionClearState: () => dispatch(questionClearStateAction()),
});

const CreateFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateForm);

export default reduxForm({
  form: 'questions_create_form', // a unique identifier for this form
  validate,
  onSubmit: createQuestionAction,
})(withTranslation('common')(withRouter(CreateFormComponent)));
