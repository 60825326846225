import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import {
  Button, ButtonToolbar, Card, CardBody, Col, Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field, reduxForm, formValueSelector, reset,
} from 'redux-form';
import {
  MARAPHONS_SUCCESS,
  getMaraphonsList as getMaraphonsListAction,
  getAsyncDataForTable as getAsyncDataAction,
  maraphonClearState as maraphonClearStateAction,
  maraphonClearFlashMessage as maraphonClearFlashMessageAction,
} from '../../../../redux/actions/maraphonsAction';
import renderField from '../../../../shared/components/form/TextField';
import renderSelectField from '../../../../shared/components/form/Select';
import DataTable from '../../../../shared/components/data-table';
import { COMPLETED, WAIT } from '../../../../shared/components/data-table/submitStatuses';
import { DATA, ACTION } from '../../../../shared/components/data-table/columnTypes';
import { LINK } from '../../../../shared/components/data-table/buttonTypes';
import { FullWideNotification } from '../../../../shared/components/Notification';
import store from '../../../App/store';

const MaraphonTable = ({
  getMaraphonsList,
  getAsyncData,
  maraphonClearState,
  maraphonClearFlashMessage,
  showNotification,
  maraphonsList,
  pagination,
  loadStatus,
  asyncStatus,
  asyncData,
  flashMessage,
  resetForm,
}) => {
  const [data, setData] = useState({
    columns: [],
    buttons: [],
    directions: [],
    isFind: false,
    status: WAIT,
  });

  const clearFilters = () => {
    resetForm(MaraphonTable.FORM_NAME);
    setData({ ...data, isFind: true });
  };

  const getConditions = () => {
    const selector = formValueSelector(MaraphonTable.FORM_NAME);
    return selector(store.getState(), 'name', 'direction');
  };

  const handleFind = () => {
    setData({ ...data, isFind: true });
  };

  const show = (color, message) => showNotification({
    notification: <FullWideNotification
      color={color}
      message={message}
    />,
    position: 'full',
  });

  useEffect(() => {
    if (loadStatus === MARAPHONS_SUCCESS) {
      setData({ ...data, isFind: false });
    }
  }, [loadStatus]);

  useEffect(() => {
    if (flashMessage && flashMessage.length) {
      setTimeout(() => {
        show('success', flashMessage);
        maraphonClearFlashMessage();
      }, 1000);
    }
  }, [flashMessage]);

  useEffect(() => {
    const columns = [{
      label: '#',
      dbColumn: 'createdAt',
      listColumn: 'number',
      sortable: true,
      width: 5,
      type: DATA,
    }, {
      label: 'Название марафона',
      dbColumn: 'name',
      listColumn: 'name',
      sortable: true,
      width: 45,
      type: DATA,
    }, {
      label: 'Направление',
      dbColumn: 'direction',
      listColumn: 'direction',
      sortable: true,
      width: 15,
      type: DATA,
    }, {
      label: 'В общем списке',
      dbColumn: 'isVisible',
      listColumn: 'isVisible',
      sortable: true,
      width: 15,
      handle: cell => (cell ? 'Да' : 'Нет'),
      type: DATA,
    }, {
      label: 'Создан',
      dbColumn: 'createdAt',
      listColumn: 'createdAt',
      sortable: true,
      width: 15,
      type: DATA,
    }, {
      label: 'Действия',
      dbColumn: '_id',
      listColumn: 'id',
      sortable: false,
      width: 5,
      type: ACTION,
    }];

    const buttons = [{
      name: 'edit',
      color: 'primary',
      icon: 'edit',
      type: LINK,
      link: '/maraphons/',
      text: 'Редактировать',
    }];

    setData(({ ...data, columns, buttons }));
    getAsyncData();

    return () => {
      maraphonClearState();
    };
  }, []);

  useEffect(() => {
    if (asyncStatus === MARAPHONS_SUCCESS) {
      const { directions } = asyncData;
      setData(({ ...data, directions, status: COMPLETED }));
    }
  }, [asyncStatus]);

  return (
    <Fragment>
      {data.status === COMPLETED && (
        <Fragment>
          <Row>
            <Col md={12} lg={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Поиск по марафонам</h5>
                  </div>
                  <form className="form gap-20">
                    <Col className="p-0">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Название марафона</span>
                        <div className="form__form-group-field">
                          <Field
                            name="name"
                            component={renderField}
                            type="text"
                            placeholder="Название марафона"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="p-0">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Направление</span>
                        <div className="form__form-group-field">
                          <Field
                            name="direction"
                            component={renderSelectField}
                            placeholder="Направление"
                            options={data.directions}
                          />
                        </div>
                      </div>
                    </Col>
                  </form>
                  <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                    <Button color="primary" type="button" onClick={handleFind}>Найти</Button>
                    <Button color="secondary" type="button" onClick={clearFilters}>Очистить</Button>
                  </ButtonToolbar>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <DataTable
                addButton={{ to: '/maraphons/create', caption: 'Добавить марафон' }}
                columns={data.columns}
                buttons={data.buttons}
                getConditions={getConditions}
                getList={getMaraphonsList}
                list={maraphonsList}
                pagination={pagination}
                isFind={data.isFind}
                loadListStatus={loadStatus}
                declensions={['марафон', 'марафона', 'марафонов']}
              />
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

MaraphonTable.FORM_NAME = 'maraphones_table';

MaraphonTable.propTypes = {
  getMaraphonsList: PropTypes.func.isRequired,
  getAsyncData: PropTypes.func.isRequired,
  maraphonClearState: PropTypes.func.isRequired,
  maraphonClearFlashMessage: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  maraphonsList: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.any),
  ).isRequired,
  // eslint-disable-next-line react/require-default-props
  pagination: PropTypes.objectOf(PropTypes.any),
  loadStatus: PropTypes.string.isRequired,
  asyncStatus: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  asyncData: PropTypes.objectOf(PropTypes.any),
  flashMessage: PropTypes.string.isRequired,
  resetForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  maraphonsList: state.maraphons.list,
  pagination: state.maraphons.pagination,
  loadStatus: state.maraphons.loadListStatus,
  asyncStatus: state.maraphons.formDataStatus,
  asyncData: state.maraphons.asyncData,
  flashMessage: state.maraphons.flash,
});

const mapDispatchToProps = dispatch => ({
  getMaraphonsList: params => dispatch(getMaraphonsListAction(params)),
  getAsyncData: () => dispatch(getAsyncDataAction()),
  maraphonClearState: () => dispatch(maraphonClearStateAction()),
  maraphonClearFlashMessage: () => dispatch(maraphonClearFlashMessageAction()),
  resetForm: formName => dispatch(reset(formName)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(withRouter(MaraphonTable)));

export default reduxForm({
  form: MaraphonTable.FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(withConnect);
