const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Это поле является обязательным';
  } else if (values.name.length < 3) {
    errors.name = 'Введите не менее 3-х символов';
  }

  return errors;
};

export default validate;
