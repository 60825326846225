import moment from 'moment';
import { startSubmit, stopSubmit } from 'redux-form';
import ws from '../../ws';
import getToken from './getToken';
import PaginationPageConverter from '../../shared/classes/PaginationPageConverter';

const MARAPHON_FLOWS_PROGRESS = 'progress';
const MARAPHON_FLOWS_SUCCESS = 'success';
const MARAPHON_FLOWS_FAILED = 'failed';

const MARAPHON_FLOWS_GET_LIST_PROGRESS = 'MARAPHON_FLOWS_GET_LIST_PROGRESS';
const MARAPHON_FLOWS_GET_LIST_SUCCESS = 'MARAPHON_FLOWS_GET_LIST_SUCCESS';
const MARAPHON_FLOWS_GET_LIST_FAILED = 'MARAPHON_FLOWS_GET_LIST_FAILED';
const MARAPHON_FLOWS_FORM_SUBMIT_PROGRESS = 'MARAPHON_FLOWS_FORM_SUBMIT_PROGRESS';
const MARAPHON_FLOWS_FORM_SUBMIT_SUCCESS = 'MARAPHON_FLOWS_FORM_SUBMIT_SUCCESS';
const MARAPHON_FLOWS_FORM_SUBMIT_FAILED = 'MARAPHON_FLOWS_FORM_SUBMIT_FAILED';
const MARAPHON_FLOWS_GET_COURSE_PROGRESS = 'MARAPHON_FLOWS_GET_COURSE_PROGRESS';
const MARAPHON_FLOWS_GET_COURSE_SUCCESS = 'MARAPHON_FLOWS_GET_COURSE_SUCCESS';
const MARAPHON_FLOWS_GET_COURSE_FAILED = 'MARAPHON_FLOWS_GET_COURSE_FAILED';
const MARAPHON_FLOWS_GET_ASYNC_DATA_PROGRESS = 'MARAPHON_FLOWS_GET_ASYNC_DATA_PROGRESS';
const MARAPHON_FLOWS_GET_ASYNC_DATA_SUCCESS = 'MARAPHON_FLOWS_GET_ASYNC_DATA_SUCCESS';
const MARAPHON_FLOWS_GET_ASYNC_DATA_FAILED = 'MARAPHON_FLOWS_GET_ASYNC_DATA_FAILED';
const MARAPHON_FLOWS_CLEAR_STATE = 'MARAPHON_FLOWS_CLEAR_STATE';

const MARAPHON_FLOWS_CLEAR_FLASH_MESSAGE = 'MARAPHON_FLOWS_CLEAR_FLASH_MESSAGE';

const maraphonFlowGetListProgress = () => ({
  type: MARAPHON_FLOWS_GET_LIST_PROGRESS,
});

const maraphonFlowGetListSuccess = payload => ({
  type: MARAPHON_FLOWS_GET_LIST_SUCCESS,
  payload,
});

const maraphonFlowGetListFailed = () => ({
  type: MARAPHON_FLOWS_GET_LIST_FAILED,
});

const maraphonFlowStartFormSubmit = () => ({
  type: MARAPHON_FLOWS_FORM_SUBMIT_PROGRESS,
});

const maraphonFlowFormSubmitSuccess = payload => ({
  type: MARAPHON_FLOWS_FORM_SUBMIT_SUCCESS,
  payload,
});

const maraphonFlowFormSubmitFailed = () => ({
  type: MARAPHON_FLOWS_FORM_SUBMIT_FAILED,
});

const maraphonFlowGetFlowProgress = () => ({
  type: MARAPHON_FLOWS_GET_COURSE_PROGRESS,
});

const maraphonFlowGetFlowSuccess = payload => ({
  type: MARAPHON_FLOWS_GET_COURSE_SUCCESS,
  payload,
});

const maraphonFlowGetFlowFailed = () => ({
  type: MARAPHON_FLOWS_GET_COURSE_FAILED,
});

const maraphonFlowGetAsyncDataProgress = () => ({
  type: MARAPHON_FLOWS_GET_ASYNC_DATA_PROGRESS,
});

const maraphonFlowGetAsyncDataSuccess = payload => ({
  type: MARAPHON_FLOWS_GET_ASYNC_DATA_SUCCESS,
  payload,
});

const maraphonFlowGetAsyncDataFailed = () => ({
  type: MARAPHON_FLOWS_GET_ASYNC_DATA_FAILED,
});

const maraphonFlowClearState = () => ({
  type: MARAPHON_FLOWS_CLEAR_STATE,
});

const convertListToTable = data => new PaginationPageConverter(data, doc => ({
  id: doc.id,
  name: doc.name,
  maraphonType: doc.maraphonType,
  title: doc.maraphon.name,
  begin: doc.date.begin !== null ? moment(doc.date.begin).locale('ru').format('DD MMM YY г.') : 'отсутствует',
  end: doc.date.end !== null ? moment(doc.date.end).locale('ru').format('DD MMM YY г.') : 'отсутствует',
  createdAt: moment(doc.createdAt).locale('ru').format('DD MMM YY г., HH:mm'),
})).getConvertedData();

const getMaraphonFlowsList = params => (dispatch) => {
  const token = getToken();
  dispatch(maraphonFlowGetListProgress());

  ws.emit('api/academy/maraphonFlows/list', { token, payload: params }, (data) => {
    const { status, payload } = data;
    let action;

    if (status === 'ok') {
      action = maraphonFlowGetListSuccess(convertListToTable(payload.data));
    } else {
      action = maraphonFlowGetListFailed();
    }

    dispatch(action);
  });
};

const convertSetFlowToDB = (data) => {
  const {
    name,
    maraphon,
    maraphonType,
    dateBegin,
    dateEnd,
    dateAvailable,
    ending,
    days,
    packages: packageList,
    curators: curatorList,
    tasks: taskList,
    modules: modulesList,
  } = data;

  const date = maraphonType.value !== 'flow' ? {
    begin: null, end: null, available: null, ending, days,
  } : {
    begin: dateBegin.toUTCString(),
    end: dateEnd.toUTCString(),
    available: dateAvailable.toUTCString(),
    ending: null,
    days: null,
  };

  const packages = (() => {
    const packageArray = [];

    for (let i = 0; i < packageList.length; i += 1) {
      const bonuses = [];

      if (typeof packageList[i].bonuses !== 'undefined') {
        for (let j = 0; j < packageList[i].bonuses.length; j += 1) {
          const { value, selected } = packageList[i].bonuses[j].bonus;
          const bonus = { flow: value };
          const packs = [];

          if (selected) {
            const keys = Object.keys(selected);

            for (let k = 0; k < keys.length; k += 1) {
              if (selected[keys[k]]) {
                packs.push(keys[k]);
              }
            }
          }

          bonus.packages = packs;
          bonuses.push(bonus);
        }
      }

      packageArray.push({
        _id: packageList[i].id,
        name: packageList[i].name,
        amoId: packageList[i].amoId,
        questions: {
          isShow: typeof packageList[i].isShowQuestions === 'undefined' ? false : packageList[i].isShowQuestions,
          option: typeof packageList[i].isShowQuestions === 'undefined' || !packageList[i].isShowQuestions
            ? null
            : packageList[i].questionOption,
        },
        bonuses,
      });
    }

    return packageArray;
  })();

  /* eslint no-underscore-dangle: 0 */
  const availablePackages = packages.map(item => item._id);

  const curators = (() => {
    const curatorArray = [];

    if (Array.isArray(curatorList)) {
      for (let i = 0; i < curatorList.length; i += 1) {
        curatorArray.push(curatorList[i].curator.value);
      }
    }

    return curatorArray;
  })();

  const tasks = (() => {
    const taskArray = [];

    if (Array.isArray(taskList)) {
      for (let i = 0; i < taskList.length; i += 1) {
        const taskObject = {
          task: taskList[i].id,
          name: 'name' in taskList[i] ? taskList[i].name : null,
          day: taskList[i].day.value,
          isBonuses: 'isBonuses' in taskList[i] ? taskList[i].isBonuses : false,
          isActive: 'isActive' in taskList[i] ? taskList[i].isActive : false,
          isShowQuestions: 'isShowQuestions' in taskList[i] ? taskList[i].isShowQuestions : false,
          isHiddenOnShowcase: 'isHiddenOnShowcase' in taskList[i] ? taskList[i].isHiddenOnShowcase : true,
        };

        const { package: taskPackage } = taskList[i];
        const packageArray = [];

        if (typeof taskPackage === 'object') {
          const packageKeys = Object.keys(taskPackage);

          for (let j = 0; j < packageKeys.length; j += 1) {
            if (taskPackage[packageKeys[j]] && availablePackages.indexOf(packageKeys[j]) !== -1) {
              packageArray.push(packageKeys[j]);
            }
          }
        }

        taskObject.package = packageArray;
        taskArray.push(taskObject);
      }
    }

    return taskArray;
  })();

  const modules = modulesList
    ? modulesList.map(module => ({
      name: module.name,
      tasks: module.tasks.map(task => (typeof task.task === 'string' ? task.task : task.task.value)),
    }))
    : [];

  return {
    name,
    maraphon: typeof maraphon === 'string' ? maraphon : maraphon.value,
    maraphonType: typeof maraphonType === 'string' ? maraphonType : maraphonType.value,
    date,
    packages,
    curators,
    tasks,
    modules,
  };
};

const createMaraphonFlow = async (data, dispatch, props) => {
  const token = getToken();
  const { form, reset } = props;
  const convertedData = convertSetFlowToDB(data);

  dispatch(startSubmit(form));
  dispatch(maraphonFlowStartFormSubmit());

  ws.emit('api/academy/maraphonFlows/create', { token, payload: { convertedData } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      reset();
      dispatch(stopSubmit(form));
      dispatch(maraphonFlowFormSubmitSuccess('Новый поток был успешно создан.'));
      props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(maraphonFlowFormSubmitFailed());
    }
  });
};

const convertDBFlowToEdit = (payload) => {
  const { flow: flowFromDB } = payload;
  const { date } = flowFromDB;

  const flow = {
    id: flowFromDB.id,
    name: flowFromDB.name,
    maraphon: {
      value: flowFromDB.maraphon.id,
      label: flowFromDB.maraphon.name,
      tasks: flowFromDB.maraphon.tasks,
    },
    maraphonType: {
      value: flowFromDB.maraphonType,
      label: flowFromDB.maraphonType === 'flow' ? 'Поток' : 'Моментальный',
    },
    dateBegin: date.begin !== null ? new Date(date.begin) : null,
    dateEnd: date.end !== null ? new Date(date.end) : null,
    dateAvailable: date.available !== null ? new Date(date.available) : null,
    ending: date.ending !== null ? date.ending : null,
    days: date.days !== null ? date.days : null,
    packages: flowFromDB.packages.map(item => ({
      id: item.id,
      name: item.name,
      amoId: item.amoId,
      isShowQuestions: item.questions.isShow,
      questionOption: item.questions.option,
      bonuses: item.bonuses.map(bonus => ({
        bonus: {
          value: bonus.id,
          label: bonus.name,
          checkboxes: bonus.packages,
          selected: bonus.selected.reduce((o, key) => ({ ...o, [key]: true }), {}),
        },
      })),
    })),
    curators: flowFromDB.curators.map(item => ({ curator: { value: item.id, label: item.name } })),
    tasks: flowFromDB.tasks.map((item) => {
      const task = {
        id: item.task.id,
        original: item.task.name,
        isBonuses: item.isBonuses,
        isActive: item.isActive,
        isShowQuestions: item.isShowQuestions,
        isHiddenOnShowcase: item.isHiddenOnShowcase,
        day: { value: String(item.day), label: null },
        package: (() => {
          const packages = {};

          for (let i = 0; i < item.package.length; i += 1) {
            packages[item.package[i]] = true;
          }

          return packages;
        })(),
      };

      if (item.name) {
        task.name = item.name;
      }

      return task;
    }),
    modules: flowFromDB.modules.map(module => ({
      name: module.name,
      tasks: module.tasks.reduce((result, task) => {
        for (let i = 0; i < flowFromDB.tasks.length; i += 1) {
          if (flowFromDB.tasks[i].task.id === task) {
            result.push({
              task: {
                value: task,
                label: flowFromDB.tasks[i].name ? flowFromDB.tasks[i].name : flowFromDB.tasks[i].task.name,
              },
            });
            break;
          }
        }
        return result;
      }, []),
    })),
  };

  return { flow };
};

const getMaraphonFlow = flowId => async (dispatch) => {
  const token = getToken();
  dispatch(maraphonFlowGetFlowProgress());

  ws.emit('api/academy/maraphonFlows/getById', { token, payload: { flowId } }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = maraphonFlowGetFlowSuccess(convertDBFlowToEdit(payload));
    } else {
      action = maraphonFlowGetFlowFailed();
    }

    dispatch(action);
  });
};

const updateMaraphonFlow = async (data, dispatch, props) => {
  const token = getToken();
  const { id } = data;
  const { form } = props;
  const convertedData = convertSetFlowToDB(data);

  dispatch(startSubmit(form));
  dispatch(maraphonFlowStartFormSubmit());

  ws.emit('api/academy/maraphonFlows/updateById', { token, payload: { flowId: id, convertedData } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(stopSubmit(form));
      dispatch(maraphonFlowFormSubmitSuccess('Поток был успешно отредактирован.'));
      props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(maraphonFlowFormSubmitFailed());
    }
  });
};

const transformAsyncData = data => ({
  maraphons: data.maraphons.map(task => ({ value: task.id, label: task.name, tasks: task.tasks })),
  tasks: data.tasks.map(task => ({ value: task.id, label: task.name })),
  curators: data.curators.map(task => ({ value: task.id, label: task.name })),
  instantFlows: data.instantFlows.map(task => ({ value: task.id, label: task.name, checkboxes: task.checkboxes })),
});

const getAsyncData = () => async (dispatch) => {
  const token = getToken();
  dispatch(maraphonFlowGetAsyncDataProgress());

  ws.emit('api/academy/maraphonFlows/getAsyncData', { token }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = maraphonFlowGetAsyncDataSuccess(transformAsyncData(payload));
    } else {
      action = maraphonFlowGetAsyncDataFailed();
    }

    dispatch(action);
  });
};

const maraphonFlowClearFlashMessage = () => ({
  type: MARAPHON_FLOWS_CLEAR_FLASH_MESSAGE,
});

export {
  MARAPHON_FLOWS_PROGRESS,
  MARAPHON_FLOWS_SUCCESS,
  MARAPHON_FLOWS_FAILED,
  MARAPHON_FLOWS_GET_LIST_PROGRESS,
  MARAPHON_FLOWS_GET_LIST_SUCCESS,
  MARAPHON_FLOWS_GET_LIST_FAILED,
  MARAPHON_FLOWS_FORM_SUBMIT_PROGRESS,
  MARAPHON_FLOWS_FORM_SUBMIT_SUCCESS,
  MARAPHON_FLOWS_FORM_SUBMIT_FAILED,
  MARAPHON_FLOWS_GET_COURSE_PROGRESS,
  MARAPHON_FLOWS_GET_COURSE_SUCCESS,
  MARAPHON_FLOWS_GET_COURSE_FAILED,
  MARAPHON_FLOWS_GET_ASYNC_DATA_PROGRESS,
  MARAPHON_FLOWS_GET_ASYNC_DATA_SUCCESS,
  MARAPHON_FLOWS_GET_ASYNC_DATA_FAILED,
  MARAPHON_FLOWS_CLEAR_STATE,
  MARAPHON_FLOWS_CLEAR_FLASH_MESSAGE,
  maraphonFlowGetListProgress,
  maraphonFlowGetListSuccess,
  maraphonFlowGetListFailed,
  maraphonFlowStartFormSubmit,
  maraphonFlowFormSubmitSuccess,
  maraphonFlowFormSubmitFailed,
  maraphonFlowGetFlowProgress,
  maraphonFlowGetFlowSuccess,
  maraphonFlowGetFlowFailed,
  maraphonFlowGetAsyncDataProgress,
  maraphonFlowGetAsyncDataSuccess,
  maraphonFlowGetAsyncDataFailed,
  maraphonFlowClearState,
  getMaraphonFlowsList,
  createMaraphonFlow,
  getMaraphonFlow,
  updateMaraphonFlow,
  getAsyncData,
  maraphonFlowClearFlashMessage,
};
