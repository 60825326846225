import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { FieldArray, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import RenderChecklist from '../../components/RenderChecklist';
import validate from '../../validate';

class WizardFormFive extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit, previousPage } = this.props;

    return (
      <form className="form form--horizontal wizard__form wizard__form__lg" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Составьте и отсортируйте факторы которые нужно проверить</h3>
        <FieldArray name="checklist" component={RenderChecklist} />
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={previousPage}>Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next">Следующий шаг</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'themes_edit_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: false,
  validate,
})(WizardFormFive);
