import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import {
  Button, ButtonToolbar, Card, CardBody, Col, Row, Badge,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field, reduxForm, formValueSelector, reset,
} from 'redux-form';
import {
  QUESTIONS_SUCCESS,
  getQuestionsList as getQuestionsListAction,
  questionClearState as questionClearStateAction,
  questionClearFlashMessage as questionClearFlashMessageAction,
} from '../../../../redux/actions/questionsAction';
import renderField from '../../../../shared/components/form/TextField';
import renderSelectField from '../../../../shared/components/form/Select';
import DataTable from '../../../../shared/components/data-table';
import { COMPLETED, WAIT } from '../../../../shared/components/data-table/submitStatuses';
import { DATA, ACTION } from '../../../../shared/components/data-table/columnTypes';
import { LINK } from '../../../../shared/components/data-table/buttonTypes';
import { FullWideNotification } from '../../../../shared/components/Notification';
import store from '../../../App/store';

const QuestionTable = ({
  getQuestionsList,
  questionClearState,
  questionClearFlashMessage,
  showNotification,
  questionsList,
  pagination,
  loadStatus,
  flashMessage,
  resetForm,
}) => {
  const [data, setData] = useState({
    columns: [],
    buttons: [],
    types: [],
    isFind: false,
    status: WAIT,
  });

  const clearFilters = () => {
    resetForm(QuestionTable.FORM_NAME);
    setData({ ...data, isFind: true });
  };

  const getConditions = () => {
    const selector = formValueSelector(QuestionTable.FORM_NAME);
    return selector(store.getState(), 'title', 'type');
  };

  const handleFind = () => {
    setData({ ...data, isFind: true });
  };

  const show = (color, message) => showNotification({
    notification: <FullWideNotification
      color={color}
      message={message}
    />,
    position: 'full',
  });

  useEffect(() => {
    if (loadStatus === QUESTIONS_SUCCESS) {
      setData({ ...data, isFind: false });
    }
  }, [loadStatus]);

  useEffect(() => {
    if (flashMessage && flashMessage.length) {
      setTimeout(() => {
        show('success', flashMessage);
        questionClearFlashMessage();
      }, 1000);
    }
  }, [flashMessage]);

  useEffect(() => {
    const types = [{
      value: '1',
      label: 'Только неотвеченные вопросы',
    }, {
      value: '2',
      label: 'Только новые вопросы',
    }, {
      value: '3',
      label: 'Только новые комментарии',
    }];

    const columns = [{
      label: '#',
      dbColumn: 'createdAt',
      listColumn: 'number',
      sortable: true,
      width: 5,
      type: DATA,
    }, {
      label: 'Название вопроса',
      dbColumn: 'title',
      listColumn: 'title',
      sortable: false,
      width: 25,
      type: DATA,
    }, {
      label: 'Статус',
      dbColumn: 'status',
      listColumn: 'status',
      sortable: true,
      width: 15,
      handle: (status) => {
        let badge;

        switch (status) {
          case 'new':
            badge = <Badge color="danger">Новый вопрос</Badge>;
            break;
          case 'awaiting':
            badge = <Badge color="warning">Новый комментарий</Badge>;
            break;
          case 'edited':
            badge = <Badge color="secondary">Редактируется</Badge>;
            break;
          default:
            badge = <Badge color="success">Был дан ответ</Badge>;
            break;
        }

        return badge;
      },
      type: DATA,
    }, {
      label: 'Ученик',
      dbColumn: 'name',
      listColumn: 'name',
      sortable: false,
      width: 15,
      type: DATA,
    }, {
      label: 'Название потока',
      dbColumn: 'flow',
      listColumn: 'flow',
      sortable: true,
      width: 20,
      type: DATA,
    }, {
      label: 'Создан',
      dbColumn: 'createdAt',
      listColumn: 'createdAt',
      sortable: true,
      width: 15,
      type: DATA,
    }, {
      label: 'Действия',
      dbColumn: '_id',
      listColumn: 'id',
      sortable: false,
      width: 5,
      type: ACTION,
    }];

    const buttons = [{
      name: 'edit',
      color: 'primary',
      icon: 'edit',
      type: LINK,
      link: '/questions/',
      text: 'Редактировать',
    }];

    setData(({
      ...data, columns, buttons, types, status: COMPLETED,
    }));

    return () => {
      questionClearState();
    };
  }, []);

  return (
    <Fragment>
      {data.status === COMPLETED && (
        <Fragment>
          <Row>
            <Col md={12} lg={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Поиск по вопросам</h5>
                  </div>
                  <form className="form gap-20">
                    <Col className="p-0">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Название вопроса</span>
                        <div className="form__form-group-field">
                          <Field
                            name="title"
                            component={renderField}
                            type="text"
                            placeholder="Название вопроса"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="p-0">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Статус</span>
                        <div className="form__form-group-field">
                          <Field
                            name="type"
                            component={renderSelectField}
                            placeholder="Статус вопроса"
                            options={data.types}
                          />
                        </div>
                      </div>
                    </Col>
                  </form>
                  <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                    <Button color="primary" type="button" onClick={handleFind}>Найти</Button>
                    <Button color="secondary" type="button" onClick={clearFilters}>Очистить</Button>
                  </ButtonToolbar>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <DataTable
                addButton={{ to: '/questions/create', caption: 'Добавить вопрос' }}
                columns={data.columns}
                buttons={data.buttons}
                getConditions={getConditions}
                getList={getQuestionsList}
                list={questionsList}
                pagination={pagination}
                isFind={data.isFind}
                loadListStatus={loadStatus}
                declensions={['вопрос', 'вопроса', 'вопросов']}
              />
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

QuestionTable.FORM_NAME = 'questions_table';

QuestionTable.propTypes = {
  getQuestionsList: PropTypes.func.isRequired,
  questionClearState: PropTypes.func.isRequired,
  questionClearFlashMessage: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  questionsList: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.any),
  ).isRequired,
  // eslint-disable-next-line react/require-default-props
  pagination: PropTypes.objectOf(PropTypes.any),
  loadStatus: PropTypes.string.isRequired,
  flashMessage: PropTypes.string.isRequired,
  resetForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  questionsList: state.questions.list,
  pagination: state.questions.pagination,
  loadStatus: state.questions.loadListStatus,
  flashMessage: state.questions.flash,
});

const mapDispatchToProps = dispatch => ({
  getQuestionsList: params => dispatch(getQuestionsListAction(params)),
  questionClearState: () => dispatch(questionClearStateAction()),
  questionClearFlashMessage: () => dispatch(questionClearFlashMessageAction()),
  resetForm: formName => dispatch(reset(formName)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(withRouter(QuestionTable)));

export default reduxForm({
  form: QuestionTable.FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(withConnect);
