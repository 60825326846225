import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field } from 'redux-form';
import ArrowUpBold from 'mdi-react/ArrowUpBoldIcon';
import ArrowDownBoldIcon from 'mdi-react/ArrowDownBoldIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import renderTextarea from '../../../shared/components/form/TextareaField';
import hiddenField from '../../../shared/components/form/HiddenField';

const buttonTop = {
  marginTop: '15px',
};

class RenderChecklistValues extends PureComponent {
  static propTypes = {
    fields: PropTypes.objectOf(PropTypes.any).isRequired,
    meta: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static moveToUp(fields, index) {
    if (index > 0) {
      fields.swap(index, index - 1);
    }
  }

  static moveToDown(fields, index) {
    if (fields.length - 1 !== index) {
      fields.swap(index, index + 1);
    }
  }

  render() {
    const { fields, meta: { error, submitFailed } } = this.props;
    const { moveToUp, moveToDown } = RenderChecklistValues;

    return (
      <div className="form__form-group">
        <ButtonToolbar style={buttonTop}>
          <Button color="success" size="sm" onClick={() => fields.push({})}>Добавить пункт</Button>
        </ButtonToolbar>
        {submitFailed && error
        && (
          <div className="form__form-group">
            <div>
              <span className="form__form-group-error">{error}</span>
            </div>
          </div>
        )}
        {fields.map((content, index) => (
          <div key={index} className="form__form-group">
            <span className="form__form-group-label">Пункт # {index + 1}</span>
            <div className="form__form-group-field">
              <Field
                name={`${content}.content`}
                component={renderTextarea}
                placeholder="Название пункта"
              />
              <Field
                name={`${content}.id`}
                component={hiddenField}
              />
              <div className="form__form-group-icon">
                <ArrowUpBold onClick={() => moveToUp(fields, index)} />
              </div>
              <div className="form__form-group-icon">
                <ArrowDownBoldIcon onClick={() => moveToDown(fields, index)} />
              </div>
              <div className="form__form-group-icon">
                <TrashCanOutlineIcon onClick={() => fields.remove(index)} />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default RenderChecklistValues;
