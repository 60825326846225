import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { FullWideNotification } from '../Notification';

const bytesToSize = (bytes) => {
  const sizes = ['Байта', 'Кб', 'Мб', 'Гб', 'Тб'];
  if (!bytes) {
    return '0 Байт';
  }
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${Math.round((bytes / (1024 ** i)), 2)} ${sizes[i]}`;
};

class DropZoneField extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    customHeight: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
      })),
    ]).isRequired,
    showNotification: PropTypes.func.isRequired,
    mimes: PropTypes.arrayOf(PropTypes.any).isRequired,
    maxFilesize: PropTypes.number.isRequired,
  };

  static defaultProps = {
    customHeight: false,
  };

  constructor() {
    super();
    this.state = {};
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(file) {
    const { onChange, mimes, maxFilesize } = this.props;
    let isNotMimes = false;
    let isLargesMaxFilesize = false;

    onChange(file.map((fl) => {
      if (mimes.length) {
        if (mimes.indexOf(fl.type) === -1) {
          isNotMimes = true;
        }
      }

      if (maxFilesize) {
        if (fl.size > maxFilesize) {
          isLargesMaxFilesize = true;
        }
      }

      return Object.assign(fl, {
        preview: URL.createObjectURL(fl),
      });
    }));

    if (isNotMimes) {
      this.show('danger', 'Вы выбрали файл, который не содержит изображение.');
    }

    if (!isNotMimes && isLargesMaxFilesize) {
      this.show('danger', `Файл превышает максимально допустимый размер в ${bytesToSize(maxFilesize)}`);
    }

    if (isNotMimes || isLargesMaxFilesize) {
      onChange(file.filter((val, i) => i !== 0));
    }
  }

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message={message}
      />,
      position: 'full',
    });
  };

  removeFile(index, e) {
    const { onChange, value } = this.props;
    e.preventDefault();
    onChange(value.filter((val, i) => i !== index));
  }

  render() {
    const {
      value, customHeight, name,
    } = this.props;

    const files = value;

    return (
      <div className={`dropzone dropzone--single${customHeight ? ' dropzone--custom-height' : ''}`}>
        <Dropzone
          accept="image/jpeg, image/png, image/gif"
          name={name}
          multiple={false}
          onDrop={(fileToUpload) => {
            this.onDrop(fileToUpload);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="dropzone__input">
              {(!files || files.length === 0)
              && (
              <div className="dropzone__drop-here">
                <span className="lnr lnr-upload" /> Перетащите изображение сюда для загрузки
              </div>
              )}
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>
        {files && Array.isArray(files) && files.length > 0
        && (
        <aside className="dropzone__img">
          <img src={files[0].preview} alt="drop-img" />
          <p className="dropzone__img-name">{files[0].name}</p>
          <button className="dropzone__img-delete" type="button" onClick={e => this.removeFile(0, e)}>
            Очистить
          </button>
        </aside>
        )}
      </div>
    );
  }
}

const renderDropZoneField = (props) => {
  const {
    input,
    customHeight,
    showNotification,
    mimes,
    maxFilesize,
  } = props;
  return (
    <DropZoneField
      {...input}
      customHeight={customHeight}
      showNotification={showNotification}
      mimes={mimes}
      maxFilesize={maxFilesize}
    />
  );
};

renderDropZoneField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  customHeight: PropTypes.bool,
  showNotification: PropTypes.func.isRequired,
  mimes: PropTypes.arrayOf(PropTypes.any),
  maxFilesize: PropTypes.number,
};

renderDropZoneField.defaultProps = {
  customHeight: false,
  mimes: [],
  maxFilesize: 0,
};

export default renderDropZoneField;
