import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';

const TinyMCE = ({
  input, height, menu,
}) => (
  <Editor
    apiKey="ehwl4nnyjy4ii1r1j5w2wqh0geagu4wv0d3i1gkgt8x6u7kr"
    init={{
      height,
      width: '100%',
      language: 'ru',
      menu: {
        shortcodes: {
          title: 'Шорткоды',
          // eslint-disable-next-line max-len
          items: 'closedContent unclosedContent | hiddenContent unhiddenContent | demoContent undemoContent | blurredContent',
        },
      },
      menubar: menu ? 'file edit view insert format tools table shortcodes help' : false,
      iconfonts_selector: '.fa, .fab, .fal, .far, .fas, .glyphicon', // optional (default shown)
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen hr',
        'insertdatetime media table paste code help wordcount template',
      ],
      external_plugins: {
        iconfonts: '/tinymce/plugins/iconfonts/plugin.min.js',
      },
      setup(editor) {
        editor.ui.registry.addMenuItem('closedContent', {
          text: 'Заблюренный контент, начало [closed]',
          // eslint-disable-next-line no-unused-vars
          onAction(_) {
            editor.insertContent('[closed]');
          },
        });
        editor.ui.registry.addMenuItem('unclosedContent', {
          text: 'Заблюренный контент, окончание [/closed]',
          // eslint-disable-next-line no-unused-vars
          onAction(_) {
            editor.insertContent('[/closed]');
          },
        });
        editor.ui.registry.addMenuItem('hiddenContent', {
          text: 'Скрытый контент, начало [hidden]',
          // eslint-disable-next-line no-unused-vars
          onAction(_) {
            editor.insertContent('[hidden]');
          },
        });
        editor.ui.registry.addMenuItem('unhiddenContent', {
          text: 'Скрытый контент, окончание [/hidden]',
          // eslint-disable-next-line no-unused-vars
          onAction(_) {
            editor.insertContent('[/hidden]');
          },
        });
        editor.ui.registry.addMenuItem('demoContent', {
          text: 'Контент только на витрине, начало [onlydemo]',
          // eslint-disable-next-line no-unused-vars
          onAction(_) {
            editor.insertContent('[onlydemo]');
          },
        });
        editor.ui.registry.addMenuItem('undemoContent', {
          text: 'Контент только на витрине, окончание [/onlydemo]',
          // eslint-disable-next-line no-unused-vars
          onAction(_) {
            editor.insertContent('[/onlydemo]');
          },
        });
        editor.ui.registry.addMenuItem('blurredContent', {
          text: 'Заблюренный текст [blurred/]',
          // eslint-disable-next-line no-unused-vars
          onAction(_) {
            editor.insertContent(`[blurred]${editor.selection.getContent()}[/blurred]`);
          },
        });
        editor.ui.registry.addButton('highlighted', {
          icon: 'permanent-pen',
          tooltip: 'Пометить выделенный текст',
          // eslint-disable-next-line no-unused-vars
          onAction(_) {
            editor.insertContent(`<mark>${editor.selection.getContent()}</mark>`);
          },
        });
        editor.ui.registry.addButton('newp', {
          icon: 'rtl',
          tooltip: 'Вставить параграф в конец документа',
          // eslint-disable-next-line no-unused-vars
          onAction(_) {
            editor.setContent(`${editor.getContent()}<p>Текст...</p>`);
          },
        });
      },
      content_css: [
        'https://admin.karpachoff.academy/tinymce/styles/styles.css',
        'https://use.fontawesome.com/releases/v5.8.1/css/all.css',
      ],
      content_css_cors: true,
      link_class_list: [
        { title: 'None', value: '' },
        { title: 'Открытая ссылка на витрине', value: 'showcase_opened_link' },
      ],
      visualblocks_default_state: true,
      templates: [
        {
          title: 'Заголовок H1 с иконкой "Редактировать"',
          description: 'Добавляет на страницу заголовок H1 c иконкой "Редактировать"',
          content: '<h1 class="icon edit">Заголовок</h1>',
        },
        {
          title: 'Заголовок H1 с иконкой "Видео"',
          description: 'Добавляет на страницу заголовок H1 c иконкой "Видео"',
          content: '<h1 class="icon video">Заголовок</h1>',
        },
        {
          title: 'Заголовок H2 с порядковым номером',
          description: 'Добавляет на страницу заголовок H2 с порядковым номером',
          content: '<h2 class="counter" data-start="1">Заголовок</h2>',
        },
        {
          title: 'Блок "Важно"',
          description: 'Добавляет на страницу блок "Важно"',
          content: '<div class="section important"><h4>Заголовок</h4><p>Текст...</p></div>',
        },
        {
          title: 'Блок "Интересно"',
          description: 'Добавляет на страницу блок "Интересно"',
          content: '<div class="section interesting"><h4>Заголовок</h4><p>Текст...</p></div>',
        },
        {
          title: 'Блок "Инфо"',
          description: 'Добавляет на страницу блок "Инфо"',
          content: '<div class="section info"><h4>Заголовок</h4><p>Текст...</p></div>',
        },
        {
          title: 'Нумерованный список с параграфами',
          description: 'Добавляет на страницу нумерованный список с параграфами',
          content: '<ol class="big-list">'
            + '<li><h4>Заголовок</h4><p>Текст...</p></li>'
            + '<li><h4>Заголовок</h4><p>Текст...</p></li>'
            + '<li><h4>Заголовок</h4><p>Текст...</p></li>'
            + '<li><h4>Заголовок</h4><p>Текст...</p></li>'
            + '<li><h4>Заголовок</h4><p>Текст...</p></li>'
            + '<li><h4>Заголовок</h4><p>Текст...</p></li>'
            + '<li><h4>Заголовок</h4><p>Текст...</p></li>'
            + '<li><h4>Заголовок</h4><p>Текст...</p></li>'
            + '</ol>',
        },
        {
          title: 'Маркированный список внутри нумерованного списка с параграфом',
          description: 'Добавляет на страницу маркированный список внутри нумерованного списка с параграфом',
          content: '<ul><li>Текст...</li></ul>',
        },
        {
          title: 'Компактный нумерованный список',
          description: 'Добавляет на страницу компактный нумерованный список',
          content: '<ol class="compact"><li>Текст...</li></ol>',
        },
        {
          title: 'Компактный маркированный список',
          description: 'Добавляет на страницу компактный маркированный список',
          content: '<ul class="compact"><li>Текст...</li></ul>',
        },
        {
          title: 'Длинный видео плеер',
          description: 'Добавляет на страницу элемент P с классом для длинного видео плеера',
          content: '<p class="wistia_long_video">%video1%</p>',
        },
      ],
      toolbar:
        'undo redo | formatselect template | bold italic underline | backcolor forecolor highlighted |'
        + 'link unlink | image table hr newp | bullist numlist | blockquote |'
        + 'alignleft aligncenter alignright alignjustify | removeformat |'
        + 'code fullscreen | help',
    }}
    value={input.value}
    onEditorChange={
      content => input.onChange(content)
    }
  />
);

TinyMCE.defaultProps = {
  height: 500,
  menu: true,
};

TinyMCE.propTypes = {
  input: PropTypes.shape().isRequired,
  height: PropTypes.number,
  menu: PropTypes.bool,
};

export default TinyMCE;
