import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  PAGES_SUCCESS, PAGES_FAILED,
  getPage as getPageAction,
  pageClearState as pageClearStateAction,
  updatePage as updatePageAction,
} from '../../../../redux/actions/pagesAction';
import ButtonToNavigate from '../../../../shared/components/form/ButtonToNavigate';
import renderEditor from '../../../../shared/components/tinymce/TinyMCE';
import renderField from '../../../../shared/components/form/TextField';
import { FullWideNotification } from '../../../../shared/components/Notification';
import RenderVideos from '../../components/RenderVideos';
import validate from '../../validate';
import asyncValidate from '../../asyncValidate';

class EditForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    submitStatus: PropTypes.string.isRequired,
    loadStatus: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    getPage: PropTypes.func.isRequired,
    pageClearState: PropTypes.func.isRequired,
  };

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { getPage, match } = this.props;
    const { pageId } = match.params;

    getPage(pageId);
  }

  componentWillReceiveProps(nextProps) {
    const { submitStatus, loadStatus, history } = nextProps;

    if (loadStatus === PAGES_FAILED) {
      history.push('/404');
    }

    if (submitStatus === PAGES_SUCCESS) {
      history.push('/pages');
    } else if (submitStatus === PAGES_FAILED) {
      this.show('danger', 'Произошла непредвиденная ошибка. Обратитесь к разработчикам.');
    }
  }

  componentWillUnmount() {
    const { pageClearState } = this.props;
    pageClearState();
  }

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message={message}
      />,
      position: 'full',
    });
  };

  render() {
    const {
      handleSubmit, submitting, t, loadStatus,
    } = this.props;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">ФОРМА РЕДАКТИРОВАНИЯ СТРАНИЦЫ</h5>
              <h5 className="subhead">{t('maretials.forms.create_form_description')}</h5>
            </div>
            {loadStatus === PAGES_SUCCESS ? (
              <form className="form" onSubmit={handleSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Название страницы</span>
                  <div className="form__form-group-field">
                    <Field
                      name="name"
                      component={renderField}
                      type="text"
                      placeholder="Название страницы"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Url страницы</span>
                  <div className="form__form-group-field">
                    <Field
                      name="url"
                      component={renderField}
                      type="text"
                      placeholder="Url страницы"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Список видео материалов</span>
                  <div className="form__form-group-field">
                    <FieldArray name="videos" component={RenderVideos} />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Описание</span>
                  <div className="form__form-group-field">
                    <Field
                      name="view"
                      component={renderEditor}
                    />
                  </div>
                </div>
                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit" disabled={submitting}>Редактировать</Button>
                  <Route
                    path="/"
                    render={props => <ButtonToNavigate {...props} title="Отмена" path="/pages" />}
                  />
                </ButtonToolbar>
              </form>
            ) : null}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: state.pages.active,
  submitStatus: state.pages.submitStatus,
  loadStatus: state.pages.loadActiveStatus,
});

const mapDispatchToProps = dispatch => ({
  getPage: pageId => dispatch(getPageAction(pageId)),
  pageClearState: () => dispatch(pageClearStateAction()),
});

const EditFormComponent = reduxForm({
  form: 'pages_edit_form', // a unique identifier for this form
  validate,
  asyncValidate,
  asyncBlurFields: ['url'],
  enableReinitialize: true,
  onSubmit: updatePageAction,
})(withTranslation('common')(withRouter(EditForm)));

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditFormComponent);
