import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';

import MaraphonTablePage from '../../../Maraphons/MaraphonTable/index';
import MaraphonCreatePage from '../../../Maraphons/MaraphonCreate/index';
import MaraphonEditPage from '../../../Maraphons/MaraphonEdit/index';

export default () => (
  <Switch>
    <ProtectedRoute path="/maraphons" exact component={MaraphonTablePage} />
    <ProtectedRoute path="/maraphons/create" component={MaraphonCreatePage} />
    <ProtectedRoute path="/maraphons/:maraphonId" exact component={MaraphonEditPage} />
  </Switch>
);
