import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';

import CourseTablePage from '../../../Courses/CourseTable/index';
import CourseCreatePage from '../../../Courses/CourseCreate/index';
import CourseEditPage from '../../../Courses/CourseEdit/index';

export default () => (
  <Switch>
    <ProtectedRoute path="/courses" exact component={CourseTablePage} />
    <ProtectedRoute path="/courses/create" component={CourseCreatePage} />
    <ProtectedRoute path="/courses/:courseId" exact component={CourseEditPage} />
  </Switch>
);
