import moment from 'moment';
import ws from '../../ws';
import getToken from './getToken';
import PaginationPageConverter from '../../shared/classes/PaginationPageConverter';

const DUPLICATES_PROGRESS = 'progress';
const DUPLICATES_SUCCESS = 'success';
const DUPLICATES_FAILED = 'failed';

const DUPLICATES_GET_LIST_PROGRESS = 'DUPLICATES_GET_LIST_PROGRESS';
const DUPLICATES_GET_LIST_SUCCESS = 'DUPLICATES_GET_LIST_SUCCESS';
const DUPLICATES_GET_LIST_FAILED = 'DUPLICATES_GET_LIST_FAILED';
const DUPLICATES_GET_DUPLICATES_PROGRESS = 'DUPLICATES_GET_DUPLICATES_PROGRESS';
const DUPLICATES_GET_DUPLICATES_SUCCESS = 'DUPLICATES_GET_DUPLICATES_SUCCESS';
const DUPLICATES_GET_DUPLICATES_FAILED = 'DUPLICATES_GET_DUPLICATES_FAILED';
const DUPLICATES_CLEAR_STATE = 'DUPLICATES_CLEAR_STATE';

const DUPLICATES_CLEAR_FLASH_MESSAGE = 'DUPLICATES_CLEAR_FLASH_MESSAGE';

const duplicateGetListProgress = () => ({
  type: DUPLICATES_GET_LIST_PROGRESS,
});

const duplicateGetListSuccess = payload => ({
  type: DUPLICATES_GET_LIST_SUCCESS,
  payload,
});

const duplicateGetListFailed = () => ({
  type: DUPLICATES_GET_LIST_FAILED,
});

const duplicateGetDuplicatesProgress = () => ({
  type: DUPLICATES_GET_DUPLICATES_PROGRESS,
});

const duplicateGetDuplicatesSuccess = payload => ({
  type: DUPLICATES_GET_DUPLICATES_SUCCESS,
  payload,
});

const duplicateGetDuplicatesFailed = () => ({
  type: DUPLICATES_GET_DUPLICATES_FAILED,
});

const duplicateClearState = () => ({
  type: DUPLICATES_CLEAR_STATE,
});

const convertListToTable = data => new PaginationPageConverter(data, doc => ({
  id: doc.id,
  baseId: doc.id,
  email: doc.local.email,
  createdAt: moment(doc.createdAt).locale('ru').format('DD MMM YY г., HH:mm'),
})).getConvertedData();

const getDuplicatesList = params => (dispatch) => {
  const token = getToken();
  dispatch(duplicateGetListProgress());

  ws.emit('api/academy/duplicates/list', { token, payload: params }, (data) => {
    const { status, payload } = data;
    let action;

    if (status === 'ok') {
      action = duplicateGetListSuccess(convertListToTable(payload.data));
    } else {
      action = duplicateGetListFailed();
    }

    dispatch(action);
  });
};

const getDuplicate = email => async (dispatch) => {
  const token = getToken();
  dispatch(duplicateGetDuplicatesProgress());

  ws.emit('api/academy/duplicates/getDuplicate', { token, payload: { email } }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = duplicateGetDuplicatesSuccess(payload);
    } else {
      action = duplicateGetDuplicatesFailed();
    }

    dispatch(action);
  });
};

const updateDuplicate = (userId, amoContactId) => new Promise((resolve, reject) => {
  const token = getToken();

  ws.emit('api/academy/duplicates/updateAmoContactId', { token, payload: { userId, amoContactId } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      resolve();
    } else {
      reject();
    }
  });
});

const deleteDuplicate = id => new Promise((resolve, reject) => {
  const token = getToken();

  ws.emit('api/academy/duplicates/deleteDuplicate', { token, payload: { id } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      resolve();
    } else {
      reject();
    }
  });
});

const duplicateClearFlashMessage = () => ({
  type: DUPLICATES_CLEAR_FLASH_MESSAGE,
});

export {
  DUPLICATES_PROGRESS,
  DUPLICATES_SUCCESS,
  DUPLICATES_FAILED,
  DUPLICATES_GET_LIST_PROGRESS,
  DUPLICATES_GET_LIST_SUCCESS,
  DUPLICATES_GET_LIST_FAILED,
  DUPLICATES_GET_DUPLICATES_PROGRESS,
  DUPLICATES_GET_DUPLICATES_SUCCESS,
  DUPLICATES_GET_DUPLICATES_FAILED,
  DUPLICATES_CLEAR_STATE,
  DUPLICATES_CLEAR_FLASH_MESSAGE,
  duplicateGetListProgress,
  duplicateGetListSuccess,
  duplicateGetListFailed,
  duplicateGetDuplicatesProgress,
  duplicateGetDuplicatesSuccess,
  duplicateGetDuplicatesFailed,
  duplicateClearState,
  getDuplicatesList,
  getDuplicate,
  updateDuplicate,
  deleteDuplicate,
  duplicateClearFlashMessage,
};
