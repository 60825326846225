import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field } from 'redux-form';
import ArrowUpBold from 'mdi-react/ArrowUpBoldIcon';
import ArrowDownBoldIcon from 'mdi-react/ArrowDownBoldIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import renderField from '../../../shared/components/form/TextField';
import hiddenField from '../../../shared/components/form/HiddenField';
import renderTextarea from '../../../shared/components/form/TextareaField';
import renderSelectField from '../../../shared/components/form/Select';

const marginTop20 = { marginTop: '20px' };
const marginTop25 = { marginTop: '25px' };

class RenderAttentions extends PureComponent {
  static propTypes = {
    fields: PropTypes.objectOf(PropTypes.any).isRequired,
    meta: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static moveToUp(fields, index) {
    if (index > 0) {
      fields.swap(index, index - 1);
    }
  }

  static moveToDown(fields, index) {
    if (fields.length - 1 !== index) {
      fields.swap(index, index + 1);
    }
  }

  render() {
    const { fields, meta: { error, submitFailed } } = this.props;
    const { moveToUp, moveToDown } = RenderAttentions;

    return (
      <div className="form__form-group">
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" size="sm" onClick={() => fields.push({})}>Добавить фактор</Button>
        </ButtonToolbar>
        {submitFailed && error
        && (
          <div className="form__form-group">
            <div>
              <span className="form__form-group-error">{error}</span>
            </div>
          </div>
        )}
        {fields.map((attention, index) => (
          <div key={index} className="form__form-group">
            <span className="form__form-group-label">Фактор # {index + 1}</span>
            <div className="form__form-group-field">
              <Field
                name={`${attention}.id`}
                component={hiddenField}
              />
              <Field
                name={`${attention}.icon`}
                component={renderSelectField}
                placeholder="Выберите иконку"
                type="text"
                options={[
                  { value: 'hospital', label: 'Госпиталь' },
                  { value: 'sleep', label: 'Сон' },
                  { value: 'swim', label: 'Плаванье' },
                ]}
              />
              <div className="form__form-group-icon">
                <ArrowUpBold onClick={() => moveToUp(fields, index)} />
              </div>
              <div className="form__form-group-icon">
                <ArrowDownBoldIcon onClick={() => moveToDown(fields, index)} />
              </div>
              <div className="form__form-group-icon">
                <TrashCanOutlineIcon onClick={() => fields.remove(index)} />
              </div>
            </div>
            <div className="form__form-group-field" style={marginTop25}>
              <Field
                name={`${attention}.header`}
                component={renderField}
                type="text"
                placeholder="Название"
              />
            </div>
            <div className="form__form-group-field" style={marginTop20}>
              <Field
                name={`${attention}.content`}
                component={renderTextarea}
                type="text"
                placeholder="Описание"
              />
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default RenderAttentions;
