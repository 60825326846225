import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';

import MaraphonFlowTablePage from '../../../MaraphonFlows/MaraphonFlowTable';
import MaraphonFlowCreatePage from '../../../MaraphonFlows/MaraphonFlowCreate/index';
import MaraphonFlowEditPage from '../../../MaraphonFlows/MaraphonFlowEdit/index';

export default () => (
  <Switch>
    <ProtectedRoute path="/maraphon_flows" exact component={MaraphonFlowTablePage} />
    <ProtectedRoute path="/maraphon_flows/create" component={MaraphonFlowCreatePage} />
    <ProtectedRoute path="/maraphon_flows/:flowId" exact component={MaraphonFlowEditPage} />
  </Switch>
);
