import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  GROUPS_SUCCESS, GROUPS_FAILED,
  getGroup as getGroupAction,
  groupClearState as groupClearStateAction,
  updateGroup as updateGroupAction,
  deleteGroup as deleteGroupAction,
  getAsyncData as getAsyncDataAction,
} from '../../../../redux/actions/groupsAction';
import Modal from '../../../../shared/components/Modal';
import ButtonToNavigate from '../../../../shared/components/form/ButtonToNavigate';
import hiddenField from '../../../../shared/components/form/HiddenField';
import renderField from '../../../../shared/components/form/TextField';
import renderSelectField from '../../../../shared/components/form/Select';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import RenderOptions from '../../components/RenderOptions';
import { FullWideNotification } from '../../../../shared/components/Notification';
import validate from '../../validate';

class EditForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
    submitting: PropTypes.bool.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    asyncData: PropTypes.objectOf(PropTypes.any).isRequired,
    submitStatus: PropTypes.string.isRequired,
    loadStatus: PropTypes.string.isRequired,
    asyncStatus: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    getGroup: PropTypes.func.isRequired,
    deleteGroup: PropTypes.func.isRequired,
    groupClearState: PropTypes.func.isRequired,
    getAsyncData: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      type: null,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { getGroup, match, getAsyncData } = this.props;
    const { groupId } = match.params;

    getGroup(groupId);
    getAsyncData();
  }

  componentWillReceiveProps(nextProps) {
    const {
      submitStatus, loadStatus, history, initialValues: initialData,
    } = nextProps;

    if (loadStatus === GROUPS_SUCCESS) {
      const { type } = initialData;
      this.setState({ type });
    }

    if (loadStatus === GROUPS_FAILED) {
      history.push('/404');
    }

    if (submitStatus === GROUPS_SUCCESS) {
      history.push('/groups');
    } else if (submitStatus === GROUPS_FAILED) {
      this.show('danger', 'Произошла непредвиденная ошибка. Обратитесь к разработчикам.');
    }
  }

  componentWillUnmount() {
    const { groupClearState } = this.props;
    groupClearState();
  }

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message={message}
      />,
      position: 'full',
    });
  };

  handleChangeType = (value) => {
    this.setState({ type: value });
  };

  handleDelete = () => {
    const { handleSubmit, deleteGroup } = this.props;
    deleteGroup();
    setTimeout(handleSubmit, 700);
  };

  render() {
    const {
      handleSubmit, submitting, t, loadStatus, asyncStatus, asyncData, initialValues,
    } = this.props;
    const { type } = this.state;
    const { lists } = asyncData;
    const { isMain } = initialValues;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">ФОРМА РЕДАКТИРОВАНИЯ ГРУППЫ ПОЛЕЙ</h5>
              <h5 className="subhead">{t('groups.forms.create_form_description')}</h5>
            </div>
            {loadStatus === GROUPS_SUCCESS && asyncStatus === GROUPS_SUCCESS ? (
              <form className="form" onSubmit={handleSubmit}>
                <Field
                  name="type"
                  component={hiddenField}
                />
                <div className="form__form-group">
                  <span className="form__form-group-label">Название группы полей</span>
                  <div className="form__form-group-field">
                    <Field
                      name="name"
                      component={renderField}
                      type="text"
                      placeholder="Название группы полей"
                    />
                  </div>
                </div>
                {type === 'sublist' || type === 'done' ? (
                  <div className="form__form-group form__form-select" style={{ marginBottom: '40px' }}>
                    <span className="form__form-group-label">Первичный список</span>
                    <div className="form__form-group-field">
                      <Field
                        name="parent"
                        component={renderSelectField}
                        placeholder="Выберите список"
                        options={lists}
                      />
                    </div>
                  </div>
                ) : null}
                {type === 'list' || type === 'sublist' || type === 'mix' ? (
                  <>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Минимальное количество полей</span>
                      <div className="form__form-group-field">
                        <Field
                          name="min"
                          component={renderField}
                          type="number"
                          placeholder="Минимальное количество полей"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="isAdd"
                          component={renderCheckBoxField}
                          label="Разрешить пользователю добавлять поля"
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {type === 'list' || type === 'sublist' ? (
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="isSortable"
                        component={renderCheckBoxField}
                        label="Разрешить пользователю сортировать поля"
                      />
                    </div>
                  </div>
                ) : null}
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {type === 'sublist' ? 'Надпись над подсписком' : 'Надпись возле поля'}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="textNearField"
                      component={renderField}
                      type="text"
                      placeholder={type === 'sublist' ? 'Надпись над подсписком' : 'Надпись возле поля'}
                    />
                  </div>
                </div>
                {type !== 'field' ? (
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="isIterableTextNearField"
                        component={renderCheckBoxField}
                        label="Нумеровать поля"
                      />
                    </div>
                  </div>
                ) : null}
                {type === 'sublist' || type === 'done' ? (
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {type === 'sublist' ? 'Надпись возле основного поля' : 'Текст на кнопке'}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="additionalText"
                        component={renderField}
                        type="text"
                        placeholder={type === 'sublist' ? 'Надпись возле основного поля' : 'Текст на кнопке'}
                      />
                    </div>
                  </div>
                ) : null}
                {type === 'sublist' ? (
                  <div className="form__form-group">
                    <span className="form__form-group-label">Текст под основным полем</span>
                    <div className="form__form-group-field">
                      <Field
                        name="additionalText2"
                        component={renderField}
                        type="text"
                        placeholder="Текст под основным полем"
                      />
                    </div>
                  </div>
                ) : null}
                {type === 'radio' || type === 'sentences' || type === 'interview' || type === 'mix' ? (
                  <div className="form__form-group form__form-select">
                    <span className="form__form-group-label">Опции</span>
                    <div className="form__form-group-field">
                      <FieldArray
                        name="options"
                        component={RenderOptions}
                        props={{
                          showIsMain: type === 'mix',
                          isMain,
                          text: 'Вариант',
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {type === 'interview' || type === 'mix' ? (
                  <div className="form__form-group form__form-select">
                    <span className="form__form-group-label">Дополнительные Опции</span>
                    <div className="form__form-group-field">
                      <FieldArray
                        name="options2"
                        component={RenderOptions}
                        props={{
                          showIsMain: false,
                          text: 'Вариант',
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit" disabled={submitting}>Редактировать</Button>
                  <Route
                    path="/"
                    render={props => <ButtonToNavigate {...props} title="Отмена" path="/groups" />}
                  />
                  <Modal
                    color="danger"
                    title="Вы действительно хотите удалить эту группу полей?"
                    header
                    btn="Удалить"
                    btnDisabled={submitting}
                    btnSuccess="Удалить"
                    btnType="submit"
                    message="Так же будут удалены все ответы, которые были оставлены пользователями для этой
                    группы полей!"
                    success={() => this.handleDelete()}
                  />
                </ButtonToolbar>
              </form>
            ) : null}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: state.groups.active,
  submitStatus: state.groups.submitStatus,
  loadStatus: state.groups.loadActiveStatus,
  asyncStatus: state.groups.asyncStatus,
  asyncData: state.groups.asyncData,
  isDelete: state.groups.isDelete,
});

const mapDispatchToProps = dispatch => ({
  getGroup: groupId => dispatch(getGroupAction(groupId)),
  getAsyncData: () => dispatch(getAsyncDataAction()),
  deleteGroup: () => dispatch(deleteGroupAction()),
  groupClearState: () => dispatch(groupClearStateAction()),
});

const EditFormComponent = reduxForm({
  form: 'groups_edit_form', // a unique identifier for this form
  validate,
  enableReinitialize: true,
  onSubmit: updateGroupAction,
})(withTranslation('common')(withRouter(EditForm)));

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditFormComponent);
